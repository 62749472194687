import {Component, Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import { ReferenceCategoryModel } from '../../../../../../../core/interfaces/referenceCategory.model';
import {CategoryService} from '../../../../../../../core/services/category.service';
import {FileService} from '../../../../../../../core/services/file.service';
import {ErrorResolutionService} from '../../../../../../../core/services/errorResolution.service';
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {CertificateModel} from "../../../../../../../core/interfaces/certificate.model";
import {DataService} from "../../../../../../../core/services/data.service";

@Component({
  selector: 'app-certificate-delete',
  templateUrl: './certificate-delete.component.html',
  styleUrls: ['./certificate-delete.component.scss']
})
export class CertificateDeleteComponent {
  @Input() certificate = new CertificateModel();
  icon = faTrashCan;

  constructor(
    private dataService: DataService,
    private fileService: FileService,
    private modalService: NgbModal,
    private errorRes: ErrorResolutionService,
  ) { }

  showAlert(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', scrollable: true, centered: true}).result.then(result => {
      if (result === 'delete') {
        this.deleteCertificate();
        this.deleteFile();
      }
      console.log('Formulář uzavřen pomocí:' + result);
    }, (reason) => {
      console.log(this.errorRes.getModalDismissReason(reason));
    });
  }

  deleteCertificate(): void {
    this.dataService.deleteCertificate(this.certificate.id);
  }

  deleteFile(): void {
    console.log('Zahajuji mazání souborů certifikátu ' + this.certificate.name);
    this.fileService.deleteFolder('/public/certificates/' + this.certificate.id);
  }
}
