import { Component, Input, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import { Timestamp } from 'firebase/firestore';

import {ErrorResolutionService} from 'src/app/core/services/errorResolution.service';
import {EmployeeModel} from "../../../../../../../core/interfaces/employee.model";
import {EmployeeService} from "../../../../../../../core/services/employee.service";
import {faX, faCheck} from "@fortawesome/free-solid-svg-icons";
import {UtilityService} from "../../../../../../../core/services/utility.service";

@Component({
  selector: 'app-employee-deactivate',
  templateUrl: './employee-deactivate.component.html',
  styleUrls: ['./employee-deactivate.component.scss']
})
export class EmployeeDeactivateComponent implements OnInit{
  @Input() employee = new EmployeeModel();
  disableIcon = faX;
  enableIcon = faCheck;
  fireDate="";

  constructor(
    private employeeService: EmployeeService,
    private modalService: NgbModal,
    private errorRes: ErrorResolutionService,
    private util:UtilityService
  ) { }

  ngOnInit() {
    this.fireDate = new Date(Date.now()).toLocaleDateString();
  }

  showAlert(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', scrollable: true, centered: true}).result.then(result => {
      if (result === 'toggle') {
        this.toggleStatus();
      }
      console.log('Formulář uzavřen pomocí:' + result);
      this.ngOnInit();
    }, (reason) => {
      console.log(this.errorRes.getModalDismissReason(reason));
      this.ngOnInit();
    });
  }
  toggleStatus(): void {
    const [day, month, year] = this.fireDate.split('.');
    const date = new Date(+year, +month - 1, +day);
    if(!isNaN(date.getTime())) {
      console.log('Aktualizuji status zaměstnance ' + this.employee.lastName + ' ' + this.employee.firstName);
      this.employee.active = !this.employee.active;
      this.employee.fireDate = Timestamp.fromDate(date);
      this.employeeService.upsert(this.employee);
    } else {
      window.alert('Neplatné datum');
      console.error('Neplatné datum');
    }
  }
}
