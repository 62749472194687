export class AppUserPermissions {
  siteAdmin = false; // Upravuje kontent prezentační části
  attendanceAdmin = false; // Má přístup k formuláři docházky
  employeeAdmin = false; // Má přístup k sezamu a profilu zaměstnanců
  machinesAdmin = false; // Má přístup k GPS, sezamu a profilu strojů
  userAdmin = false; // Má přístup do sekce Uživatelé
  superAdmin = false; // Má přístup všude
}

export class AppUser {
  id = '';
  email = '';
  displayName = '';
  permissions = new AppUserPermissions();
}
