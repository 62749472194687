<h2 class="card-title text-center">Seznam Oblečení</h2>
<hr class="d-block d-sm-none">
<div class="tableFixHead">
<table class="table table-hover text-center text-sm-start">
  <thead>
  <tr>
    <th scope="col" class="d-none d-sm-table-cell">#</th>
    <th scope="col" class="d-none d-sm-table-cell">Název</th>
    <th scope="col" class="d-none d-lg-table-cell">Odkaz</th>
    <th scope="col" class="d-none d-sm-table-cell">Cena</th>
    <th scope="col" class="d-none d-sm-table-cell">Operace</th>
  </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="clothesList[0]">
      <tr *ngFor="let clothing of clothesList; index as i">
        <th scope="row" class="d-none d-sm-table-cell">{{ i + 1 }}</th>
        <td class="d-none d-sm-table-cell">
          <app-name-hover-id [object]="clothing" class="d-none d-lg-table-cell"></app-name-hover-id>
          <a class="d-table-cell d-lg-none" href="{{clothing.link}}" target="_blank">{{clothing.name}}</a>
        </td>
        <td class="clothingLink d-none d-lg-table-cell"><a class="text-primary" href="{{clothing.link}}" target="_blank">{{clothing.link}}</a></td>
        <td class="d-none d-sm-table-cell text-nowrap">{{ clothing.price | number }} Kč</td>
        <td class="text-nowrap">
          <p class="d-sm-none justify-content-around">
            <a href="{{clothing.link}}" target="_blank">{{clothing.name}}</a><br>
            {{clothing.price | number }} Kč
          </p>
          <app-clothing-preview [clothing]="clothing" [ngbTooltip]="viewTip"></app-clothing-preview>
          <app-clothing-form [clothing]="clothing" [ngbTooltip]="editTip"></app-clothing-form>
          <app-clothing-delete [clothing]="clothing" [ngbTooltip]="deleteTip"></app-clothing-delete>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
</div>
<app-clothing-form class="d-grid"></app-clothing-form>
<ng-template #viewTip>
  <div class="fs-7">
    Zobrazit
  </div>
</ng-template>
<ng-template #deleteTip>
  <div class="fs-7">
    Odstranit
  </div>
</ng-template>
<ng-template #editTip>
  <div class="fs-7">
    Upravit
  </div>
</ng-template>
