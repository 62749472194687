<button class="btn btn-sm btn-primary mx-1" (click)="showPlace(previewModal)"><fa-icon [icon]="icon"></fa-icon></button>
<ng-template #previewModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Náhled osoby</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
      <div class="text-nowrap text-center">
        <h3 class="my-0">{{person.name}}</h3>
        <b>{{person.function}}</b><br>
        <b>Email:</b> <a href="mailto:{{person.email}}"> {{person.email}}</a><br>
        <b>Tel:</b> <a href="tel:+420{{person.phoneNumber}}"> +420 {{person.phoneNumber}}</a>
      </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Hotovo</button>
  </div>
</ng-template>
