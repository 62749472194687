import {Component, OnInit} from '@angular/core';
import {CertificateModel} from "../../../../../core/interfaces/certificate.model";

import {DataService} from "../../../../../core/services/data.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{
  //Headre inputs
  headerL1 = 'Geo-stav Valeč';
  headerL2 = 'Stavíme pro vás a vaši budoucnost.';
  btnType = 'contacts';

  certificates: CertificateModel[] = [];

  constructor(
    private dataService: DataService
  ) {
  }
  ngOnInit() {
    this.dataService.getAllCertificates().subscribe(result => {
      this.certificates = result;
    });
  }
}
