<h2 class="card-title text-center mb-3">Přehled Výplat</h2>

<ng-container *ngIf="employeeList[0]">
  <form [formGroup]="selectionForm" class="mb-3">
    <div class="row row-cols-1 row-cols-lg-2 justify-content-around justify-content-lg-between selectionInputs">
      <div class="col-9 col-sm-6 col-lg-4 col-xl-3 mb-2 mb-lg-0">
        <input
          (change)="onMonthChange()"
          formControlName="month"
          type="month"
          [attr.min]="intervalMin"
          [attr.max]="currentMonth"
          class="form-control">
      </div>
      <div class="col-9 col-sm-6 col-lg-4 col-xl-3">
        <div class="btn-group w-100" role="group" aria-label="Basic example">
          <button class="btn btn-danger export-button" routerLink="/admin/employees/attendance">
            <span>Zpět</span>
          </button>
          <button class="btn btn-success export-button" (click)="export()">
            <span>Exportovat</span>
          </button>
        </div>
      </div>
    </div>
  </form>
      <div class="tableFixHead tableFixFooter">
        <table class="table table-hover">
          <thead>
          <tr>
            <th scope="col" class="text-nowrap">Příjmení a jméno</th>
            <th scope="col" class="text-nowrap">Výplata</th>
            <th scope="col" class="text-nowrap">
              <div class="form-check">
                <label class="form-check-label" for="5000check">
                  5000 Kč
                </label>
                <input class="form-check-input" type="checkbox" value="" id="5000check" (change)="amountCheck(5000)" checked>
              </div>
            </th>
            <th scope="col" class="text-nowrap">
              <div class="form-check">
                <label class="form-check-label" for="2000check">
                  2000 Kč
                </label>
                <input class="form-check-input" type="checkbox" value="" id="2000check" (change)="amountCheck(2000)" checked>
              </div>
            </th>
            <th scope="col" class="text-nowrap">
              <div class="form-check">
                <label class="form-check-label" for="1000check">
                  1000 Kč
                </label>
                <input class="form-check-input" type="checkbox" value="" id="1000check" (change)="amountCheck(1000)" checked>
              </div>
            </th>
            <th scope="col" class="text-nowrap">
              <div class="form-check">
                <label class="form-check-label" for="500check">
                  500 Kč
                </label>
                <input class="form-check-input" type="checkbox" value="" id="500check" (change)="amountCheck(500)" checked>
              </div>
            </th>
            <th scope="col" class="text-nowrap">
              <div class="form-check">
                <label class="form-check-label" for="200check">
                  200 Kč
                </label>
                <input class="form-check-input" type="checkbox" value="" id="200check" (change)="amountCheck(200)" checked>
              </div>
            </th>
            <th scope="col" class="text-nowrap">
              <div class="form-check">
                <label class="form-check-label" for="100check">
                  100 Kč
                </label>
                <input class="form-check-input" type="checkbox" value="" id="100check" (change)="amountCheck(100)" checked>
              </div>
            </th>
            <th scope="col" class="text-nowrap">
              <div class="form-check">
                <label class="form-check-label" for="50check">
                  50 Kč
                </label>
                <input class="form-check-input" type="checkbox" value="" id="50check" (change)="amountCheck(50)" checked>
              </div>
            </th>
            <th scope="col" class="text-nowrap">
              <div class="form-check">
                <label class="form-check-label" for="20check">
                  20 Kč
                </label>
                <input class="form-check-input" type="checkbox" value="" id="20check" (change)="amountCheck(20)" checked>
              </div>
            </th>
            <th scope="col" class="text-nowrap">
              <div class="form-check">
                <label class="form-check-label" for="10check">
                  10 Kč
                </label>
                <input class="form-check-input" type="checkbox" value="" id="10check" (change)="amountCheck(10)" checked>
              </div>
            </th>
            <th scope="col" class="text-nowrap">
              5 Kč
            </th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let employee of employeeList">
            <tr *ngIf="getAttendance(employee.id) as attendance;" attendance-modal [mode]="1" [attendance]="attendance" [employeeID]="employee.id" [haveMoney]="haveMoney"></tr>
          </ng-container>
          </tbody>
          <tfoot>
          <tr class="bg-primary bg-opacity-75">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th scope="row" class="text-nowrap">Celkem</th>
            <td class="text-nowrap">{{getTotal()}} Kč</td>
            <td>{{getTotalAmount(5000)}} ks</td>
            <td>{{getTotalAmount(2000)}} ks</td>
            <td>{{getTotalAmount(1000)}} ks</td>
            <td>{{getTotalAmount(500)}} ks</td>
            <td>{{getTotalAmount(200)}} ks</td>
            <td>{{getTotalAmount(100)}} ks</td>
            <td>{{getTotalAmount(50)}} ks</td>
            <td>{{getTotalAmount(20)}} ks</td>
            <td>{{getTotalAmount(10)}} ks</td>
            <td>{{getTotalAmount(5)}} ks</td>
          </tr>
          <tr>
            <th scope="row" class="text-nowrap">Máme</th>
            <td class="text-nowrap">{{salaryService.getTotalNominal(haveMoney)}} Kč</td>
            <td class="td-input"><input min="0" type="number" class="form-control form-control-sm w-100" [(ngModel)]="haveMoney['5000']" [attr.disabled]="haveMoney['5000'] === -1 ? '' : null"></td>
            <td class="td-input"><input min="0" type="number" class="form-control form-control-sm w-100" [(ngModel)]="haveMoney['2000']" [attr.disabled]="haveMoney['2000'] === -1 ? '' : null"></td>
            <td class="td-input"><input min="0" type="number" class="form-control form-control-sm w-100" [(ngModel)]="haveMoney['1000']" [attr.disabled]="haveMoney['1000'] === -1 ? '' : null"></td>
            <td class="td-input"><input min="0" type="number" class="form-control form-control-sm w-100" [(ngModel)]="haveMoney['500']" [attr.disabled]="haveMoney['500'] === -1 ? '' : null"></td>
            <td class="td-input"><input min="0" type="number" class="form-control form-control-sm w-100" [(ngModel)]="haveMoney['200']" [attr.disabled]="haveMoney['200'] === -1 ? '' : null"></td>
            <td class="td-input"><input min="0" type="number" class="form-control form-control-sm w-100" [(ngModel)]="haveMoney['100']" [attr.disabled]="haveMoney['100'] === -1 ? '' : null"></td>
            <td class="td-input"><input min="0" type="number" class="form-control form-control-sm w-100" [(ngModel)]="haveMoney['50']" [attr.disabled]="haveMoney['50'] === -1 ? '' : null"></td>
            <td class="td-input"><input min="0" type="number" class="form-control form-control-sm w-100" [(ngModel)]="haveMoney['20']" [attr.disabled]="haveMoney['20'] === -1 ? '' : null"></td>
            <td class="td-input"><input min="0" type="number" class="form-control form-control-sm w-100" [(ngModel)]="haveMoney['10']" [attr.disabled]="haveMoney['10'] === -1 ? '' : null"></td>
            <td class="td-input"><input min="0" type="number" class="form-control form-control-sm w-100" [(ngModel)]="haveMoney['5']" [attr.disabled]="haveMoney['5'] === -1 ? '' : null"></td>
          </tr>
          <tr>
            <th scope="row" class="text-nowrap">Potřebujeme</th>
            <td class="text-nowrap">{{getTotalNeeded()}} Kč</td>
            <td>{{haveMoney['5000'] === -1 ? 0 : getTotalAmount(5000)-haveMoney[5000]}} ks</td>
            <td>{{haveMoney['2000'] === -1 ? 0 : getTotalAmount(2000)-haveMoney[2000]}} ks</td>
            <td>{{haveMoney['1000'] === -1 ? 0 : getTotalAmount(1000)-haveMoney[1000]}} ks</td>
            <td>{{haveMoney['500'] === -1 ? 0 : getTotalAmount(500)-haveMoney[500]}} ks</td>
            <td>{{haveMoney['200'] === -1 ? 0 : getTotalAmount(200)-haveMoney[200]}} ks</td>
            <td>{{haveMoney['100'] === -1 ? 0 : getTotalAmount(100)-haveMoney[100]}} ks</td>
            <td>{{haveMoney['50'] === -1 ? 0 : getTotalAmount(50)-haveMoney[50]}} ks</td>
            <td>{{haveMoney['20'] === -1 ? 0 : getTotalAmount(20)-haveMoney[20]}} ks</td>
            <td>{{haveMoney['10'] === -1 ? 0 : getTotalAmount(10)-haveMoney[10]}} ks</td>
            <td>{{haveMoney['5'] === -1 ? 0 : getTotalAmount(5)-haveMoney[5]}} ks</td>
          </tr>
          </tfoot>
        </table>
      </div>
</ng-container>
<ng-container *ngIf="!employeeList[0]">
  <h3 class="text-center text-danger my-5">Nebyli nalezeni žádní zaměstnanci!</h3>
</ng-container>
