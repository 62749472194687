<h2 class="card-title text-center">Technika</h2>
<hr class="d-block d-sm-none">
<div class="tableFixHead">
<table class="table table-hover text-center text-sm-start">
  <thead>
  <tr>
    <th scope="col" class="d-none d-xl-table-cell">#</th>
    <th scope="col" class="d-none d-sm-table-cell d-lg-none">Technika</th>
    <th scope="col" class="d-none d-lg-table-cell sorter" (click)="sort('manufacturer')">Název<mat-icon>sort</mat-icon></th>
    <th scope="col" class="d-none d-sm-table-cell sorter" (click)="sort('type')">Typ<mat-icon>sort</mat-icon></th>
    <th scope="col" class="d-none d-lg-table-cell sorter" (click)="sort('operator')">Operátor<mat-icon>sort</mat-icon></th>
    <th scope="col" class="d-none d-sm-table-cell">Operace</th>
  </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="machineList[0]">
      <tr *ngFor="let machine of machineList; index as i">
        <th routerLink="/admin/machines/list/profile/{{machine.id}}" scope="row" class="d-none d-xl-table-cell align-middle pointer">{{ i + 1 }}</th>
        <td routerLink="/admin/machines/list/profile/{{machine.id}}" class="d-none d-sm-table-cell align-middle pointer">
          <a routerLink="/admin/machines/list/profile/{{machine.id}}" [ngbTooltip]="tipContent"><app-name-hover-id [object]="machine"></app-name-hover-id></a>
          <ng-template #tipContent>
            <div>
              <img class="bg-light image my-1" src="{{machine.previewImage.src}}" alt=""><br>
            </div>
          </ng-template>
        </td>
        <td routerLink="/admin/machines/list/profile/{{machine.id}}" class="d-none d-sm-table-cell align-middle pointer">{{MachineTypeEnumMapping[machine.type]}}</td>
        <td class="d-none d-lg-table-cell align-middle"><app-operator-name *ngIf="machine.operator !== null" [employeeID]="machine.operator"></app-operator-name></td>
        <td class="text-nowrap align-middle">
          <p class="d-sm-none justify-content-around">
            {{machine.manufacturer}} {{machine.model}}<br>
            {{MachineTypeEnumMapping[machine.type]}}
          </p>
          <div class="mt-2 mt-lg-auto">
            <button routerLink="/admin/machines/list/profile/{{machine.id}}" class="btn btn-sm btn-primary mx-1" [ngbTooltip]="viewTip"><fa-icon [icon]="previewIcon"></fa-icon></button>
            <app-machine-form [machine]="machine" [ngbTooltip]="editTip"></app-machine-form>
            <app-machine-delete [machine]="machine" [ngbTooltip]="deleteTip"></app-machine-delete>
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
</div>
<app-machine-form class="d-grid"></app-machine-form>
<ng-template #viewTip>
  <div class="fs-7">
    Zobrazit
  </div>
</ng-template>
<ng-template #deleteTip>
  <div class="fs-7">
    Odstranit
  </div>
</ng-template>

<ng-template #editTip>
  <div class="fs-7">
    Upravit
  </div>
</ng-template>
