import { Component, Input } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FileService} from 'src/app/core/services/file.service';
import {ErrorResolutionService} from 'src/app/core/services/errorResolution.service';
import {MachineModel} from "../../../../../../core/interfaces/machine.model";
import {MachineService} from "../../../../../../core/services/machine.service";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-machine-delete',
  templateUrl: './machine-delete.component.html',
  styleUrls: ['./machine-delete.component.scss']
})
export class MachineDeleteComponent {
  @Input() machine = new MachineModel();
  icon = faTrashCan;

  constructor(
    private machineService: MachineService,
    private fileService: FileService,
    private modalService: NgbModal,
    private errorRes: ErrorResolutionService,
  ) { }

  showAlert(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', scrollable: true, centered: true}).result.then(result => {
      if (result === 'delete') {
        this.deleteReference();
        this.deleteFiles()
      }
      console.log('Formulář uzavřen pomocí:' + result);
    }, (reason) => {
      console.log(this.errorRes.getModalDismissReason(reason));
    });
  }
  deleteReference(): void {
    console.log('Mažu záznam ' + this.machine.id + ' stroje ' + this.machine.manufacturer + ' ' + this.machine.model);
    this.machineService.delete(this.machine.id);
  }
  deleteFiles(): void {
    console.log('Zahajuji mazání souborů stroje ' + this.machine.manufacturer + ' ' + this.machine.model);
    this.fileService.deleteFolder('private/machines/' + this.machine.id + '/preview');
    this.fileService.deleteFolder('private/machines/' + this.machine.id + '/gallery');
  }
}
