import {Component, OnInit} from '@angular/core';

import {ReferencePlaceModel} from 'src/app/core/interfaces/referencePlace.model';
import {PlaceService} from 'src/app/core/services/place.service';
import {UtilityService} from "../../../../../../core/services/utility.service";

@Component({
  selector: 'app-reference-place',
  templateUrl: './reference-place.component.html',
  styleUrls: ['./reference-place.component.scss']
})
export class ReferencePlaceComponent implements OnInit {
  placeList: ReferencePlaceModel[] = [];
  asc = false;

  constructor(
    private placeService: PlaceService,
    private util: UtilityService
  ) {}

  ngOnInit(): void {
    this.getAllPlaces();
  }
  getAllPlaces(): void {
    this.placeService.getAll().subscribe(result => {
      if (result.length > 0) {
        this.placeList = result;
      } else {
        this.placeList = [];
        console.log('Žádný záznam místa neexistuje!!!')
      }
    });
  }
  sort(key: string) {
    const sorter = this.util.sortByKey(this.placeList, key, this.asc);
    this.placeList = sorter.object;
    this.asc = sorter.asc;
  }
}
