import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AttendanceRepositoryService} from "../repositories/attendance-repository.service";
import {MonthlyAttendance} from "../interfaces/employeeAttendance.model";

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {

  constructor(
    private repository: AttendanceRepositoryService,
  ) {
  }

  createId(): string {
    const id = this.repository.createId();
    console.log('Generuji ID ' + id + ' pro záznam velikosti oblečení...');
    return id;
  }
  upsert(attendance: MonthlyAttendance, employeeID: string): Observable<any> {
    console.log('Aktualizuji/Vytvářím záznam velikosti oblečení ' + attendance.id + '...');
    const parentPath = `Employees/${employeeID}/attendance`;
    return this.repository.upsert(attendance, {parentPath});
  }
  delete(id: string, employeeID: string): Observable<void> {
    console.log('Mažu záznam docházky ' + id + '...');
    const parentPath = `Employees/${employeeID}/attendance`;
    return this.repository.deleteDocument(id, {parentPath});
  }
  getAllForEmployee(employeeId: string): Observable<any> {
    const parentPath = `Employees/${employeeId}/attendance`;
    return this.repository.getAll({parentPath});
  }
  getByDateForEmployee(date: string, employeeId: string): Observable<any> {
    const parentPath = `Employees/${employeeId}/attendance`;
    return this.repository.getById(date, {parentPath});
  }
  getDay(date: string): string {
    const year = +date.slice(-4);
    const month = +date.substring(3, 5) - 1;
    const day = +date.substring(0, 2);
    let daysInWeek = ['NE', 'PO', 'ÚT', 'ST', 'ČT', 'PÁ', 'SO'];
    let dayIndex = new Date(year, month, day).getDay();
    return daysInWeek[dayIndex];
  }
  getMonth(monthYear: string): string {
    const year = +monthYear.substring(0, 4);
    const month = +monthYear.slice(-2);
    const monthsInYear = ['prosinec', 'leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad'];
    const monthIndex = new Date(year, month).getMonth();
    return monthsInYear[monthIndex] + ' ' + year;
  }
}
