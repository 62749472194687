import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {RepositoryOptionsInterface} from '../interfaces/repository-options.interface';
import {Repository} from './repository.abstract';
import {ReferenceModel} from '../interfaces/reference.model';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ReferenceRepositoryService extends Repository {
  constructor(protected db: AngularFirestore) {
    super();
  }

  collectionName(options?: RepositoryOptionsInterface): string {
    return 'References';
  }

  fromDb(snapshot: any, options: any): ReferenceModel {
    const data = snapshot.data(options);
    const result = new ReferenceModel();
    Object.assign(result, data);
    result.id = data.id || snapshot.id;

    return result;
  }

  toDb(data: ReferenceModel): object {
    return JSON.parse(JSON.stringify(data));
  }

  getAllReferencesByCategories(categoryID: string, options?: RepositoryOptionsInterface): Observable<ReferenceModel[]> {
    const result = this.db.collection<ReferenceModel>(this.collectionWithConverter(options), (colRef) => {
      return colRef.where('category', '==', categoryID)
    });
    return result.valueChanges({idField: 'id'});
  }
}
