import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {BreakpointObserver} from "@angular/cdk/layout";
import {MatSidenav} from "@angular/material/sidenav";
import {toggle} from "slidetoggle";
import {map, Observable, timer} from "rxjs";
import {adminMenuItems} from "../../core/data/adminMenuItems";
import {AuthService} from "../../core/services/auth.service";

@Component({
  selector: 'app-admin-app',
  templateUrl: './admin-app.component.html',
  styleUrls: ['./admin-app.component.scss']
})
export class AdminAppComponent implements OnInit, AfterViewInit, AfterViewChecked{
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  date: Observable<Date> = timer(0, 1000).pipe(map(() => new Date()));
  menuItems = adminMenuItems;
  parentSet = false;

  constructor(
    public authService: AuthService,
    private observer: BreakpointObserver,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.authService.initialized.subscribe(result => {
      if (result) {
        const permissions = this.authService.appUser!.permissions;
        this.menuItems = this.menuItems.filter(item => {
          if (permissions.superAdmin) {
            return true;
          } else if (item.withChildItem) {
            item.children = item.children.filter(child => {
              switch (child.permissionRequired) {
                case 'siteAdmin':
                  return permissions.siteAdmin;
                case 'attendanceAdmin':
                  return permissions.attendanceAdmin;
                case 'employeeAdmin':
                  return permissions.employeeAdmin;
                case 'machinesAdmin':
                  return permissions.machinesAdmin;
                case 'userAdmin':
                  return permissions.userAdmin;
                case 'superAdmin':
                  return permissions.superAdmin;
                default:
                  return true;
              }
            });
            return item.children.length > 0;
          } else {
            switch (item.permissionRequired) {
              case 'siteAdmin':
                return permissions.siteAdmin;
              case 'attendanceAdmin':
                return permissions.attendanceAdmin;
              case 'employeeAdmin':
                return permissions.employeeAdmin;
              case 'machinesAdmin':
                return permissions.machinesAdmin;
              case 'userAdmin':
                return permissions.userAdmin;
              case 'superAdmin':
                return permissions.superAdmin;
              default:
                return true;
            }
          }
        })
      }
    });
  }

  ngAfterViewInit(): void {
    this.observer.observe(['(max-width: 800px)']).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = 'over';
        this.sidenav.close().then();
      } else {
        this.sidenav.mode = 'side';
        this.sidenav.open().then();
      }
    });
  }

  ngAfterViewChecked():void {
    this.setupNavMenu();
    this.cdRef.detectChanges();
  }

  logout(): void {
    this.authService.SignOut().then(r => {});
  }

  slideOpen(id: string) {
    const arrowDown = document.getElementById(id + '-arrow-down');
    const arrowUp = document.getElementById(id + '-arrow-up');
    toggle('#sub-' + id, {miliseconds: 250});
    if (arrowUp && arrowDown) {
      if (arrowDown.classList.contains('d-none')) {
        arrowDown.classList.replace('d-none','d-inline-block');
        arrowUp.classList.replace('d-inline-block', 'd-none');
      } else {
        arrowDown.classList.replace('d-inline-block', 'd-none');
        arrowUp.classList.replace('d-none','d-inline-block');
      }
    }
  }

  setupNavMenu(): void {
    if (!this.parentSet) {
      const activeButton = document.querySelectorAll('.active-button');
      if(activeButton.length > 0) {
        const parentId = activeButton[0].id.substring(0, activeButton[0].id.length - 6);
        const parent = document.getElementById(parentId);
        if(parent){
          parent.classList.add('parent-active');
          this.slideOpen(parentId);
        }
        this.parentSet = true;
      }
    }
  }

  getDayName(): string {
    const date = new Date(Date.now());
    return date.toLocaleDateString('cs-CZ', { weekday: 'long' });
  }

  highlightParent(id?: string) {
    const activeParents = document.querySelectorAll('.parent-active');
    activeParents.forEach(parent => {
      parent.classList.remove('parent-active');
    });
    if (id) {
      const parent = document.getElementById(id);
      if (parent) {
        parent.classList.add('parent-active');
      }
    }
  }
}
