import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {ContactRepositoryService} from '../repositories/contact-repository.service';
import {ContactPersonModel} from '../interfaces/contactPerson.model';
import {CompanyInfoRepository} from '../repositories/company-info-repository.service';
import {CompanyInfoModel} from '../interfaces/companyInfo.model';
import {CertificateRepositoryService} from "../repositories/certificate-repository.service";
import {CertificateModel} from "../interfaces/certificate.model";

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private afs: AngularFirestore,
    private contactRepository: ContactRepositoryService,
    private companyInfoRepository: CompanyInfoRepository,
    private certificateRepository: CertificateRepositoryService,
  ) { }

  // #CompanyInfo ---------------------------------------------------------------
  getCompanyInfo(): Observable<any> {
    console.log('Načítám záznam informací o společnosti...')
    return this.companyInfoRepository.getById('CompanyInfo');
  }
  updateCompanyInfo(info: CompanyInfoModel): Observable<any> {
    console.log('Aktualizuji záznam informací o společnosti...')
    return this.companyInfoRepository.upsert(info);
  }

  // #ContactPeople -------------------------------------------------------------
  createId(): string {
    const id = this.contactRepository.createId();
    console.log('Generuji ID ' + id + ' pro záznam osoby...');
    return id;
  }
  getAllContactPeople(): Observable<ContactPersonModel[]>{
    console.log('Načítám zaznamy všech kontaktních osob...');
    return this.contactRepository.getAll({orderBy: 'priority', orderByDirection: 'asc'});
  }
  upsertContactPerson(info: ContactPersonModel): Observable<any> {
    if (info.id) {
      console.log('Aktualizuji záznam osoby: ' + info.id + ' (' + info.name + ')...');
    } else {
      console.log('Vytvářím záznam osoby: ' + info.name + '...');
    }
    return this.contactRepository.upsert(info);
  }
  deleteContactPerson(id: string): Observable<void> {
    console.log('Mažu záznam osoby ' + id);
    return this.contactRepository.deleteDocument(id);
  }

  // #Certificates ---------------------------------------------------------------
  getAllCertificates(): Observable<CertificateModel[]>{
    console.log('Načítám zaznamy všech certifikátů...');
    return this.certificateRepository.getAll({orderBy: 'priority', orderByDirection: 'asc'});
  }
  upsertCertificate(info: CertificateModel): Observable<any> {
    if (info.id) {
      console.log('Aktualizuji záznam certifikátu: ' + info.id + ' (' + info.name + ')...');
    } else {
      console.log('Vytvářím záznam certifikátu: ' + info.name + '...');
    }
    return this.certificateRepository.upsert(info);
  }
  deleteCertificate(id: string): Observable<void> {
    console.log('Mažu záznam certifikátu ' + id);
    return this.certificateRepository.deleteDocument(id);
  }
}
