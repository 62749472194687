import {Injectable} from '@angular/core';
import {Borders, Fill, Workbook} from 'exceljs';
import * as fs from 'file-saver';
import {AuthService} from "./auth.service";
import {EmployeeModel} from "../interfaces/employee.model";
import {AttendancePlaceEnum, MonthlyAttendance} from "../interfaces/employeeAttendance.model";
import {EmployeeService} from "./employee.service";
import {AttendanceService} from "./attendance.service";
import {Observable} from "rxjs";
import * as moment from "moment";
import {SalaryService} from "./salary.service";

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

const axios = require('axios').default;

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor(
    public authService: AuthService,
    public salaryService: SalaryService,
    private employeeService: EmployeeService,
    private attendanceService: AttendanceService,
  ) {
  }

  saveAsExcel(employee: EmployeeModel, attendance: MonthlyAttendance) {
    let obj: any = {};
    obj[employee.id] = attendance
    this.generateExcel([employee], obj, {
      5000: 0,
      2000: 0,
      1000: 0,
      500: 0,
      200: 0,
      100: 0,
      50: 0,
      20: 0,
      10: 0,
      5: 0
    }).then(workbook => {
      workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
        const blob = new Blob([data], {type: EXCEL_TYPE});
        fs.saveAs(blob, 'Docházka_' + attendance.id + '_' + employee.lastName+employee.firstName + EXCEL_EXTENSION);
      });
    });
  }

  saveAllAsExcel(employeeList: EmployeeModel[], date: string, moneyWeHave: any) {
    new Observable<any>(observer => {
      let obj: any = {};
      employeeList.forEach((employee, i) => {
        this.attendanceService.getByDateForEmployee(date, employee.id).subscribe(attendance => {
          obj[employee.id] = attendance.data();
          if (i == employeeList.length - 1) {
            employeeList = employeeList.filter(employee => {
              return obj[employee.id]
            });
            observer.next(obj);
          }
        });
      });
    }).subscribe(list => {
      this.generateExcel(employeeList, list, moneyWeHave).then(workbook => {
        workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
          const blob = new Blob([data], {type: EXCEL_TYPE});
          fs.saveAs(blob, 'Docházka_' + date + EXCEL_EXTENSION);
        });
      });
    });

  }

  async generateExcel(employeeList: EmployeeModel[], obj: any, moneyWeHave: any): Promise<Workbook> {
    const workbook = new Workbook();
    workbook.creator = 'Geo-stav Valeč s.r.o.';
    workbook.lastModifiedBy = this.authService.appUser?.displayName || 'neznámý';
    workbook.created = new Date();
    workbook.modified = new Date();

    const logoBuffer = await axios.get('../../assets/images/excel/excel-logo.png', {responseType: 'arraybuffer'});
    const logo = workbook.addImage({
      buffer: logoBuffer.data,
      extension: 'png',
    });
    const stampBuffer = await axios.get('../../assets/images/excel/excel-stamp.png', {responseType: 'arraybuffer'});
    const stamp = workbook.addImage({
      buffer: stampBuffer.data,
      extension: 'png',
    });
    const signatureBuffer = await axios.get('../../assets/images/excel/excel-signature.png', {responseType: 'arraybuffer'});
    const signature = workbook.addImage({
      buffer: signatureBuffer.data,
      extension: 'png',
    });

    let currencyFmt = '# ##0 Kč';
    let ksFmt = '# ##0 ks';
// # Zaměstnanec =======================================================================================================
    employeeList.forEach(employee => {
      if (obj[employee.id]) {
        let attendance: MonthlyAttendance = obj[employee.id];
        const osoba = workbook.addWorksheet(employee.lastName + " " + employee.firstName, {pageSetup: {fitToPage: true}});

        osoba.addImage(logo, 'B3:E8');
        osoba.addImage(stamp, 'H55:K58');
        osoba.addImage(signature, 'G58:J60');


        osoba.mergeCells('H6:K6');
        osoba.getCell('H6').value = employee.lastName + ' ' + employee.firstName;
        osoba.getCell('H6').alignment = {horizontal: 'center'};
        osoba.getCell('H6').font = {size: 24, bold: true};

        osoba.mergeCells('B9:D9');
        osoba.getCell('B9').value = this.attendanceService.getMonth(attendance.id);
        osoba.getCell('B9').font = {size: 24, bold: true};


        const header = ['Datum', 'Den', 'hodin', 'činnost', 'Kč/den'];
        let startLetterASCII = 66; // B

        header.forEach(head => {
          osoba.getCell(String.fromCharCode(startLetterASCII) + '17').value = head;
          osoba.getCell(String.fromCharCode(startLetterASCII) + '17').alignment = {
            horizontal: 'center',
            vertical: 'middle'
          };
          osoba.getCell(String.fromCharCode(startLetterASCII) + '17').font = {size: 11, bold: true};
          switch (startLetterASCII) {
            case 66:
              osoba.getCell(String.fromCharCode(startLetterASCII) + '17').style.border = {
                top: {style: 'thick'},
                left: {style: 'thick'},
                bottom: {style: 'medium'},
                right: {style: 'thin'}
              };
              break;
            case 70:
              osoba.getCell(String.fromCharCode(startLetterASCII) + '17').style.border = {
                top: {style: 'thick'},
                left: {style: 'thin'},
                bottom: {style: 'medium'},
                right: {style: 'thick'}
              };
              break;
            default:
              osoba.getCell(String.fromCharCode(startLetterASCII) + '17').style.border = {
                top: {style: 'thick'},
                left: {style: 'thin'},
                bottom: {style: 'medium'},
                right: {style: 'thin'}
              };
              break;
          }
          startLetterASCII++;
        });

        attendance.days.forEach((day, index) => {
          // Datem
          osoba.getCell('B' + (index + 18)).value = day.date;
          osoba.getCell('B' + (index + 18)).alignment = {horizontal: 'center', vertical: 'middle'};
          osoba.getCell('B' + (index + 18)).font = {size: 11, bold: false};
          // Den
          osoba.getCell('C' + (index + 18)).value = this.attendanceService.getDay(day.date);
          osoba.getCell('C' + (index + 18)).alignment = {horizontal: 'center', vertical: 'middle'};
          osoba.getCell('C' + (index + 18)).font = {size: 11, bold: false};
          // Hodin
          osoba.getCell('D' + (index + 18)).value = day.hours + ' h';
          osoba.getCell('D' + (index + 18)).alignment = {horizontal: 'center', vertical: 'middle'};
          osoba.getCell('D' + (index + 18)).font = {size: 11, bold: false};
          // Činnost
          osoba.getCell('E' + (index + 18)).value = day.place;
          osoba.getCell('E' + (index + 18)).alignment = {horizontal: 'center', vertical: 'middle'};
          osoba.getCell('E' + (index + 18)).font = {size: 11, bold: false};
          // Kč/Den
          osoba.getCell('F' + (index + 18)).value = this.salaryService.calculateSalaryForDay(day.place, day.hours, day.multiplier, attendance.currentSalary) + ' Kč';
          osoba.getCell('F' + (index + 18)).alignment = {horizontal: 'right', vertical: 'middle'};
          osoba.getCell('F' + (index + 18)).font = {size: 11, bold: false};

          if (attendance.days.length == index + 1) {
            osoba.getCell('B' + (index + 18)).style.border = {
              top: {style: 'thin'},
              left: {style: 'thick'},
              bottom: {style: 'thick'},
              right: {style: 'thin'}
            };
            osoba.getCell('C' + (index + 18)).style.border = {
              top: {style: 'thin'},
              left: {style: 'thin'},
              bottom: {style: 'thick'},
              right: {style: 'thin'}
            };
            osoba.getCell('D' + (index + 18)).style.border = {
              top: {style: 'thin'},
              left: {style: 'thin'},
              bottom: {style: 'thick'},
              right: {style: 'thin'}
            };
            osoba.getCell('E' + (index + 18)).style.border = {
              top: {style: 'thin'},
              left: {style: 'thin'},
              bottom: {style: 'thick'},
              right: {style: 'thin'}
            };
            osoba.getCell('F' + (index + 18)).style.border = {
              top: {style: 'thin'},
              left: {style: 'thin'},
              bottom: {style: 'thick'},
              right: {style: 'thick'}
            };
          } else {
            osoba.getCell('B' + (index + 18)).style.border = {
              top: {style: 'thin'},
              left: {style: 'thick'},
              bottom: {style: 'thin'},
              right: {style: 'thin'}
            };
            osoba.getCell('C' + (index + 18)).style.border = {
              top: {style: 'thin'},
              left: {style: 'thin'},
              bottom: {style: 'thin'},
              right: {style: 'thin'}
            };
            osoba.getCell('D' + (index + 18)).style.border = {
              top: {style: 'thin'},
              left: {style: 'thin'},
              bottom: {style: 'thin'},
              right: {style: 'thin'}
            };
            osoba.getCell('E' + (index + 18)).style.border = {
              top: {style: 'thin'},
              left: {style: 'thin'},
              bottom: {style: 'thin'},
              right: {style: 'thin'}
            };
            osoba.getCell('F' + (index + 18)).style.border = {
              top: {style: 'thin'},
              left: {style: 'thin'},
              bottom: {style: 'thin'},
              right: {style: 'thick'}
            };
          }
          switch (day.place) {
            case AttendancePlaceEnum.vacation:
              osoba.getCell('B' + (index + 18)).style.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FF93A8D7'}
              };
              osoba.getCell('C' + (index + 18)).style.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FF93A8D7'}
              };
              osoba.getCell('D' + (index + 18)).style.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FF93A8D7'}
              };
              osoba.getCell('E' + (index + 18)).style.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FF93A8D7'}
              };
              osoba.getCell('F' + (index + 18)).style.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FF93A8D7'}
              };
              break;
            case AttendancePlaceEnum.publicHoliday:
              osoba.getCell('B' + (index + 18)).style.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFFFC000'}
              };
              osoba.getCell('C' + (index + 18)).style.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFFFC000'}
              };
              osoba.getCell('D' + (index + 18)).style.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFFFC000'}
              };
              osoba.getCell('E' + (index + 18)).style.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFFFC000'}
              };
              osoba.getCell('F' + (index + 18)).style.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFFFC000'}
              };
              break;
            case AttendancePlaceEnum.weekend:
              osoba.getCell('B' + (index + 18)).style.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFBFBFC0'}
              };
              osoba.getCell('C' + (index + 18)).style.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFBFBFC0'}
              };
              osoba.getCell('D' + (index + 18)).style.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFBFBFC0'}
              };
              osoba.getCell('E' + (index + 18)).style.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFBFBFC0'}
              };
              osoba.getCell('F' + (index + 18)).style.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFBFBFC0'}
              };
              break;
            case AttendancePlaceEnum.sick:
              osoba.getCell('B' + (index + 18)).style.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFC00000'}
              };
              osoba.getCell('C' + (index + 18)).style.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFC00000'}
              };
              osoba.getCell('D' + (index + 18)).style.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFC00000'}
              };
              osoba.getCell('E' + (index + 18)).style.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFC00000'}
              };
              osoba.getCell('F' + (index + 18)).style.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFC00000'}
              };
              osoba.getCell('B' + (index + 18)).font = {size: 11, bold: false, color: {argb: 'FFffffff'}};
              osoba.getCell('C' + (index + 18)).font = {size: 11, bold: false, color: {argb: 'FFffffff'}};
              osoba.getCell('D' + (index + 18)).font = {size: 11, bold: false, color: {argb: 'FFffffff'}};
              osoba.getCell('E' + (index + 18)).font = {size: 11, bold: false, color: {argb: 'FFffffff'}};
              osoba.getCell('F' + (index + 18)).font = {size: 11, bold: false, color: {argb: 'FFffffff'}};
              break;
            default:
              break;
          }
        })


        osoba.getCell('I18').value = 'Víkend';
        osoba.getCell('H18').style.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFBFBFC0'}};
        osoba.getCell('I20').value = 'Dovolená';
        osoba.getCell('H20').style.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FF93A8D7'}};
        osoba.getCell('I22').value = 'Svátek';
        osoba.getCell('H22').style.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFFFC000'}};
        osoba.getCell('I24').value = 'Mzda celkem';
        osoba.getCell('H24').style.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFEA3323'}};


        osoba.getCell('H' + (3 + attendance.days.length)).style.border = {
          top: {style: 'thick'},
          left: {style: 'thick'},
          bottom: {style: 'medium'},
          right: {style: 'thin'}
        };

        osoba.getCell('I' + (3 + attendance.days.length)).value = 'Kč/h:';
        osoba.getCell('I' + (3 + attendance.days.length)).font = {bold: true};
        osoba.getCell('I' + (3 + attendance.days.length)).style.border = {
          top: {style: 'thick'},
          left: {style: 'thin'},
          bottom: {style: 'medium'},
          right: {style: 'thick'}
        };
        osoba.getCell('I' + (3 + attendance.days.length)).alignment = {horizontal: 'right', vertical: 'middle'};

        osoba.getCell('H' + (4 + attendance.days.length)).value = 'Stavba:';
        osoba.getCell('H' + (4 + attendance.days.length)).style.border = {
          top: {style: 'medium'},
          left: {style: 'thick'},
          bottom: {style: 'thin'},
          right: {style: 'thin'}
        };

        osoba.getCell('I' + (4 + attendance.days.length)).value = attendance.currentSalary.construction + ' Kč';
        osoba.getCell('I' + (4 + attendance.days.length)).style.border = {
          top: {style: 'medium'},
          left: {style: 'thin'},
          bottom: {style: 'thin'},
          right: {style: 'thick'}
        };
        osoba.getCell('I' + (4 + attendance.days.length)).alignment = {horizontal: 'right', vertical: 'middle'};

        osoba.getCell('H' + (5 + attendance.days.length)).value = 'Dílna:';
        osoba.getCell('H' + (5 + attendance.days.length)).style.border = {
          top: {style: 'thin'},
          left: {style: 'thick'},
          bottom: {style: 'thin'},
          right: {style: 'thin'}
        };

        osoba.getCell('I' + (5 + attendance.days.length)).value = attendance.currentSalary.workshop + ' Kč';
        osoba.getCell('I' + (5 + attendance.days.length)).style.border = {
          top: {style: 'thin'},
          left: {style: 'thin'},
          bottom: {style: 'thin'},
          right: {style: 'thick'}
        };
        osoba.getCell('I' + (5 + attendance.days.length)).alignment = {horizontal: 'right', vertical: 'middle'};

        osoba.getCell('H' + (6 + attendance.days.length)).value = 'Ostatní:';
        osoba.getCell('H' + (6 + attendance.days.length)).style.border = {
          top: {style: 'thin'},
          left: {style: 'thick'},
          bottom: {style: 'thick'},
          right: {style: 'thin'}
        };

        osoba.getCell('I' + (6 + attendance.days.length)).value = attendance.currentSalary.other + ' Kč';
        osoba.getCell('I' + (6 + attendance.days.length)).style.border = {
          top: {style: 'thin'},
          left: {style: 'thin'},
          bottom: {style: 'thick'},
          right: {style: 'thick'}
        };
        osoba.getCell('I' + (6 + attendance.days.length)).alignment = {horizontal: 'right', vertical: 'middle'};


        osoba.getCell('K' + (3 + attendance.days.length)).value = 'Činnost:';
        osoba.getCell('K' + (3 + attendance.days.length)).font = {bold: true};
        osoba.getCell('K' + (3 + attendance.days.length)).style.border = {
          top: {style: 'thick'},
          left: {style: 'thick'},
          bottom: {style: 'medium'},
          right: {style: 'thin'}
        };

        osoba.getCell('L' + (3 + attendance.days.length)).value = 'Počet dní:';
        osoba.getCell('L' + (3 + attendance.days.length)).font = {bold: true};
        osoba.getCell('L' + (3 + attendance.days.length)).style.border = {
          top: {style: 'thick'},
          left: {style: 'thin'},
          bottom: {style: 'medium'},
          right: {style: 'thick'}
        };

        osoba.getCell('K' + (4 + attendance.days.length)).value = 'Práce:';
        osoba.getCell('K' + (4 + attendance.days.length)).style.border = {
          top: {style: 'medium'},
          left: {style: 'thick'},
          bottom: {style: 'thin'},
          right: {style: 'thin'}
        };

        osoba.getCell('L' + (4 + attendance.days.length)).value = Math.round((attendance.workHours / employee.defaultHours) * 100) / 100;
        osoba.getCell('L' + (4 + attendance.days.length)).style.border = {
          top: {style: 'medium'},
          left: {style: 'thin'},
          bottom: {style: 'thin'},
          right: {style: 'thick'}
        };

        osoba.getCell('K' + (5 + attendance.days.length)).value = 'Dovolená:';
        osoba.getCell('K' + (5 + attendance.days.length)).style.border = {
          top: {style: 'thin'},
          left: {style: 'thick'},
          bottom: {style: 'thin'},
          right: {style: 'thin'}
        };

        osoba.getCell('L' + (5 + attendance.days.length)).value = Math.round((attendance.vacationHoursDuring / 9) * 100) / 100;
        osoba.getCell('L' + (5 + attendance.days.length)).style.border = {
          top: {style: 'thin'},
          left: {style: 'thin'},
          bottom: {style: 'thin'},
          right: {style: 'thick'}
        };

        osoba.getCell('K' + (6 + attendance.days.length)).value = 'Nemoc:';
        osoba.getCell('K' + (6 + attendance.days.length)).style.border = {
          top: {style: 'thin'},
          left: {style: 'thick'},
          bottom: {style: 'thick'},
          right: {style: 'thin'}
        };

        osoba.getCell('L' + (6 + attendance.days.length)).value = Math.round((attendance.sickHours / 9) * 100) / 100;
        osoba.getCell('L' + (6 + attendance.days.length)).style.border = {
          top: {style: 'thin'},
          left: {style: 'thin'},
          bottom: {style: 'thick'},
          right: {style: 'thick'}
        };


        osoba.mergeCells('H' + (9 + attendance.days.length) + ':J' + (9 + attendance.days.length));
        osoba.getCell('H' + (9 + attendance.days.length)).value = 'dovolená na začátku měsíce';
        osoba.getCell('H' + (9 + attendance.days.length)).style.border = {
          top: {style: 'thick'},
          left: {style: 'thick'},
          bottom: {style: 'thin'},
          right: {style: 'thin'}
        };

        osoba.mergeCells('K' + (9 + attendance.days.length) + ':L' + (9 + attendance.days.length));
        osoba.getCell('K' + (9 + attendance.days.length)).value = attendance.vacationHoursAtStart + ' h';
        osoba.getCell('K' + (9 + attendance.days.length)).style.border = {
          top: {style: 'thick'},
          left: {style: 'thin'},
          bottom: {style: 'thin'},
          right: {style: 'thick'}
        };
        osoba.getCell('K' + (9 + attendance.days.length)).alignment = {horizontal: 'right', vertical: 'middle'};

        osoba.mergeCells('H' + (10 + attendance.days.length) + ':J' + (10 + attendance.days.length));
        osoba.getCell('H' + (10 + attendance.days.length)).value = 'dovolená v tomto měsíci';
        osoba.getCell('H' + (10 + attendance.days.length)).style.border = {
          top: {style: 'thin'},
          left: {style: 'thick'},
          bottom: {style: 'medium'},
          right: {style: 'thin'}
        };

        osoba.mergeCells('K' + (10 + attendance.days.length) + ':L' + (10 + attendance.days.length));
        osoba.getCell('K' + (10 + attendance.days.length)).value = attendance.vacationHoursDuring + ' h';
        osoba.getCell('K' + (10 + attendance.days.length)).style.border = {
          top: {style: 'thin'},
          left: {style: 'thin'},
          bottom: {style: 'medium'},
          right: {style: 'thick'}
        };
        osoba.getCell('K' + (10 + attendance.days.length)).alignment = {horizontal: 'right', vertical: 'middle'};

        osoba.mergeCells('H' + (11 + attendance.days.length) + ':J' + (11 + attendance.days.length));
        osoba.getCell('H' + (11 + attendance.days.length)).value = 'Zbývající dovolená';
        osoba.getCell('H' + (11 + attendance.days.length)).font = {bold: true};
        osoba.getCell('H' + (11 + attendance.days.length)).style.border = {
          top: {style: 'medium'},
          left: {style: 'thick'},
          bottom: {style: 'thick'},
          right: {style: 'thin'}
        };

        osoba.mergeCells('K' + (11 + attendance.days.length) + ':L' + (11 + attendance.days.length));
        osoba.getCell('K' + (11 + attendance.days.length)).value = (attendance.vacationHoursAtStart - attendance.vacationHoursDuring) + ' h';
        osoba.getCell('K' + (11 + attendance.days.length)).font = {bold: true};
        osoba.getCell('K' + (11 + attendance.days.length)).style.border = {
          top: {style: 'thin'},
          left: {style: 'thin'},
          bottom: {style: 'thick'},
          right: {style: 'thick'}
        };
        osoba.getCell('K' + (11 + attendance.days.length)).alignment = {horizontal: 'right', vertical: 'middle'};


        osoba.getCell('H' + (14 + attendance.days.length)).value = 'Hodiny';
        osoba.getCell('H' + (14 + attendance.days.length)).style.border = {
          top: {style: 'thick'},
          left: {style: 'thick'},
          bottom: {style: 'thin'},
          right: {style: 'thin'}
        };
        osoba.getCell('H' + (14 + attendance.days.length)).alignment = {horizontal: 'right', vertical: 'middle'};

        osoba.getCell('I' + (14 + attendance.days.length)).value = attendance.workHours + ' h';
        osoba.mergeCells('I' + (14 + attendance.days.length) + ':K' + (14 + attendance.days.length));
        osoba.getCell('I' + (14 + attendance.days.length)).style.border = {
          top: {style: 'thick'},
          left: {style: 'thin'},
          bottom: {style: 'thin'},
          right: {style: 'thin'}
        };
        osoba.getCell('I' + (14 + attendance.days.length)).alignment = {horizontal: 'center', vertical: 'middle'};

        osoba.getCell('L' + (14 + attendance.days.length)).value = attendance.salary + ' Kč';
        osoba.getCell('L' + (14 + attendance.days.length)).style.border = {
          top: {style: 'thick'},
          left: {style: 'thin'},
          bottom: {style: 'thin'},
          right: {style: 'thick'}
        };
        osoba.getCell('L' + (14 + attendance.days.length)).alignment = {horizontal: 'right', vertical: 'middle'};

        osoba.getCell('H' + (15 + attendance.days.length)).value = 'Odměny';
        osoba.getCell('H' + (15 + attendance.days.length)).style.border = {
          top: {style: 'thin'},
          left: {style: 'thick'},
          bottom: {style: 'thin'},
          right: {style: 'thin'}
        };
        osoba.getCell('H' + (15 + attendance.days.length)).alignment = {horizontal: 'right', vertical: 'middle'};

        osoba.mergeCells('I' + (15 + attendance.days.length) + ':K' + (15 + attendance.days.length));
        osoba.getCell('I' + (15 + attendance.days.length)).style.border = {
          top: {style: 'thin'},
          left: {style: 'thin'},
          bottom: {style: 'thin'},
          right: {style: 'thin'}
        };

        osoba.getCell('L' + (15 + attendance.days.length)).value = attendance.salaryBonus + ' Kč';
        osoba.getCell('L' + (15 + attendance.days.length)).style.border = {
          top: {style: 'thin'},
          left: {style: 'thin'},
          bottom: {style: 'thin'},
          right: {style: 'thick'}
        };
        osoba.getCell('L' + (15 + attendance.days.length)).alignment = {horizontal: 'right', vertical: 'middle'};

        osoba.mergeCells('H' + (16 + attendance.days.length) + ':L' + (16 + attendance.days.length));
        osoba.getCell('H' + (16 + attendance.days.length)).style.border = {
          top: {style: 'thin'},
          left: {style: 'thick'},
          bottom: {style: 'thin'},
          right: {style: 'thick'}
        };

        osoba.getCell('H' + (17 + attendance.days.length)).value = 'Celkem';
        osoba.getCell('H' + (17 + attendance.days.length)).style.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: 'FFEA3323'}
        };
        osoba.getCell('H' + (17 + attendance.days.length)).font = {size: 14, bold: true};
        osoba.getCell('H' + (17 + attendance.days.length)).style.border = {
          top: {style: 'thin'},
          left: {style: 'thick'},
          bottom: {style: 'thick'},
          right: {style: 'thin'}
        };
        osoba.getCell('H' + (17 + attendance.days.length)).alignment = {horizontal: 'right', vertical: 'middle'};

        osoba.mergeCells('I' + (17 + attendance.days.length) + ':K' + (17 + attendance.days.length));
        osoba.getCell('I' + (17 + attendance.days.length)).style.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: 'FFEA3323'}
        };
        osoba.getCell('I' + (17 + attendance.days.length)).font = {size: 14, bold: true};
        osoba.getCell('I' + (17 + attendance.days.length)).style.border = {
          top: {style: 'thin'},
          left: {style: 'thin'},
          bottom: {style: 'thick'},
          right: {style: 'thin'}
        };

        osoba.getCell('L' + (17 + attendance.days.length)).value = (attendance.salary + attendance.salaryBonus) + ' Kč';
        osoba.getCell('L' + (17 + attendance.days.length)).style.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: 'FFEA3323'}
        };
        osoba.getCell('L' + (17 + attendance.days.length)).font = {size: 14, bold: true};
        osoba.getCell('L' + (17 + attendance.days.length)).style.border = {
          top: {style: 'thin'},
          left: {style: 'thin'},
          bottom: {style: 'thick'},
          right: {style: 'thick'}
        };
        osoba.getCell('L' + (17 + attendance.days.length)).alignment = {horizontal: 'right', vertical: 'middle'};

        osoba.getCell('B55').value = moment(Date.now()).format('DD.MM.YYYY');
        // osoba.getCell('B55').value = 'dne: 1.' + (+attendance.id.slice(-2) + 1) + '.' + attendance.id.substring(0, 4);


        osoba.getColumn('A').width = 3.5;
        osoba.getColumn('B').width = 10;
        osoba.getColumn('C').width = 5.67;
        osoba.getColumn('D').width = 9.83;
        osoba.getColumn('E').width = 15.67;
        osoba.getColumn('F').width = 10.33;
        osoba.getColumn('G').width = 5.67;
        osoba.getColumn('H').width = 9.33;
        osoba.getColumn('I').width = 9.33;
        osoba.getColumn('J').width = 3.5;
        osoba.getColumn('K').width = 8.33;
        osoba.getColumn('L').width = 13.17;
        osoba.getRow(5).height = 17;
        osoba.getRow(8).height = 13;
        osoba.getRow(16).height = 18;
      }
    });
// # Výčetka ===========================================================================================================
    const vycetka = workbook.addWorksheet('Výčetka', {pageSetup: {fitToPage: true}});
    vycetka.mergeCells('A1:L2');
    vycetka.getCell('A1').alignment = {horizontal: 'center', vertical: 'middle'};
    vycetka.getCell('A1').font = {size: 16};
    vycetka.getCell('C3').value = 5000;
    vycetka.getCell('D3').value = 2000;
    vycetka.getCell('E3').value = 1000;
    vycetka.getCell('F3').value = 500;
    vycetka.getCell('G3').value = 200;
    vycetka.getCell('H3').value = 100;
    vycetka.getCell('I3').value = 50;
    vycetka.getCell('J3').value = 20;
    vycetka.getCell('K3').value = 10;
    vycetka.getCell('L3').value = 5;
    vycetka.getRow(3).numFmt = currencyFmt;

    let thinBorder: Partial<Borders> = {
      top: {style: 'thin'},
      left: {style: 'thin'},
      bottom: {style: 'thin'},
      right: {style: 'thin'}
    };

    let grayBg: Fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'FFBFBFC0'}
    };
    vycetka.getCell('A3').style.border = thinBorder;
    vycetka.getCell('B3').style.border = thinBorder;
    vycetka.getCell('C3').style.border = thinBorder;
    vycetka.getCell('D3').style.border = thinBorder;
    vycetka.getCell('E3').style.border = thinBorder;
    vycetka.getCell('F3').style.border = thinBorder;
    vycetka.getCell('G3').style.border = thinBorder;
    vycetka.getCell('H3').style.border = thinBorder;
    vycetka.getCell('I3').style.border = thinBorder;
    vycetka.getCell('J3').style.border = thinBorder;
    vycetka.getCell('K3').style.border = thinBorder;
    vycetka.getCell('L3').style.border = thinBorder;

    vycetka.getColumn('A').width = 14.33;
    vycetka.getColumn('B').width = 11;

    vycetka.getColumn(2).numFmt = currencyFmt;

    let totalSalary = 0;
    let totalAmount = {
      5000: 0,
      2000: 0,
      1000: 0,
      500: 0,
      200: 0,
      100: 0,
      50: 0,
      20: 0,
      10: 0,
      5: 0
    }
    employeeList.forEach((employee, index) => {
      if (obj[employee.id]) {
        let attendance: MonthlyAttendance = obj[employee.id];
        let salary = attendance.salary + attendance.salaryBonus;
        totalSalary += salary;

        vycetka.getCell('A1').value = this.attendanceService.getMonth(attendance.id);

        vycetka.getCell('A' + (index + 4)).value = employee.lastName + ' ' + employee.firstName;
        vycetka.getCell('B' + (index + 4)).value = salary;
        let amount = this.salaryService.getAmountOfBills(salary, 5000, moneyWeHave);
        totalAmount[5000] += amount;
        vycetka.getCell('C' + (index + 4)).value = amount;
        amount = this.salaryService.getAmountOfBills(salary, 2000, moneyWeHave);
        totalAmount[2000] += amount;
        vycetka.getCell('D' + (index + 4)).value = amount;
        amount = this.salaryService.getAmountOfBills(salary, 1000, moneyWeHave);
        totalAmount[1000] += amount;
        vycetka.getCell('E' + (index + 4)).value = amount;
        amount = this.salaryService.getAmountOfBills(salary, 500, moneyWeHave);
        totalAmount[500] += amount;
        vycetka.getCell('F' + (index + 4)).value = amount;
        amount = this.salaryService.getAmountOfBills(salary, 200, moneyWeHave);
        totalAmount[200] += amount;
        vycetka.getCell('G' + (index + 4)).value = amount;
        amount = this.salaryService.getAmountOfBills(salary, 100, moneyWeHave);
        totalAmount[100] += amount;
        vycetka.getCell('H' + (index + 4)).value = amount;
        amount = this.salaryService.getAmountOfBills(salary, 50, moneyWeHave);
        totalAmount[50] += amount;
        vycetka.getCell('I' + (index + 4)).value = amount;
        amount = this.salaryService.getAmountOfBills(salary, 20, moneyWeHave);
        totalAmount[20] += amount;
        vycetka.getCell('J' + (index + 4)).value = amount;
        amount = this.salaryService.getAmountOfBills(salary, 10, moneyWeHave);
        totalAmount[10] += amount;
        vycetka.getCell('K' + (index + 4)).value = amount;
        amount = this.salaryService.getAmountOfBills(salary, 5, moneyWeHave);
        totalAmount[5] += amount;
        vycetka.getCell('L' + (index + 4)).value = amount;

        vycetka.getCell('A' + (index + 4)).style.border = thinBorder;
        vycetka.getCell('B' + (index + 4)).style.border = thinBorder;
        vycetka.getCell('C' + (index + 4)).style.border = thinBorder;
        vycetka.getCell('D' + (index + 4)).style.border = thinBorder;
        vycetka.getCell('E' + (index + 4)).style.border = thinBorder;
        vycetka.getCell('F' + (index + 4)).style.border = thinBorder;
        vycetka.getCell('G' + (index + 4)).style.border = thinBorder;
        vycetka.getCell('H' + (index + 4)).style.border = thinBorder;
        vycetka.getCell('I' + (index + 4)).style.border = thinBorder;
        vycetka.getCell('J' + (index + 4)).style.border = thinBorder;
        vycetka.getCell('K' + (index + 4)).style.border = thinBorder;
        vycetka.getCell('L' + (index + 4)).style.border = thinBorder;
        if (index == employeeList.length - 1) {
          vycetka.getCell('A' + (index + 6)).value = 'Celkem';
          vycetka.getCell('B' + (index + 6)).value = totalSalary;
          vycetka.getCell('C' + (index + 6)).value = totalAmount[5000];
          vycetka.getCell('D' + (index + 6)).value = totalAmount[2000];
          vycetka.getCell('E' + (index + 6)).value = totalAmount[1000];
          vycetka.getCell('F' + (index + 6)).value = totalAmount[500];
          vycetka.getCell('G' + (index + 6)).value = totalAmount[200];
          vycetka.getCell('H' + (index + 6)).value = totalAmount[100];
          vycetka.getCell('I' + (index + 6)).value = totalAmount[50];
          vycetka.getCell('J' + (index + 6)).value = totalAmount[20];
          vycetka.getCell('K' + (index + 6)).value = totalAmount[10];
          vycetka.getCell('L' + (index + 6)).value = totalAmount[5];

          vycetka.getCell('A' + (index + 6)).style.fill = grayBg;
          vycetka.getCell('B' + (index + 6)).style.fill = grayBg;
          vycetka.getCell('C' + (index + 6)).style.fill = grayBg;
          vycetka.getCell('D' + (index + 6)).style.fill = grayBg;
          vycetka.getCell('E' + (index + 6)).style.fill = grayBg;
          vycetka.getCell('F' + (index + 6)).style.fill = grayBg;
          vycetka.getCell('G' + (index + 6)).style.fill = grayBg;
          vycetka.getCell('H' + (index + 6)).style.fill = grayBg;
          vycetka.getCell('I' + (index + 6)).style.fill = grayBg;
          vycetka.getCell('J' + (index + 6)).style.fill = grayBg;
          vycetka.getCell('K' + (index + 6)).style.fill = grayBg;
          vycetka.getCell('L' + (index + 6)).style.fill = grayBg;

          vycetka.getCell('A' + (index + 6)).style.border = thinBorder;
          vycetka.getCell('B' + (index + 6)).style.border = thinBorder;
          vycetka.getCell('C' + (index + 6)).style.border = thinBorder;
          vycetka.getCell('D' + (index + 6)).style.border = thinBorder;
          vycetka.getCell('E' + (index + 6)).style.border = thinBorder;
          vycetka.getCell('F' + (index + 6)).style.border = thinBorder;
          vycetka.getCell('G' + (index + 6)).style.border = thinBorder;
          vycetka.getCell('H' + (index + 6)).style.border = thinBorder;
          vycetka.getCell('I' + (index + 6)).style.border = thinBorder;
          vycetka.getCell('J' + (index + 6)).style.border = thinBorder;
          vycetka.getCell('K' + (index + 6)).style.border = thinBorder;
          vycetka.getCell('L' + (index + 6)).style.border = thinBorder;
          if (moneyWeHave) {
            vycetka.getCell('A' + (index + 7)).value = 'Máme';
            vycetka.getCell('A' + (index + 8)).value = 'Potřebujeme';
            vycetka.getCell('B' + (index + 7)).value = this.salaryService.getTotalNominal(moneyWeHave);
            vycetka.getCell('B' + (index + 8)).value = totalSalary - this.salaryService.getTotalNominal(moneyWeHave);
            if (moneyWeHave['5000'] === -1) {
              vycetka.getCell('C' + (index + 7)).value = 0;
              vycetka.getCell('C' + (index + 8)).value = 0;
            } else {
              vycetka.getCell('C' + (index + 7)).value = moneyWeHave[5000];
              vycetka.getCell('C' + (index + 8)).value = totalAmount[5000] - moneyWeHave[5000];
            }
            if (moneyWeHave['2000'] === -1) {
              vycetka.getCell('D' + (index + 7)).value = 0;
              vycetka.getCell('D' + (index + 8)).value = 0;
            } else {
              vycetka.getCell('D' + (index + 7)).value = moneyWeHave[2000];
              vycetka.getCell('D' + (index + 8)).value = totalAmount[2000] - moneyWeHave[2000];
            }
            if (moneyWeHave['1000'] === -1) {
              vycetka.getCell('E' + (index + 7)).value = 0;
              vycetka.getCell('E' + (index + 8)).value = 0;
            } else {
              vycetka.getCell('E' + (index + 7)).value = moneyWeHave[1000];
              vycetka.getCell('E' + (index + 8)).value = totalAmount[1000] - moneyWeHave[1000];
            }
            if (moneyWeHave['500'] === -1) {
              vycetka.getCell('F' + (index + 7)).value = 0;
              vycetka.getCell('F' + (index + 8)).value = 0;
            } else {
              vycetka.getCell('F' + (index + 7)).value = moneyWeHave[500];
              vycetka.getCell('F' + (index + 8)).value = totalAmount[500] - moneyWeHave[500];
            }
            if (moneyWeHave['200'] === -1) {
              vycetka.getCell('G' + (index + 7)).value = 0;
              vycetka.getCell('G' + (index + 8)).value = 0;
            } else {
              vycetka.getCell('G' + (index + 7)).value = moneyWeHave[200];
              vycetka.getCell('G' + (index + 8)).value = totalAmount[200] - moneyWeHave[200];
            }
            if (moneyWeHave['100'] === -1) {
              vycetka.getCell('H' + (index + 7)).value = 0;
              vycetka.getCell('H' + (index + 8)).value = 0;
            } else {
              vycetka.getCell('H' + (index + 7)).value = moneyWeHave[100];
              vycetka.getCell('H' + (index + 8)).value = totalAmount[100] - moneyWeHave[100];
            }
            if (moneyWeHave['50'] === -1) {
              vycetka.getCell('I' + (index + 7)).value = 0;
              vycetka.getCell('I' + (index + 8)).value = 0;
            } else {
              vycetka.getCell('I' + (index + 7)).value = moneyWeHave[50];
              vycetka.getCell('I' + (index + 8)).value = totalAmount[50] - moneyWeHave[50];
            }
            if (moneyWeHave['20'] === -1) {
              vycetka.getCell('J' + (index + 7)).value = 0;
              vycetka.getCell('J' + (index + 8)).value = 0;
            } else {
              vycetka.getCell('J' + (index + 7)).value = moneyWeHave[20];
              vycetka.getCell('J' + (index + 8)).value = totalAmount[20] - moneyWeHave[20];
            }
            if (moneyWeHave['10'] === -1) {
              vycetka.getCell('K' + (index + 7)).value = 0;
              vycetka.getCell('K' + (index + 8)).value = 0;
            } else {
              vycetka.getCell('K' + (index + 7)).value = moneyWeHave[10];
              vycetka.getCell('K' + (index + 8)).value = totalAmount[10] - moneyWeHave[10];
            }
            if (moneyWeHave['5'] === -1) {
              vycetka.getCell('L' + (index + 7)).value = 0;
              vycetka.getCell('L' + (index + 8)).value = 0;
            } else {
              vycetka.getCell('L' + (index + 7)).value = moneyWeHave[5];
              vycetka.getCell('L' + (index + 8)).value = totalAmount[5] - moneyWeHave[5];
            }


            vycetka.getCell('A' + (index + 7)).style.border = thinBorder;
            vycetka.getCell('B' + (index + 7)).style.border = thinBorder;
            vycetka.getCell('C' + (index + 7)).style.border = thinBorder;
            vycetka.getCell('D' + (index + 7)).style.border = thinBorder;
            vycetka.getCell('E' + (index + 7)).style.border = thinBorder;
            vycetka.getCell('F' + (index + 7)).style.border = thinBorder;
            vycetka.getCell('G' + (index + 7)).style.border = thinBorder;
            vycetka.getCell('H' + (index + 7)).style.border = thinBorder;
            vycetka.getCell('I' + (index + 7)).style.border = thinBorder;
            vycetka.getCell('J' + (index + 7)).style.border = thinBorder;
            vycetka.getCell('K' + (index + 7)).style.border = thinBorder;
            vycetka.getCell('L' + (index + 7)).style.border = thinBorder;
            vycetka.getCell('A' + (index + 7)).style.fill = grayBg;
            vycetka.getCell('B' + (index + 7)).style.fill = grayBg;
            vycetka.getCell('C' + (index + 7)).style.fill = grayBg;
            vycetka.getCell('D' + (index + 7)).style.fill = grayBg;
            vycetka.getCell('E' + (index + 7)).style.fill = grayBg;
            vycetka.getCell('F' + (index + 7)).style.fill = grayBg;
            vycetka.getCell('G' + (index + 7)).style.fill = grayBg;
            vycetka.getCell('H' + (index + 7)).style.fill = grayBg;
            vycetka.getCell('I' + (index + 7)).style.fill = grayBg;
            vycetka.getCell('J' + (index + 7)).style.fill = grayBg;
            vycetka.getCell('K' + (index + 7)).style.fill = grayBg;
            vycetka.getCell('L' + (index + 7)).style.fill = grayBg;
            vycetka.getCell('A' + (index + 8)).style.border = thinBorder;
            vycetka.getCell('B' + (index + 8)).style.border = thinBorder;
            vycetka.getCell('C' + (index + 8)).style.border = thinBorder;
            vycetka.getCell('D' + (index + 8)).style.border = thinBorder;
            vycetka.getCell('E' + (index + 8)).style.border = thinBorder;
            vycetka.getCell('F' + (index + 8)).style.border = thinBorder;
            vycetka.getCell('G' + (index + 8)).style.border = thinBorder;
            vycetka.getCell('H' + (index + 8)).style.border = thinBorder;
            vycetka.getCell('I' + (index + 8)).style.border = thinBorder;
            vycetka.getCell('J' + (index + 8)).style.border = thinBorder;
            vycetka.getCell('K' + (index + 8)).style.border = thinBorder;
            vycetka.getCell('L' + (index + 8)).style.border = thinBorder;
            vycetka.getCell('A' + (index + 8)).style.fill = grayBg;
            vycetka.getCell('B' + (index + 8)).style.fill = grayBg;
            vycetka.getCell('C' + (index + 8)).style.fill = grayBg;
            vycetka.getCell('D' + (index + 8)).style.fill = grayBg;
            vycetka.getCell('E' + (index + 8)).style.fill = grayBg;
            vycetka.getCell('F' + (index + 8)).style.fill = grayBg;
            vycetka.getCell('G' + (index + 8)).style.fill = grayBg;
            vycetka.getCell('H' + (index + 8)).style.fill = grayBg;
            vycetka.getCell('I' + (index + 8)).style.fill = grayBg;
            vycetka.getCell('J' + (index + 8)).style.fill = grayBg;
            vycetka.getCell('K' + (index + 8)).style.fill = grayBg;
            vycetka.getCell('L' + (index + 8)).style.fill = grayBg;
          }
        }
      }
    });
// # Banka =============================================================================================================
    const banka = workbook.addWorksheet('Banka', {pageSetup: {fitToPage: true}});
    banka.getCell('A1').value = 'Geo-stav Valeč s.r.o.';
    banka.getCell('A2').value = 'číslo účtu: 115-8367300217/0100';
    banka.getCell('A2').font = {size: 11, bold: true};
    banka.getColumn('A').width = 14;

    banka.getCell('A4').value = 5000;
    banka.getCell('B4').value = 2000;
    banka.getCell('C4').value = 1000;
    banka.getCell('D4').value = 500;
    banka.getCell('E4').value = 200;
    banka.getCell('F4').value = 100;
    banka.getCell('G4').value = 50;
    banka.getCell('H4').value = 20;
    banka.getCell('I4').value = 10;
    banka.getCell('J4').value = 5;

    banka.getCell('A4').style.border = thinBorder;
    banka.getCell('B4').style.border = thinBorder;
    banka.getCell('C4').style.border = thinBorder;
    banka.getCell('D4').style.border = thinBorder;
    banka.getCell('E4').style.border = thinBorder;
    banka.getCell('F4').style.border = thinBorder;
    banka.getCell('G4').style.border = thinBorder;
    banka.getCell('H4').style.border = thinBorder;
    banka.getCell('I4').style.border = thinBorder;
    banka.getCell('J4').style.border = thinBorder;

    banka.getRow(4).numFmt = currencyFmt;

    if (moneyWeHave) {
      if (moneyWeHave['5000'] === -1) {
        banka.getCell('A5').value = 0;
        banka.getCell('A7').value = 0;
      } else {
        banka.getCell('A5').value = totalAmount[5000] - moneyWeHave[5000];
        banka.getCell('A7').value = (totalAmount[5000] - moneyWeHave[5000]) * 5000;
      }
      if (moneyWeHave['2000'] === -1) {
        banka.getCell('B5').value = 0;
        banka.getCell('B7').value = 0;
      } else {
        banka.getCell('B5').value = totalAmount[2000] - moneyWeHave[2000];
        banka.getCell('B7').value = (totalAmount[2000] - moneyWeHave[2000]) * 2000;
      }
      if (moneyWeHave['1000'] === -1) {
        banka.getCell('C5').value = 0;
        banka.getCell('C7').value = 0;
      } else {
        banka.getCell('C5').value = totalAmount[1000] - moneyWeHave[1000];
        banka.getCell('C7').value = (totalAmount[1000] - moneyWeHave[1000]) * 1000;
      }
      if (moneyWeHave['500'] === -1) {
        banka.getCell('D5').value = 0;
        banka.getCell('D7').value = 0;
      } else {
        banka.getCell('D5').value = totalAmount[500] - moneyWeHave[500];
        banka.getCell('D7').value = (totalAmount[500] - moneyWeHave[500]) * 500;
      }
      if (moneyWeHave['200'] === -1) {
        banka.getCell('E5').value = 0;
        banka.getCell('E7').value = 0;
      } else {
        banka.getCell('E5').value = totalAmount[200] - moneyWeHave[200];
        banka.getCell('E7').value = (totalAmount[200] - moneyWeHave[200]) * 200;
      }
      if (moneyWeHave['100'] === -1) {
        banka.getCell('F5').value = 0;
        banka.getCell('F7').value = 0;
      } else {
        banka.getCell('F5').value = totalAmount[100] - moneyWeHave[100];
        banka.getCell('F7').value = (totalAmount[100] - moneyWeHave[100]) * 100;
      }
      if (moneyWeHave['50'] === -1) {
        banka.getCell('G5').value = 0;
        banka.getCell('G7').value = 0;
      } else {
        banka.getCell('G5').value = totalAmount[50] - moneyWeHave[50];
        banka.getCell('G7').value = (totalAmount[50] - moneyWeHave[50]) * 50;
      }
      if (moneyWeHave['20'] === -1) {
        banka.getCell('H5').value = 0;
        banka.getCell('H7').value = 0;
      } else {
        banka.getCell('H5').value = totalAmount[20] - moneyWeHave[20];
        banka.getCell('H7').value = (totalAmount[20] - moneyWeHave[20]) * 20;
      }
      if (moneyWeHave['10'] === -1) {
        banka.getCell('I5').value = 0;
        banka.getCell('I7').value = 0;
      } else {
        banka.getCell('I5').value = totalAmount[10] - moneyWeHave[10];
        banka.getCell('I7').value = (totalAmount[10] - moneyWeHave[10]) * 10;
      }
      if (moneyWeHave['5'] === -1) {
        banka.getCell('J5').value = 0;
        banka.getCell('J7').value = 0;
      } else {
        banka.getCell('J5').value = totalAmount[5] - moneyWeHave[5];
        banka.getCell('J7').value = (totalAmount[5] - moneyWeHave[5]) * 5;
      }
      banka.getCell('B9').value = totalSalary - this.salaryService.getTotalNominal(moneyWeHave);
    } else {
      banka.getCell('A5').value = totalAmount[5000];
      banka.getCell('A7').value = totalAmount[5000] * 5000;
      banka.getCell('B5').value = totalAmount[2000];
      banka.getCell('B7').value = totalAmount[2000] * 2000;
      banka.getCell('C5').value = totalAmount[1000];
      banka.getCell('C7').value = totalAmount[1000] * 1000;
      banka.getCell('D5').value = totalAmount[500];
      banka.getCell('D7').value = totalAmount[500] * 500;
      banka.getCell('E5').value = totalAmount[200];
      banka.getCell('E7').value = totalAmount[200] * 200;
      banka.getCell('F5').value = totalAmount[100];
      banka.getCell('F7').value = totalAmount[100] * 100;
      banka.getCell('G5').value = totalAmount[50];
      banka.getCell('G7').value = totalAmount[50] * 50;
      banka.getCell('H5').value = totalAmount[20];
      banka.getCell('H7').value = totalAmount[20] * 20;
      banka.getCell('I5').value = totalAmount[10];
      banka.getCell('I7').value = totalAmount[10] * 10;
      banka.getCell('J5').value = totalAmount[5];
      banka.getCell('J7').value = totalAmount[5] * 5;

      banka.getCell('B9').value = totalSalary;
    }

    banka.getCell('A5').style.border = thinBorder;
    banka.getCell('B5').style.border = thinBorder;
    banka.getCell('C5').style.border = thinBorder;
    banka.getCell('D5').style.border = thinBorder;
    banka.getCell('E5').style.border = thinBorder;
    banka.getCell('F5').style.border = thinBorder;
    banka.getCell('G5').style.border = thinBorder;
    banka.getCell('H5').style.border = thinBorder;
    banka.getCell('I5').style.border = thinBorder;
    banka.getCell('J5').style.border = thinBorder;

    banka.getCell('A7').style.border = thinBorder;
    banka.getCell('B7').style.border = thinBorder;
    banka.getCell('C7').style.border = thinBorder;
    banka.getCell('D7').style.border = thinBorder;
    banka.getCell('E7').style.border = thinBorder;
    banka.getCell('F7').style.border = thinBorder;
    banka.getCell('G7').style.border = thinBorder;
    banka.getCell('H7').style.border = thinBorder;
    banka.getCell('I7').style.border = thinBorder;
    banka.getCell('J7').style.border = thinBorder;

    banka.getRow(7).numFmt = currencyFmt;

    banka.getCell('A9').value = 'Celkem';
    banka.mergeCells('B9:J9');

    banka.getCell('A9').style.border = thinBorder;
    banka.getCell('B9').style.border = thinBorder;
    banka.getCell('A9').font = {size: 11, bold: true};
    banka.getCell('B9').font = {size: 11, bold: true};
    banka.getRow(9).numFmt = currencyFmt;

    return workbook;
  }
}
