<button *ngIf="!isNew" class="btn btn-sm btn-success mx-1" (click)="openForm(previewModal)"><fa-icon [icon]="icon"></fa-icon></button>
<button *ngIf="isNew" class="btn btn-lg btn-gradient-custom" (click)="openForm(previewModal)">Přidat oblečení</button>
<ng-template #previewModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{isNew ? 'Vytvořit' : 'Upravit'}} oblečení</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="placeForm">
      <div class="col input-group form-floating form-group mb-3">
        <input
                formControlName="link"
                id="clothingLink"
                class="form-control"
                placeholder="nothing"
                required>
        <label for="clothingLink">{{placeForm.get('ownLink')?.value? 'Obecný odkaz:':'Odkaz:'}}</label>
        <span class="input-group-text bg-success pointer" (click)="getPrice()">
          <fa-icon *ngIf="!fetching" [icon]="scrapeIcon" class="scrapeIcon"></fa-icon>
          <fa-icon *ngIf="fetching" [icon]="loadingIcon" class="loadingIcon"></fa-icon>
        </span>
      </div>
      <div class="col form-floating form-group mb-3">
        <input
          formControlName="name"
          id="clothingName"
          class="form-control"
          placeholder="nothing"
          required>
        <label for="clothingName">Název:</label>
      </div>
      <div class="col form-floating form-group mb-3">
        <input
          formControlName="price"
          id="clothingPrice"
          class="form-control"
          placeholder="nothing"
          required>
        <label for="clothingPrice">Cena:</label>
      </div>
      <div class="col form-check">
        <input formControlName="numberedSizes" class="form-check-input" type="checkbox" value="" id="numberedSizes">
        <label class="form-check-label" for="numberedSizes">Číslované velikosti</label>
      </div>
      <div class="col form-check">
        <input formControlName="ownLink" class="form-check-input" type="checkbox" value="" id="ownLink">
        <label class="form-check-label" for="ownLink">Individuální odkazy</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button *ngIf="isNew" (click)="sendData()" [disabled]="!placeForm.valid" type="button" class="btn btn-success">Další</button>
    <button (click)="modal.close('Save click')" [disabled]="!placeForm.valid" type="button" class="btn btn-primary">Uložit</button>
  </div>
</ng-template>
