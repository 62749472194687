import {FileUploadModel} from "./fileUpload.model";

export class ReferenceCategoryModel {
  priority = 1;
  id = '';
  link  = '';
  name  = '';
  file = new FileUploadModel();
  galleryMode = false;
  subCategories: ReferenceSubCategoryModel[] = [];
}

export class ReferenceSubCategoryModel {
  id = '';
  name  = '';
}
