import { Component, Input } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import { ReferenceModel } from 'src/app/core/interfaces/reference.model';
import {ReferenceService} from 'src/app/core/services/reference.service';
import {FileService} from 'src/app/core/services/file.service';
import {ErrorResolutionService} from 'src/app/core/services/errorResolution.service';
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-reference-delete',
  templateUrl: './reference-delete.component.html',
  styleUrls: ['./reference-delete.component.scss']
})
export class ReferenceDeleteComponent {
  @Input() reference = new ReferenceModel();
  icon = faTrashCan;

  constructor(
    private referenceService: ReferenceService,
    private fileService: FileService,
    private modalService: NgbModal,
    private errorRes: ErrorResolutionService,
  ) { }

  showAlert(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', scrollable: true, centered: true}).result.then(result => {
      if (result === 'delete') {
        this.deleteReference();
        this.deleteFiles()
      }
      console.log('Formulář uzavřen pomocí:' + result);
    }, (reason) => {
      console.log(this.errorRes.getModalDismissReason(reason));
    });
  }
  deleteReference(): void {
    console.log('Mažu záznam ' + this.reference.id + ' reference ' + this.reference.name);
    this.referenceService.delete(this.reference.id);
  }
  deleteFiles(): void {
    console.log('Zahajuji mazání souborů reference ' + this.reference.name);
    this.fileService.deleteFolder('public/references/' + this.reference.id + '/preview');
    this.fileService.deleteFolder('public/references/' + this.reference.id + '/images');
  }
}
