import {Component, Input} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ExcelService} from "../../../../../core/services/excel.service";
import {EmployeeService} from "../../../../../core/services/employee.service";
import {
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFileImage,
  faFileVideo,
  faFile,
  faTrash,
  faDownload,
  faEye,
  faFileZipper,
  faCloudArrowUp
} from "@fortawesome/free-solid-svg-icons";
import {FileService} from "../../../../../core/services/file.service";
import {FileUploadModel} from "../../../../../core/interfaces/fileUpload.model";
import {EmployeeModel} from "../../../../../core/interfaces/employee.model";

import {MachineModel} from "../../../../../core/interfaces/machine.model";
import {MachineService} from "../../../../../core/services/machine.service";
import {UtilityService} from "../../../../../core/services/utility.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ErrorResolutionService} from "../../../../../core/services/errorResolution.service";

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss']
})
export class FileManagerComponent {
  fa = {
    pdf: faFilePdf,
    word: faFileWord,
    excel: faFileExcel,
    powerpoint: faFilePowerpoint,
    image: faFileImage,
    video: faFileVideo,
    audio: faFileVideo,
    file: faFile,
    archive: faFileZipper,
    trash: faTrash,
    download: faDownload,
    show: faEye,
    upload: faCloudArrowUp
  };
  @Input() model!: EmployeeModel | MachineModel;
  disableSending = false;
  uploadProgress = 0;

  constructor(
    private excelService: ExcelService,
    private employeeService: EmployeeService,
    public utilityService: UtilityService,
    private machineService: MachineService,
    private errorRes: ErrorResolutionService,
    private modalService: NgbModal,
    private fileService: FileService,
    private route: ActivatedRoute,
  ) {
  }

  onFileChange(event: any): void {
    let path = '';
    if(this.model instanceof EmployeeModel) {
      path = '/employees/';
    } else {
      path = '/machines/';
    }
    const file = event.target.files[0];
    if(!this.model.files.find(item => item.name === file.name)) {
      this.uploadProgress = 0;
      this.disableSending = true;
      this.fileService.upload(file, path + this.model.id + '/files', false).subscribe(result => {
        if (typeof result === 'number') {
          this.uploadProgress = Math.round(result);
        } else {
          this.model.files.push(result);
          if(this.model instanceof EmployeeModel) {
            this.employeeService.upsert(this.model);
          } else {
            this.machineService.upsert(this.model);
          }
          this.disableSending = false;
          this.uploadProgress = 0;
        }
      });
    } else {
        window.alert("Soubor s tímto názvem je již nahrán")
    }
  }
  getFileType(name: string): string {
    return this.fileService.getFileType(name);
  }
  formatBytes(a: number) {
    return this.fileService.formatBytes(a);
  }
  delete(file: FileUploadModel, content: any) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', scrollable: true, centered: true}).result.then(result => {
      if (result === 'delete') {
        const index = this.model.files.indexOf(file);
        const x = this.model.files.splice(index, 1);
        this.fileService.delete(file.src).then(r => {
          if(this.model instanceof EmployeeModel) {
            this.employeeService.upsert(this.model);
          } else {
            this.machineService.upsert(this.model);
          }
        });
      }
      console.log('Formulář uzavřen pomocí:' + result);
    }, (reason) => {
      console.log(this.errorRes.getModalDismissReason(reason));
    });
  }
}
