import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {MachineRepositoryService} from "../repositories/machine-repository.service";
import {MachineModel} from "../interfaces/machine.model";

@Injectable({
  providedIn: 'root'
})
export class MachineService {

  constructor(
    private repository: MachineRepositoryService,
  ) {}

  createId(): string {
    const id = this.repository.createId();
    console.log('Generuji ID ' + id + ' pro záznam stroje...');
    return id;
  }

  update(reference: MachineModel): Observable<any> {
    console.log('Aktualizuji záznam stroje ' + reference.id + '...');
    return this.repository.update(reference);
  }
  upsert(reference: MachineModel): Observable<any> {
    console.log('Aktualizuji/Vytvářím záznam stroje ' + reference.id + '...');
    return this.repository.upsert(reference);
  }
  delete(id: string): Observable<void> {
    console.log('Mažu záznam stroje ' + id + '...');
    return this.repository.deleteDocument(id);
  }

  getAll(): Observable<MachineModel[]> {
    console.log('Načítám záznamy všech strojů...');
    return this.repository.getAll({orderBy: 'manufacturer', orderByDirection: 'asc'});
  }
  getById(id: string): Observable<any> {
    console.log('Načítám záznam stroje ' + id);
    return this.repository.getById(id);
  }
}
