import { Injectable } from '@angular/core';
import {AttendancePlaceEnum} from "../interfaces/employeeAttendance.model";
import {SalaryModel} from "../interfaces/employee.model";

@Injectable({
  providedIn: 'root'
})
export class SalaryService {

  constructor() {}

  getTotalNominal(haveMoney: any) {
    let pom = Object.assign({}, haveMoney);
    if(pom[5000] == -1) {pom[5000]=0}
    if(pom[2000] == -1) {pom[2000]=0}
    if(pom[1000] == -1) {pom[1000]=0}
    if(pom[500] == -1) {pom[500]=0}
    if(pom[200] == -1) {pom[200]=0}
    if(pom[100] == -1) {pom[100]=0}
    if(pom[50] == -1) {pom[50]=0}
    if(pom[20] == -1) {pom[20]=0}
    if(pom[10] == -1) {pom[10]=0}
    if(pom[5] == -1) {pom[5]=0}
    return pom[5000]*5000
      + pom[2000]*2000
      + pom[1000]*1000
      + pom[500]*500
      + pom[200]*200
      + pom[100]*100
      + pom[50]*50
      + pom[20]*20
      + pom[10]*10
      + pom[5]*5;
  }
  calculateSalaryForDay(place: AttendancePlaceEnum, hours: number, multiplier: number, currentSalary: SalaryModel): number {
    let salary;
    switch (place) {
      case AttendancePlaceEnum.construction:
        salary = (currentSalary.construction * hours);
        break;
      case AttendancePlaceEnum.workshop:
        salary = (currentSalary.workshop * hours);
        break;
      case AttendancePlaceEnum.other:
        salary = (currentSalary.other * hours);
        break;
      case AttendancePlaceEnum.sick:
        salary = (currentSalary.construction * hours * multiplier);
        break;
      default:
        salary = (currentSalary.construction * hours);
        break;
    }
    return salary;
  }
  getAmountOfBills(salary: number, amount: number, haveMoney: any) {
    if (haveMoney[amount] == -1) {
      amount = 0;
    } else {
      switch (amount) {
        case 5000:
          amount = Math.floor(salary / 5000);
          break;
        case 2000:
          amount = Math.floor((salary
            - this.getAmountOfBills(salary, 5000, haveMoney) * 5000
          ) / 2000);
          break;
        case 1000:
          amount = Math.floor((salary
            - this.getAmountOfBills(salary, 5000, haveMoney) * 5000
            - this.getAmountOfBills(salary, 2000, haveMoney) * 2000
          ) / 1000);
          break;
        case 500:
          amount = Math.floor((salary
            - this.getAmountOfBills(salary, 5000, haveMoney) * 5000
            - this.getAmountOfBills(salary, 2000, haveMoney) * 2000
            - this.getAmountOfBills(salary, 1000, haveMoney) * 1000
          ) / 500);
          break;
        case 200:
          amount = Math.floor((salary
            - this.getAmountOfBills(salary, 5000, haveMoney) * 5000
            - this.getAmountOfBills(salary, 2000, haveMoney) * 2000
            - this.getAmountOfBills(salary, 1000, haveMoney) * 1000
            - this.getAmountOfBills(salary, 500, haveMoney) * 500
          ) / 200);
          break;
        case 100:
          amount = Math.floor((salary
            - this.getAmountOfBills(salary, 5000, haveMoney) * 5000
            - this.getAmountOfBills(salary, 2000, haveMoney) * 2000
            - this.getAmountOfBills(salary, 1000, haveMoney) * 1000
            - this.getAmountOfBills(salary, 500, haveMoney) * 500
            - this.getAmountOfBills(salary, 200, haveMoney) * 200
          ) / 100);
          break;
        case 50:
          amount = Math.floor((salary
            - this.getAmountOfBills(salary, 5000, haveMoney) * 5000
            - this.getAmountOfBills(salary, 2000, haveMoney) * 2000
            - this.getAmountOfBills(salary, 1000, haveMoney) * 1000
            - this.getAmountOfBills(salary, 500, haveMoney) * 500
            - this.getAmountOfBills(salary, 200, haveMoney) * 200
            - this.getAmountOfBills(salary, 100, haveMoney) * 100
          ) / 50);
          break;
        case 20:
          amount = Math.floor((salary
            - this.getAmountOfBills(salary, 5000, haveMoney) * 5000
            - this.getAmountOfBills(salary, 2000, haveMoney) * 2000
            - this.getAmountOfBills(salary, 1000, haveMoney) * 1000
            - this.getAmountOfBills(salary, 500, haveMoney) * 500
            - this.getAmountOfBills(salary, 200, haveMoney) * 200
            - this.getAmountOfBills(salary, 100, haveMoney) * 100
            - this.getAmountOfBills(salary, 50, haveMoney) * 50
          ) / 20);
          break;
        case 10:
          amount = Math.floor((salary
            - this.getAmountOfBills(salary, 5000, haveMoney) * 5000
            - this.getAmountOfBills(salary, 2000, haveMoney) * 2000
            - this.getAmountOfBills(salary, 1000, haveMoney) * 1000
            - this.getAmountOfBills(salary, 500, haveMoney) * 500
            - this.getAmountOfBills(salary, 200, haveMoney) * 200
            - this.getAmountOfBills(salary, 100, haveMoney) * 100
            - this.getAmountOfBills(salary, 50, haveMoney) * 50
            - this.getAmountOfBills(salary, 20, haveMoney) * 20
          ) / 10);
          break;
        case 5:
          amount = Math.floor((salary
            - this.getAmountOfBills(salary, 5000, haveMoney) * 5000
            - this.getAmountOfBills(salary, 2000, haveMoney) * 2000
            - this.getAmountOfBills(salary, 1000, haveMoney) * 1000
            - this.getAmountOfBills(salary, 500, haveMoney) * 500
            - this.getAmountOfBills(salary, 200, haveMoney) * 200
            - this.getAmountOfBills(salary, 100, haveMoney) * 100
            - this.getAmountOfBills(salary, 50, haveMoney) * 50
            - this.getAmountOfBills(salary, 20, haveMoney) * 20
            - this.getAmountOfBills(salary, 10, haveMoney) * 10
          ) / 5);
          break;
      }
    }
    return amount;
  }
}
