<div id="map" class="row footer-map">
  <div class="col-12">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6 justify-content-md-center text-nowrap text-center text-md-start">
        <h2>Zaujali jsme vás?<br>Vytvořme společně něco <br class="d-sm-none">NOVÉHO!</h2>
        <button (click)="contactFormSlide()" type="submit" class="btn mt-5 fs-5 btn-primary">Poptejte nás</button>
        <div class="mt-5 footer-map-links">
          <a class="footer-link-a fs-2 text-nowrap" href="mailto:{{contactData.email}}">{{contactData.email}}</a><br>
          <a class="footer-link-b my-3 fs-4 text-nowrap" href="tel:+420{{contactData.phoneNumber}}">+420 {{contactData.phoneNumber}}</a>
        </div>
      </div>
      <div class="col-12 col-md-3 mt-md-0 mt-5 justify-content-md-center text-center text-md-start">
        <div class="row d-md-inline d-none">
          <br><br><br><br><br><br><br><br>
        </div>
        <div class="row">
          <div class="footer-links">
            <a class="footer-link text-nowrap" href="https://goo.gl/maps/Z9qxYDYFk4XYmats7" target="_blank">{{contactData.address1}}<br />{{contactData.address2}}</a>
            <form method="get" action="">
              <button (click)="slideToTop()" type="submit" class="btn btn-sm my-3 fs-6 btn-primary text-nowrap" routerLink="/contacts">Všechny kontakty</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="contactform">
  <div class="row justify-content-center footer-contactform">
    <div class="col-xl-5 col-lg-7 col-md-9 col-12">
      <app-web-footer-contact-form></app-web-footer-contact-form>
    </div>
  </div>
</div>
