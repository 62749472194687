<button *ngIf="!isNew" class="btn btn-sm btn-success mx-1" (click)="openForm(addForm)"><fa-icon [icon]="icon"></fa-icon></button>
<button *ngIf="isNew" class="btn btn-lg btn-gradient-custom" (click)="openForm(addForm)">Přidat referenci</button>
<ng-template #addForm let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{isNew ? 'Vytvořit' : 'Upravit'}} referenci</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="referenceForm">
      <div class="row">
        <div class="col-sm-6 col-12 form-group mb-3">
          <select
            formControlName="category"
            class="form-control form-control-lg"
            required>
            <option [value]="null" disabled>Kategorie:</option>
            <ng-container *ngFor="let category of categoryList">
              <option [value]="category.id">
                {{category.name}}
              </option>
            </ng-container>
          </select>
        </div>
        <div *ngIf="!isCategoryGallery()" class="col-sm-6 col-12 form-group mb-3">
          <select
            formControlName="place"
            class="form-control form-control-lg"
            required>
            <option [value]="null" disabled>Místo:</option>
            <ng-container *ngFor="let place of placeList">
              <option [value]="place.id">
                {{place.name}}
              </option>
            </ng-container>
          </select>
        </div>
        <div *ngIf="isCategoryGallery() && getCategory()?.subCategories" class="col-sm-6 col-12 form-group mb-3">
          <select
            formControlName="subCategory"
            class="form-control form-control-lg"
            required>
            <option [value]="null" disabled>Podkategorie:</option>
            <ng-container *ngFor="let sub of getCategory()?.subCategories">
              <option [value]="sub.id">
                {{sub.name}}
              </option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="col form-floating form-group mb-3">
        <input formControlName="name" id="referenceName" class="form-control" placeholder="nothing" required>
        <label for="referenceName">Název:</label>
      </div>
      <ng-container *ngIf="!isCategoryGallery()">
        <div class="row">
          <div class="col-sm-6 col-12 form-floating form-group mb-3">
            <input formControlName="dateFrom" id="referenceDateFrom" type="date" class="form-control" placeholder="XX.XX.XXXX" required>
            <label for="referenceDateFrom" class="mx-4">Datum od:</label>
          </div>
          <div class="col-sm-6 col-12  form-floating form-group mb-3">
            <input formControlName="dateTo" id="referenceDateTo" type="date" class="form-control" placeholder="YY.YY.YYYY" required>
            <label for="referenceDateTo" class="mx-4">Datum do:</label>
          </div>
        </div>
      </ng-container>
      <div class="col form-floating form-group mb-3">
        <input formControlName="basicInfo" id="referenceBasicInfo" class="form-control" placeholder="nothing" required>
        <label for="referenceBasicInfo">Základní informace:</label>
      </div>
      <ng-container *ngIf="!isCategoryGallery()">
        <div class="form-floating mb-3">
          <textarea formControlName="description" id="referenceDescription" class="form-control" placeholder="nothing" required></textarea>
          <label for="referenceDescription">Popis:</label>
        </div>
      </ng-container>
      <div class="col form-group mb-3">
        <label for="referencePreviewImage" class="mb-1">Náhled:</label>
        <div *ngIf="(!isNew && !smallImageDisableSending) || (isNew && !smallImageDisableSending && smallImageUploadProgress === 100)" class="text-center mb-3">
          <img src="{{reference.previewImage.src}}" alt="{{reference.previewImage.name}}">
        </div>
        <p *ngIf="smallImageDisableSending">
          <ngb-progressbar
            [showValue]="true"
            [value]="smallImageUploadProgress"
            [striped]="true"
            [animated]="true"
          ></ngb-progressbar>
        </p>
        <input
          (change)="chooseSmallImage($event)"
          [required]="isNew && reference.previewImage.src===''"
          [attr.disabled]="smallImageDisableSending ? '' : null"
          accept="image/jpeg, image/png"
          formControlName="smallImage"
          id="referencePreviewImage"
          type="file"
          class="form-control">
      </div>
      <div class="col form-group mb-3">
        <label for="referenceImage" class="mb-1">Fotky:</label>
        <div *ngIf="reference.mainImage[0]" class="mb-3">
          <table class="table table-hover">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Název</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
              <tr *ngFor="let image of reference.mainImage; index as i">
                <th scope="row">{{ i + 1 }}</th>
                <td><app-image-hover-info [image]="image"></app-image-hover-info></td>
                <td><button type="button" class="btn-close btn-close-danger" (click)="removeImg(image)"></button></td>
              </tr>
            </tbody>
          </table>
        </div>
        <p *ngIf="largeImageDisableSending">
          <ngb-progressbar
            [showValue]="true"
            [value]="largeImageUploadProgress"
            [striped]="true"
            [animated]="true"
          ></ngb-progressbar>
        </p>
        <input
          (change)="chooseLargeImage($event)"
          [required]="(isNew && reference.mainImage.length===0) && !isCategoryGallery()"
          [attr.disabled]="largeImageDisableSending? '' : null"
          accept="image/jpeg, image/png"
          formControlName="largeImage"
          id="referenceImage"
          type="file"
          class="form-control">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button *ngIf="isNew"  (click)="sendData()" [disabled]="smallImageDisableSending || largeImageDisableSending || !referenceForm.valid" type="button" class="btn btn-success">Další</button>
    <button (click)="modal.close('Save click')" [disabled]="smallImageDisableSending || largeImageDisableSending || !referenceForm.valid" type="button" class="btn btn-primary">Uložit</button>
  </div>
</ng-template>

