<button *ngIf="!isNew" class="btn btn-sm btn-success mx-1" (click)="openForm(previewModal)"><fa-icon [icon]="icon"></fa-icon></button>
<button *ngIf="isNew" class="btn btn-lg btn-gradient-custom" (click)="openForm(previewModal)">Přidat kontaktní osobu</button>
<ng-template #previewModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{isNew ? 'Vytvořit' : 'Upravit'}} kontaktní osobu</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="personForm">
      <div class="col form-floating form-group mb-3">
        <input formControlName="priority" id="priority" type="number" class="form-control" placeholder="nothing" required>
        <label for="priority">Priorita:</label>
      </div>
      <div class="col form-floating form-group mb-3">
        <input formControlName="name" id="name" class="form-control" placeholder="nothing" required>
        <label for="name">Jméno:</label>
      </div>
      <div class="col form-floating form-group mb-3">
        <input formControlName="function" id="function" class="form-control" placeholder="nothing" required>
        <label for="function">Funkce:</label>
      </div>
      <div class="col form-floating form-group mb-3">
        <input formControlName="email" id="email" class="form-control" placeholder="nothing" required>
        <label for="email">E-mail:</label>
      </div>
      <div class="col form-floating form-group mb-3">
        <input (change)="onTelChange()" formControlName="phoneNumber" id="phoneNumber" class="form-control" placeholder="nothing" required>
        <label for="phoneNumber">Tel:</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button *ngIf="isNew" (click)="sendData()" [disabled]="!personForm.valid" type="button" class="btn btn-success">Další</button>
    <button (click)="modal.close('Save click')" [disabled]="!personForm.valid" type="button" class="btn btn-primary">Uložit</button>
  </div>
</ng-template>
