import {Component, Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import { ReferencePlaceModel } from 'src/app/core/interfaces/referencePlace.model';
import {ErrorResolutionService} from 'src/app/core/services/errorResolution.service';
import {faEye} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-place-preview',
  templateUrl: './place-preview.component.html',
  styleUrls: ['./place-preview.component.scss']
})
export class PlacePreviewComponent {
  @Input() place = new ReferencePlaceModel();
  icon = faEye;

  constructor(
    private modalService: NgbModal,
    private errorRes: ErrorResolutionService,
  ) { }

  showPlace(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', scrollable: true, centered: true}).result.then(result => {
      console.log('Formulář uzavřen pomocí:' + result);
    }, (reason) => {
      console.log(this.errorRes.getModalDismissReason(reason));
    });
  }
}
