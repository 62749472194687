import {Component, OnInit} from '@angular/core';
import {ClothesModel} from "../../../../../../core/interfaces/clothes.model";
import {ClothesService} from "../../../../../../core/services/clothes.service";
import {ClothingSizeService} from "../../../../../../core/services/clothing-size.service";
import {EmployeeService} from "../../../../../../core/services/employee.service";
import {EmployeeModel} from "../../../../../../core/interfaces/employee.model";
import {ClothingSizeModel} from "../../../../../../core/interfaces/clothingSize.model";
import {Observable} from "rxjs";

@Component({
  selector: 'app-employee-clothing-list',
  templateUrl: './employee-clothing-table.component.html',
  styleUrls: ['./employee-clothing-table.component.scss']
})
export class EmployeeClothingTableComponent implements OnInit {
  clothesList: ClothesModel[] = [];
  employeeList: EmployeeModel[] = [];
  sizeList: ClothingSizeModel[] = [];

  constructor(
    private clothesService: ClothesService,
    private clothingSizeService: ClothingSizeService,
    private employeeService: EmployeeService,
  ) {}

  ngOnInit(): void {
    this.getAllClothes();
  }
  getAllClothes(): void {
    this.clothesService.getAll().subscribe(result => {
      if (result.length > 0) {
        this.clothesList = result;
        this.getAllEmployees();
      } else {
        this.clothesList = [];
        console.log('Žádný záznam oblečení neexistuje!!!')
      }
    });
  }
  getAllEmployees(): void {
    this.employeeService.getAllActive().subscribe(result => {
      if (result.length > 0) {
        this.employeeList = result;
        this.getAllSizes();
      } else {
        this.employeeList = [];
        console.log('Žádný záznam oblečení neexistuje!!!')
      }
    });
  }
  getAllSizes(): void {
    this.clothesList.forEach(clothing => {
      this.employeeList.forEach(employee => {
        this.clothingSizeService.getByEmployee(clothing.id, employee.id).subscribe(result => {
          if (result.docs.length !== 0) {
            const sizeData = result.docs[0].data()
            this.sizeList.push(sizeData);
          }
        });
      });
    });
  }
  getSize(empId: string, clothing: ClothesModel): Observable<ClothingSizeModel[]> {
    return new Observable<ClothingSizeModel[]>(observer => {
      const filteredSize = this.sizeList.filter(size => {
        return (size.employeeID === empId && size.clothsID === clothing.id)
      })
      if (filteredSize.length !== 0) {
        observer.next(filteredSize);
      } else {
        observer.next([{clothsID: "", employeeID: "", id: "", link: "", size: "-"}]);
      }
    });
  }
}
