<app-site-header
  [headerLine1]="headerL1"
  [headerLine2]="headerL2"
  [btnType]="btnType"
></app-site-header>
<main>
  <div class="row">
    <div class="col-12 text-center bg-white shadow-lg">
      <div class="row mt-5">
        <h1>Základní informace</h1>
      </div>
      <div class="row">
        <div class="col-xxl-6 col-xl-5 col-lg-6 col-12">
          <div class="row mb-2 mt-5 justify-content-xl-center justify-content-around">
            <div class="col-sm-6 col-12 mb-3 link text-nowrap">
              <h3 class="my-0">Název</h3>
              {{contactData.name}}
            </div>
            <div class="col-sm-6 col-12 mb-3 link text-nowrap">
              <h3 class="my-0">Adresa</h3>
              <a href="https://www.google.cz/maps/place/{{contactData.address1}}" target="_blank">{{contactData.address1}}</a>
            </div>
            <div class="col-sm-6 col-12 mb-3 link text-nowrap">
              <h3 class="my-0">Kontakt</h3>
              <b>Email: </b> <a href="mailto:{{contactData.email}}">{{contactData.email}}</a><br>
              <b>Dat. schránka: </b><a href=" https://www.mojedatovaschranka.cz/">{{contactData.dataBox}}</a><br>
            </div>
            <div class="col-sm-6 col-12 mb-3 link text-nowrap">
              <h3 class="my-0">IČ/DIČ</h3>
              <b>IČ:</b> {{contactData.in}}<br>
              <b>DIČ:</b> {{contactData.taxNumber}}
            </div>
          </div>
          <div class="row mt-5">
            <h1>Kontakty</h1>
          </div>
          <div class="row mb-2 mt-5 justify-content-around">
            <div *ngFor="let person of people" class="col link text-nowrap">
              <h3 class="my-0">{{person.name}}</h3>
              <b>{{person.function}}</b><br>
              <b>Email:</b> <a href="mailto:{{person.email}}"> {{person.email}}</a><br>
              <b>Tel:</b> <a href="tel:+420{{person.phoneNumber}}"> +420 {{person.phoneNumber}}</a>
            </div>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-7 col-lg-6 col-12">
          <div class="d-xl-none d-lg-inline d-none">
            <br>
            <br>
            <br>
            <br>
          </div>
          <iframe class="d-sm-none d-inline mt-5 map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2480.6832159073515!2d16.03692481568462!3d49.147143079316855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470d5c08ec9cd08f%3A0x5a873c50c73b6084!2zVmFsZcSNIDE0MiwgNjc1IDUzIFZhbGXEjQ!5e1!3m2!1scs!2scz!4v1624899528212!5m2!1scs!2scz" width="300" height="225" allowfullscreen="" loading="eager"></iframe>
          <iframe class="d-xl-none d-sm-inline d-none mt-lg-0 mt-5 map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2480.6832159073515!2d16.03692481568462!3d49.147143079316855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470d5c08ec9cd08f%3A0x5a873c50c73b6084!2zVmFsZcSNIDE0MiwgNjc1IDUzIFZhbGXEjQ!5e1!3m2!1scs!2scz!4v1624899528212!5m2!1scs!2scz" width="400" height="300" allowfullscreen="" loading="eager"></iframe>
          <iframe class="d-xl-inline d-none mt-5 map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2480.6832159073515!2d16.03692481568462!3d49.147143079316855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470d5c08ec9cd08f%3A0x5a873c50c73b6084!2zVmFsZcSNIDE0MiwgNjc1IDUzIFZhbGXEjQ!5e1!3m2!1scs!2scz!4v1624899528212!5m2!1scs!2scz" width="600" height="450" allowfullscreen="" loading="eager"></iframe>
        </div>
      </div>
      <div class="row">
        <div class="col-12 bg-white content-box">
          <br />
          <br />
        </div>
      </div>
    </div>
  </div>
</main>
