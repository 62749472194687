import {Component, OnInit} from '@angular/core';
import {EmployeeService} from "../../../../../../../core/services/employee.service";
import {EmployeeModel} from "../../../../../../../core/interfaces/employee.model";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MonthlyAttendance} from "../../../../../../../core/interfaces/employeeAttendance.model";

import {AttendanceService} from "../../../../../../../core/services/attendance.service";

import {ExcelService} from "../../../../../../../core/services/excel.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ErrorResolutionService} from "../../../../../../../core/services/errorResolution.service";
import {SalaryService} from "../../../../../../../core/services/salary.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-employee-attendance-overview',
  templateUrl: './employee-attendance-overview.component.html',
  styleUrls: ['./employee-attendance-overview.component.scss']
})
export class EmployeeAttendanceOverviewComponent implements OnInit {
  selectionForm!: FormGroup;
  employeeList: EmployeeModel[] = [];
  currentMonth = new Date().getFullYear().toString() + '-' + ('0' + (new Date().getMonth() + 1)).toString().slice(-2);
  selectedMonth = this.currentMonth;
  intervalMin = "2019-01"
  monthlyAttendanceList: any = {};
  haveMoney = {
    5000: 0,
    2000: 0,
    1000: 0,
    500: 0,
    200: 0,
    100: 0,
    50: 0,
    20: 0,
    10: 0,
    5: 0
  }

  constructor(
    private modalService: NgbModal,
    private errorRes: ErrorResolutionService,
    private excelService: ExcelService,
    public salaryService: SalaryService,
    private service: AttendanceService,
    private employeeService: EmployeeService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getAllEmployees();
    this.route.queryParamMap.subscribe(queryParamMap => {
      if (queryParamMap.get('month')) {
        console.log("Změna parametru onInit")
        this.selectedMonth = queryParamMap.get('month')!;
      }
    });
    this.buildForm();
    this.onMonthChange();
  }

  getAllEmployees(): void {
    this.employeeService.getAll().subscribe(result => {
      if (result.length > 0) {
        this.employeeList = result;
        this.employeeList.forEach(employee => {
          this.service.getAllForEmployee(employee.id).subscribe(value => {
            this.monthlyAttendanceList[employee.id] = value;
          });
        });
      } else {
        this.employeeList = [];
        console.log('Žádný záznam zaměstnanců neexistuje!!!');
      }
    });
  }
  getAttendance(empID:string): MonthlyAttendance | undefined{
    if (this.monthlyAttendanceList[empID]) {
      let filtered = this.monthlyAttendanceList[empID].filter((month: any) => {
        return month.id == this.selectedMonth;
      });
      if (filtered[0]) {
        return filtered[0];
      }
    }
    return undefined;
  }
  getSalary(empID:string): number {
    if (this.monthlyAttendanceList[empID]) {
      let filtered = this.monthlyAttendanceList[empID].filter((month: any) => {
        return month.id == this.selectedMonth;
      });
      if (filtered[0]) {
        return (filtered[0].salary + filtered[0].salaryBonus);
      }
    }
    return 0
  }
  getTotal() {
    let total = 0;
    this.employeeList.forEach(employee => {
      total += this.getSalary(employee.id);
    });
    return total;
  }
  getTotalNeeded() {
    let pom = Object.assign({}, this.haveMoney);
    if(pom[5000] == -1) {pom[5000]=0}
    if(pom[2000] == -1) {pom[2000]=0}
    if(pom[1000] == -1) {pom[1000]=0}
    if(pom[500] == -1) {pom[500]=0}
    if(pom[200] == -1) {pom[200]=0}
    if(pom[100] == -1) {pom[100]=0}
    if(pom[50] == -1) {pom[50]=0}
    if(pom[20] == -1) {pom[20]=0}
    if(pom[10] == -1) {pom[10]=0}
    if(pom[5] == -1) {pom[5]=0}
    return this.getTotal()
    - pom[5000]*5000
    - pom[2000]*2000
    - pom[1000]*1000
    - pom[500]*500
    - pom[200]*200
    - pom[100]*100
    - pom[50]*50
    - pom[20]*20
    - pom[10]*10
    - pom[5]*5;
  }
  getTotalAmount(number: number) {
    let total = 0;
    this.employeeList.forEach(employee => {
      total += this.salaryService.getAmountOfBills(this.getSalary(employee.id), number, this.haveMoney);
    });
    return total;
  }
  buildForm(): void {
    this.selectionForm = this.formBuilder.group({
      month: new FormControl(this.selectedMonth || '', [Validators.required])
    });
  }
  onMonthChange(): void {
    this.selectedMonth = this.selectionForm.get('month')?.value;
    this.router.navigate([], {
      relativeTo: this.route, queryParams: {
        month: this.selectedMonth
      }
    }).then();
  }

  export(): void {
    this.excelService.saveAllAsExcel(this.employeeList, this.selectedMonth, this.haveMoney);
  }

  amountCheck(amount: number): void {
    // @ts-ignore
    if (this.haveMoney[amount] == -1) {
      // @ts-ignore
      this.haveMoney[amount] = 0;
    } else {
      // @ts-ignore
      this.haveMoney[amount] = -1;
    }
  }
}
