<h2 class="card-title text-center mb-3">Docházka</h2>

<ng-container *ngIf="employeeList[0]">
  <form [formGroup]="selectionForm" class="mb-3">
    <div class="row row-cols-1 row-cols-lg-3 justify-content-around selectionInputs">
      <div class="mb-2 mb-lg-0 col-8 col-sm-6 col-lg-3 col-xl-2">
        <input
          (change)="getDaysArray()"
          formControlName="month"
          type="month"
          class="form-control">
      </div>
      <div class="mb-2 mb-lg-0 col-12 col-lg-6 col-xl-8">
        <div class="row d-flex justify-content-center">
          <div class="col text-end  mt-auto">
            <mat-icon *ngIf="employeeList.length>1" class="icon-display" (click)="changeEmployee('previous')">
              keyboard_double_arrow_left
            </mat-icon>
          </div>
          <div class="col-8 col-sm-6 col-lg-8 col-xl-6">
            <select
              (change)="changeEmployee('none')"
              class="form-control"
              formControlName="employee">
              <option
                *ngFor="let employee of employeeList"
                [value]="employee.id">
                {{ employee.lastName }} {{ employee.firstName }}
              </option>
            </select>
          </div>
          <div class="col mt-auto">
            <mat-icon *ngIf="employeeList.length>1" class="icon-display" (click)="changeEmployee('next')">
              keyboard_double_arrow_right
            </mat-icon>
          </div>
        </div>
      </div>
      <div class="col-8 col-sm-6 col-lg-3 col-xl-2">
        <div class="btn-group w-100" role="group" aria-label="Basic example">
          <button class="btn btn-warning export-button" (click)="navigateToOverview()">
            <span>Přehled</span>
          </button>
          <button class="btn btn-success export-button" (click)="export()">
            <span>Exportovat</span>
          </button>
        </div>
      </div>
    </div>
  </form>
  <div class="row d-none d-md-block">
    <div class="col">
      <table class="table table-hover">
        <thead>
        <tr>
          <th scope="col" class="col-date">Datum</th>
          <th scope="col" class="col-day">Den</th>
          <th scope="col" class="col-hours">Hodin</th>
          <th scope="col" class="col-activity">Činnost</th>
          <th scope="col" class="col-description">Popis práce</th>
          <th scope="col" class="col-salary text-end">Kč</th>
        </tr>
        </thead>
        <tbody>
        <tr inputTr *ngFor="let day of daysOfMonth" [day]="day" [employee]="selectedEmployee" [attendance]="attendance"
            (inputChange)="onSubmit(false)">
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row d-flex justify-content-around justify-content-xl-between">
    <div class="col-auto">
      <table class="table">
        <thead>
        <tr>
          <th></th>
          <th class="col-salary"></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="text-end">Dovolená na začátku měsíce:</td>
          <td class="text-end">{{ attendance.vacationHoursAtStart }} h</td>
        </tr>
        <tr>
          <td class="text-end">Dovolená v tomto měsíci:</td>
          <td class="text-end">{{ attendance.vacationHoursDuring }} h</td>
        </tr>
        <tr>
          <th scope="row" class="text-end">Zbývající dovolená:</th>
          <th scope="row" class="text-end">{{ attendance.vacationHoursAtStart - attendance.vacationHoursDuring }} h</th>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="col-auto">
      <table class="table">
        <thead>
        <tr>
          <th></th>
          <th class="col-salary"></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="text-end">Stavba:</td>
          <td class="text-end">{{ attendance.currentSalary.construction }} Kč/h</td>
        </tr>
        <tr>
          <td class="text-end">Dílna:</td>
          <td class="text-end">{{ attendance.currentSalary.workshop }} Kč/h</td>
        </tr>
        <tr>
          <td class="text-end">Jiné:</td>
          <td class="text-end">{{ attendance.currentSalary.other }} Kč/h</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="col-auto">
      <table class="table">
        <thead>
        <tr>
          <th></th>
          <th class="col-activity"></th>
          <th class="col-salary"></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="text-end">Hodiny</td>
          <td class="text-center">
            <span [ngbTooltip]="tipworkHours">{{ attendance.workHours }} h</span>
          </td>
          <td class="text-end">{{ attendance.salary }} Kč</td>
        </tr>
        <tr>
          <td class="text-end">Odměny</td>
          <td></td>
          <td class="d-flex justify-content-end">
            <input [(ngModel)]="attendance.salaryBonus" type="number" class="bonusInput me-1"> Kč
          </td>
        </tr>
        <tr>
          <th scope="row" class="text-end">Celkem</th>
          <td></td>
          <th scope="row" class="text-end">{{ attendance.salary + attendance.salaryBonus }} Kč</th>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row d-none d-md-block mt-3">
    <div class="col">
      <div class="row">
        <div class="col-6">
          <button class="btn btn-lg btn-danger w-100" (click)="onDelete()"
                  [attr.disabled]="attendance.id === '' || (currentMonth !== (year + '-' + month) && currentPreviousMonth !== (year + '-' + month))? '' : null"
                  [innerHTML]="btnDelText"></button>
        </div>
        <div class="col-6">
          <button class="btn btn-lg btn-primary w-100" (click)="onSubmit()"
                  [attr.disabled]="currentMonth !== (year + '-' + month) && currentPreviousMonth !== (year + '-' + month)? '':null"
                  [innerHTML]="btnText"></button>
        </div>
      </div>
    </div>
  </div>
  <span class="text-lightgray unselectable">.</span>


</ng-container>
<ng-container *ngIf="!employeeList[0]">
  <h3 class="text-center text-danger my-5">Nebyli nalezeni žádní zaměstnanci!</h3>
</ng-container>
<ng-template #previewModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Nastavit dovolenou</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p [innerHTML]="vacationModalCaption"></p>
    <form [formGroup]="vacationForm">
      <div class="form-floating form-group mb-3">
        <input
          formControlName="vacationHours"
          id="vacationHours"
          type="number"
          class="form-control"
          placeholder="nothing"
          required>
        <label for="vacationHours">Hodin dovolené:</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="modal.close('Save click')" [disabled]="!vacationForm.valid" type="button" class="btn btn-primary">
      Uložit
    </button>
  </div>
</ng-template>


<ng-template #tipworkHours>
  <div class="fs-6">
    Stavba: {{ attendance.currentSalary.construction }} Kč/h<br>
    Dílna: {{ attendance.currentSalary.workshop }} Kč/h<br>
    Jiné: {{ attendance.currentSalary.other }} Kč/h<br>
  </div>
</ng-template>
