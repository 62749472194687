<h2 class="card-title text-center">Místo</h2>
<hr class="d-block d-sm-none">
<div class="tableFixHead">
  <table class="table table-hover text-center text-sm-start">
    <thead>
    <tr>
      <th scope="col" class="d-none d-sm-table-cell">#</th>
      <th scope="col" class="d-none d-sm-table-cell sorter" (click)="sort('name')">Název<mat-icon>sort</mat-icon></th>
      <th scope="col" class="d-none d-lg-table-cell">Odkaz</th>
      <th scope="col" class="d-none d-sm-table-cell">Operace</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngIf="placeList[0]">
      <tr *ngFor="let place of placeList; index as i">
        <th scope="row" class="d-none d-sm-table-cell align-middle">{{ i + 1 }}</th>
        <td class="d-none d-sm-table-cell align-middle">
          <app-name-hover-id [object]="place" class="d-none d-lg-table-cell"></app-name-hover-id>
          <a class="text-primary d-table-cell d-lg-none" href="{{place.link}}" target="_blank">{{place.name}}</a>
        </td>
        <td class="placeLink d-none d-lg-table-cell align-middle"><a class="text-primary" href="{{place.link}}"
                                                        target="_blank">{{place.link}}</a></td>
        <td class="text-nowrap align-middle">
          <a href="{{place.link}}" target="_blank"
             class="d-flex d-sm-none justify-content-around mb-3">{{ place.name }}</a>
          <app-place-preview [place]="place" [ngbTooltip]="viewTip"></app-place-preview>
          <app-place-form [place]="place" [ngbTooltip]="editTip"></app-place-form>
          <app-place-delete [place]="place" [ngbTooltip]="deleteTip"></app-place-delete>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
<app-place-form class="d-grid"></app-place-form>
<ng-template #viewTip>
  <div class="fs-7">
    Zobrazit
  </div>
</ng-template>
<ng-template #deleteTip>
  <div class="fs-7">
    Odstranit
  </div>
</ng-template>

<ng-template #editTip>
  <div class="fs-7">
    Upravit
  </div>
</ng-template>
