<td
  class="
    {{day.name === 'SO' || day.name === 'NE'? 'text-muted':''}}
    {{place === 'Dovolená'? 'text-primary':''}}
    {{place === 'Nemoc'? 'text-danger':''}}
    {{place === 'Svátek'? 'text-warning':''}}
  "
><p class="mt-1 mb-0">{{day.date}}</p></td>
<td
  class="
    {{day.name === 'SO' || day.name === 'NE'? 'text-muted':''}}
    {{place === 'Dovolená'? 'text-primary':''}}
    {{place === 'Nemoc'? 'text-danger':''}}
    {{place === 'Svátek'? 'text-warning':''}}
  "
><p class="mt-1 mb-0">{{day.name}}</p></td>
<td
  class="
    {{day.name === 'SO' || day.name === 'NE'? 'text-muted':''}}
    {{place === 'Dovolená'? 'text-primary':''}}
    {{place === 'Nemoc'? 'text-danger':''}}
    {{place === 'Svátek'? 'text-warning':''}}
  "
>
  <input
    type="number"
    [(ngModel)]="hours"
    [disabled]="place === 'Svátek' || place === 'Víkend' || place === 'Dovolená' || place === 'Nemoc'"
    min="0" max="24"
    class="form-control form-control-sm">
</td>
<td
  class="
    {{day.name === 'SO' || day.name === 'NE'? 'text-muted':''}}
    {{place === 'Dovolená'? 'text-primary':''}}
    {{place === 'Nemoc'? 'text-danger':''}}
    {{place === 'Svátek'? 'text-warning':''}}
  "
>
  <div class="row">
    <div class="col">
      <select
        class="form-control form-control-sm"
        [(ngModel)]="place"
        (ngModelChange)="checkPlaceHours()"
      >
        <ng-container *ngFor="let place of attendancePlaces">
          <option
            *ngIf="
            (place !== 'Víkend' || day.name === 'SO' || day.name === 'NE') &&
            (place !== 'Svátek' || publicHoliday) &&
            (place !== 'Dovolená' || attendance.vacationHoursAtStart > 0) &&
            ((place !== 'Dovolená' && place !== 'Nemoc') || (day.name !== 'SO' && day.name !== 'NE'))"
            [value]="place">{{place}}</option>
        </ng-container>
      </select>
    </div>
    <div
      *ngIf="place === 'Nemoc'" class="col-5 multiplierInputCol">
      <select
        [(ngModel)]="multiplier"
        class="form-control form-control-sm">
        <option value="1">100%</option>
        <option value="0.6">60%</option>
        <option value="0">0%</option>
      </select>
    </div>
  </div>
</td>
<td
  class="
    {{day.name === 'SO' || day.name === 'NE'? 'text-muted':''}}
    {{place === 'Dovolená'? 'text-primary':''}}
    {{place === 'Nemoc'? 'text-danger':''}}
    {{place === 'Svátek'? 'text-warning':''}}
  "
>
  <input
    class="form-control form-control-sm"
    [(ngModel)]="activity"
    [disabled]="hours === 0 || place === 'Svátek'"
  >
</td>
<td
  class="
    text-end
    {{day.name === 'SO' || day.name === 'NE'? 'text-muted':''}}
    {{place === 'Dovolená'? 'text-primary':''}}
    {{place === 'Nemoc'? 'text-danger':''}}
    {{place === 'Svátek'? 'text-warning':''}}
  "
><p class="mt-1 mb-0">{{calculateSalary() | number}} Kč</p></td>
