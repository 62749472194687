import {Component, Input, OnInit} from '@angular/core';
import {ClothesModel} from "../../../../../../../../../../core/interfaces/clothes.model";
import {ClothingSizeEnum, ClothingSizeModel} from "../../../../../../../../../../core/interfaces/clothingSize.model";
import {ClothingSizeService} from "../../../../../../../../../../core/services/clothing-size.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-clothing-size-input',
  templateUrl: './clothing-sizes-input.component.html',
  styleUrls: ['./clothing-sizes-input.component.scss']
})
export class ClothingSizesInputComponent implements OnInit {
  @Input() clothing!: ClothesModel;
  employeeID = String(this.route.snapshot.paramMap.get('id'));
  selectedSize = new ClothingSizeModel();
  sizes = Object.values(ClothingSizeEnum);
  numberedSizes = this.generateBootSizes()

  constructor(
    private service: ClothingSizeService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    if (this.clothing.id) {
      this.service.getByEmployee(this.clothing.id, this.employeeID).subscribe(result => {
        if (result.docs.length !== 0) {
          this.selectedSize = result.docs[0].data();
        } else if(this.clothing.numberedSizes) {
          this.selectedSize.size = 45;
        }
      });
    }
  }

  onSubmit(): void {
    this.selectedSize.clothsID = this.clothing.id
    this.selectedSize.employeeID = this.employeeID
    this.service.upsert(this.selectedSize, this.clothing.id);
  }

  generateBootSizes(): number[] {
    return Array.from({length: 41}, (_, i) => i + 30);
  }
}
