<h2 class="card-title text-center">Certifikát</h2>
<hr class="d-block d-sm-none">
<div class="tableFixHead">
<table class="table table-hover text-center text-sm-start">
  <thead>
    <tr>
      <th scope="col" class="d-none d-lg-table-cell">#</th>
      <th scope="col" class="d-none d-sm-table-cell d-lg-none">Certifikát</th>
      <th scope="col" class="d-none d-lg-table-cell sorter" (click)="sort('name')">Název<mat-icon>sort</mat-icon></th>
      <th scope="col" class="d-none d-sm-table-cell sorter" (click)="sort('uploadTime')">Datum nahrání<mat-icon>sort</mat-icon></th>
      <th scope="col" class="d-none d-sm-table-cell">Operace</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="certificateList[0]">
      <tr *ngFor="let certificate of certificateList">
        <th scope="row" class="d-none d-lg-table-cell align-middle">{{ certificate.priority }}</th>
        <td class="d-none d-sm-table-cell align-middle">
          <app-name-hover-id [object]="certificate"></app-name-hover-id><br class="d-table-cell d-lg-none">
        </td>
        <td class="d-none d-sm-table-cell align-middle">
          {{util.timestampToString(certificate.low.uploadTime)}}
        </td>
        <td class="text-nowrap align-middle">
          <p class="d-sm-none justify-content-around">
            <app-name-hover-id [object]="certificate"></app-name-hover-id>
          </p>
          <div class="mt-2 mt-lg-auto">
            <a href="{{certificate.high.src}}" data-fancybox="certificates" class="btn btn-sm btn-primary mx-1" [ngbTooltip]="viewTip"><fa-icon [icon]="previewIcon"></fa-icon></a>
            <app-certificate-form [certificate]="certificate" [ngbTooltip]="editTip"></app-certificate-form>
            <app-certificate-delete [certificate]="certificate" [ngbTooltip]="deleteTip"></app-certificate-delete>
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
</div>
<app-certificate-form class="d-grid"></app-certificate-form>
<ng-template #viewTip>
  <div class="fs-7">
    Zobrazit
  </div>
</ng-template>
<ng-template #deleteTip>
  <div class="fs-7">
    Odstranit
  </div>
</ng-template>

<ng-template #editTip>
  <div class="fs-7">
    Upravit
  </div>
</ng-template>
