import { Component } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
})
export class PageNotFoundComponent {
  headerL1:string = 'ERROR 404';
  headerL2:string = 'Tudy cesta nevede...';
  btnType:string = 'back';

  constructor() { }
}
