import { Component, OnInit } from '@angular/core';
import {CompanyInfoModel} from 'src/app/core/interfaces/companyInfo.model';
import {DataService} from 'src/app/core/services/data.service';
import {ContactPersonModel} from 'src/app/core/interfaces/contactPerson.model';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  contactData = new CompanyInfoModel();
  people = [new ContactPersonModel()];
  // Headre inputs
  headerL1 = 'Kontakty';
  headerL2 = 'Máte dotaz? Kontaktujte nás!';
  btnType = 'back';

  constructor(
    private dataService: DataService,
  ) { }

  ngOnInit(): void {
    this.dataService.getCompanyInfo().subscribe(result => {
      this.contactData = result.data();
    });
    this.dataService.getAllContactPeople().subscribe(result => {
      this.people = result;
    });
  }

}
