import {Component, OnInit} from '@angular/core';
import {DataService} from "../../../../../core/services/data.service";
import {ReferenceService} from "../../../../../core/services/reference.service";
import {EmployeeService} from "../../../../../core/services/employee.service";
import {CompanyInfoModel} from "../../../../../core/interfaces/companyInfo.model";
import {FileUploadModel} from "../../../../../core/interfaces/fileUpload.model";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {HttpClient} from "@angular/common/http";

import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  numberOfEmployees = 'N/A';
  numberOfReferences = 'N/A';
  numberOfContacts = 'N/A';
  numberOfViews = 'N/A';
  data = new CompanyInfoModel()
  aresIn = '';
  images: FileUploadModel[] = []

  constructor(
    private dataService: DataService,
    private referenceService: ReferenceService,
    private employeeService: EmployeeService,
    private auth: AngularFireAuth,
    private http: HttpClient,
  ) {
  }

  ngOnInit(): void {
    this.employeeService.getAllActive().subscribe(result => {
      this.numberOfEmployees = result.length.toString();
    });
    this.dataService.getAllContactPeople().subscribe(result => {
      this.numberOfContacts = result.length.toString();
    });
    this.referenceService.getAll().subscribe(result => {
      this.numberOfReferences = result.length.toString();
      this.images = [];
      result.forEach(ref => {
        ref.mainImage.forEach(image => {
          this.images.push(image);
        });
      });
      if (this.images.length == 0) {
        this.images = [{name: 'No images found', size: 0, src: 'assets/images/blueprint.webp', uploadTime: Timestamp.now()}];
      }
    });
    this.dataService.getCompanyInfo().subscribe(result => {
      this.data = result.data();
      this.aresIn = this.data.in.replace(/\s/g, '');
    });
    this.auth.idToken.subscribe(token => {
      this.http.get('/fns/ga/views', {headers: {Authorization: `Bearer ${token}`},}).subscribe((result: any) => {
        if (result.rows.length > 0) {
          this.numberOfViews = result.rows[0].metricValues[0].value;
        } else {
          this.numberOfViews = '0';
        }
      });
    });
  }
}
