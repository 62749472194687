export enum ClothingSizeEnum {
  XS = 'XS',
  S = 'X',
  M = 'M',
  L = 'L',
  XL = 'XL',
  XXL = 'XXL',
}
export class ClothingSizeModel {
  constructor () {
    this.size = ClothingSizeEnum.L;
  }

  id = '';
  clothsID = '';
  employeeID = '';
  size: ClothingSizeEnum | number | string;
  link = '';
}
