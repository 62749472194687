import {Component, OnInit, ViewChild} from '@angular/core';

import {EmployeeModel} from "../../../../../../../core/interfaces/employee.model";
import {EmployeeService} from "../../../../../../../core/services/employee.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbNav, NgbNavChangeEvent} from "@ng-bootstrap/ng-bootstrap";

import {faCircleInfo, faClipboardUser, faShirt, faFolderOpen} from "@fortawesome/free-solid-svg-icons";
import { Timestamp } from 'firebase/firestore';
import {UtilityService} from "../../../../../../../core/services/utility.service";
import {SetupService} from "../../../../../../../core/services/setup.service";


@Component({
  selector: 'app-employee-profile',
  templateUrl: './employee-profile.component.html',
  styleUrls: ['./employee-profile.component.scss']
})
export class EmployeeProfileComponent implements OnInit {
  info = faCircleInfo;
  attendance = faClipboardUser;
  clothing = faShirt;
  files = faFolderOpen;
  employee = new EmployeeModel();
  @ViewChild("nav") nav!: NgbNav;
  activeTab = 1;

  constructor(
    private employeeService: EmployeeService,
    private utilityService: UtilityService,
    private route: ActivatedRoute,
    private router: Router,
    private setup: SetupService,
  ) {}

  ngOnInit(): void {
    this.employeeService.getById(String(this.route.snapshot.paramMap.get('id'))).subscribe(result => {
      this.employee = result.data();
      this.setup.setTitle(this.employee.lastName + ' ' + this.employee.firstName, 'adminPanel');
    });
    this.route.queryParamMap.subscribe(queryParamMap => {
      if (queryParamMap.get('tab')) {
        this.activeTab = +queryParamMap.get('tab')!;
      }
    });
  }

  convertTimestemp(date: Timestamp): string {
    return this.utilityService.timestampToString(date);
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.router.navigate([], {
      relativeTo: this.route, queryParams: {
        tab: changeEvent.nextId
      }
    }).then();
  }
}
