<app-site-header
  [headerLine1]="headerL1"
  [headerLine2]="headerL2"
  [btnType]="btnType"
></app-site-header>
<main>
  <div class="row">
    <div class="col-12 text-center bg-white shadow-lg">
      <ng-container *ngIf="!this.category.galleryMode; else galleryMode">
        <div class="row mt-5">
          <h1>Naše reference</h1>
        </div>
        <div class="row mb-2 justify-content-around">
          <div *ngFor="let reference of referenceList" class="col-12 col-md-6 col-lg-4">
            <app-reference-item [reference]="reference"></app-reference-item>
          </div>
        </div>
      </ng-container>
      <ng-template #galleryMode>
        <ng-container *ngIf="this.referenceList.length !== 0; else errorMessage">
          <div class="row mt-5 mb-2">
            <h1>Naše galerie</h1>
          </div>
          <div class="d-flex justify-content-center" *ngIf="category.link==='reklama'">
            <button class="mx-2 btn {{filter === 'all'?'btn-primary':'btn-outline-primary'}}" (click)="selectFilter({id:'all',name:'all'})">Vše</button>
            <button *ngFor="let category of category.subCategories" class="mx-2 btn {{filter === category.id?'btn-primary':'btn-outline-primary'}}" (click)="selectFilter(category)">{{category.name}}</button>
          </div>
          <div class="row mb-2 mt-5 mx-xl-5 justify-content-center">
            <ng-container *ngFor="let reference of referenceList">
              <div *ngIf="filter === reference.subCategory || filter === 'all'" class="col-12 col-sm-6 col col-lg-4 col-xl-3">
                <div class="gallery-image-box">
                  <a href="{{reference.previewImage.src}}" [attr.data-fancybox]="reference.id" [attr.data-caption]="reference.basicInfo">
                    <img src="{{reference.previewImage.src}}" class="gallery-image shadow rounded" alt="{{reference.previewImage.name}}"/>
                  </a>
                  <button class="btn btn-sm btn-primary gallery-image-swatch shadow">
                    {{sumName(reference.subCategory)}}
                  </button>
                  </div>
                  <p>{{reference.name}}</p>
                  <a *ngFor="let sub of reference.mainImage" href="{{sub.src}}" [attr.data-fancybox]="reference.id" [attr.data-caption]="reference.basicInfo" class="d-none">
                    <img src="{{sub.src}}" alt="{{sub.name}}"/>
                  </a>
                </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-template>
      <ng-template #errorMessage>
        <div class="row mt-5">
          <h1>{{errorText}}</h1>
        </div>
      </ng-template>
      <div class="row">
        <div class="col-12 bg-white content-box">
          <br/>
          <br/>
        </div>
      </div>
    </div>
  </div>
</main>
