import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {PlaceService} from 'src/app/core/services/place.service';
import {ErrorResolutionService} from 'src/app/core/services/errorResolution.service';
import {AppUser, AppUserPermissions} from "../../../../../../core/interfaces/user.interface";
import {AuthService} from "../../../../../../core/services/auth.service";
import {UserService} from "../../../../../../core/services/user.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  @Input() user = new AppUser();
  userBackUp = new AppUser();
  signUpForm!: FormGroup;
  icon = faPenToSquare;
  isNew = true;

  constructor(
    public authService: AuthService,
    private userService: UserService,
    private placeService: PlaceService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private errorRes: ErrorResolutionService,
  ) { }

  ngOnInit(): void {
    if (this.user.id != '') {
      this.isNew = false;
    }
    this.userBackUp = Object.assign({}, this.user);
    this.buildForm();
  }

  buildForm(): void {
    this.signUpForm = this.formBuilder.group({
      name: new FormControl(this.user.displayName || '', [Validators.required]),
      email: new FormControl(this.user.email || '', [Validators.required, Validators.email]),
      siteAdmin: new FormControl(this.user.permissions.siteAdmin || false, [Validators.required]),
      attendanceAdmin: new FormControl(this.user.permissions.attendanceAdmin || false, [Validators.required]),
      employeeAdmin: new FormControl(this.user.permissions.employeeAdmin || false, [Validators.required]),
      machinesAdmin: new FormControl(this.user.permissions.machinesAdmin || false, [Validators.required]),
      userAdmin: new FormControl(this.user.permissions.userAdmin || false, [Validators.required]),
      superAdmin: new FormControl(this.user.permissions.superAdmin || false, [Validators.required]),
    });
  }

  signUp(): void {
    const permissions: AppUserPermissions = {
      siteAdmin: this.signUpForm.get('siteAdmin')?.value,
      attendanceAdmin: this.signUpForm.get('attendanceAdmin')?.value,
      employeeAdmin: this.signUpForm.get('employeeAdmin')?.value,
      machinesAdmin: this.signUpForm.get('machinesAdmin')?.value,
      userAdmin: this.signUpForm.get('userAdmin')?.value,
      superAdmin: this.signUpForm.get('superAdmin')?.value
    }
    const userData: AppUser = {
      id:"",
      email: this.signUpForm.get('email')?.value,
      displayName: this.signUpForm.get('name')?.value,
      permissions: permissions,
    }
    if (this.isNew) {
      this.authService.create(userData.displayName, userData.email, Math.random().toString(36).slice(-8)).subscribe(result => {
        userData.id = result.uid;
        this.userService.upsert(userData);
        this.authService.ForgotPassword(userData.email).then();
      });
      // this.authService.SignUp(this.signUpForm.get('name')?.value, this.signUpForm.get('email')?.value, this.signUpForm.get('password')?.value, permissions).then();
    } else {
      userData.id = this.user.id;
      this.userService.update(userData);
    }
  }

  resetForm(sent:boolean): void {
    if (!sent) {
      this.user = Object.assign({}, this.userBackUp);
    }
    this.buildForm();
  }

  openForm(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', scrollable: true, centered: true}).result.then(result => {
      this.signUp();
      this.resetForm(!this.isNew);
      console.log('Formulář uzavřen pomocí:' + result);
    }, (reason) => {
      this.resetForm(false);
      console.log(this.errorRes.getModalDismissReason(reason));
    });
  }
}
