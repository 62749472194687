import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {ReferenceModel} from 'src/app/core/interfaces/reference.model';
import {ReferenceCategoryModel, ReferenceSubCategoryModel} from 'src/app/core/interfaces/referenceCategory.model';
import {CategoryService} from 'src/app/core/services/category.service';
import {ReferenceService} from 'src/app/core/services/reference.service';
import {SetupService} from "../../../../../../core/services/setup.service";

@Component({
  selector: 'app-references-category',
  templateUrl: './references-category.component.html',
  styleUrls: ['../references.component.scss']
})
export class ReferencesCategoryComponent implements OnInit {
  referenceList: ReferenceModel[] = [];
  category = new ReferenceCategoryModel();
  headerL1 = '';
  headerL2 = '';
  btnType = '';
  errorText = 'Nebyly nalezeny žádné reference.';

  filter = 'all';

  constructor(
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private referenceService: ReferenceService,
    private setup: SetupService
  ) { }

  ngOnInit(): void {
    this.categorySetup();
  }

  categorySetup(): void {
    // Vytáhne požadovanou kategorii z URL
    const link = String(this.route.snapshot.paramMap.get('category'));
    // Vyhledá v databázi data pro požadovanou kategorii
    this.categoryService.getByLink(link).subscribe(categoryData => {
      console.log(categoryData);
      // Data kategorie nalezena
      if (categoryData[0]) {
        // Vysypaní získaných dat do proměnné category
        this.category = categoryData[0];
        // Nastaví proměnné headru na požadovanou hodnotu
        this.headerL1 = this.category.name;
        this.headerL2 = '';
        this.btnType = 'contects';
        // Nastaví title
        this.setup.setTitle(this.category.name);
        // Vyhledá v databázi všechny reference pro danou kategorii
          this.referenceService.getAllByCategory(this.category.id).subscribe(result => {
            this.referenceList = result;
            if (!this.category.galleryMode) {
              this.referenceList.sort((a,b) => b.dateTo.seconds-a.dateTo.seconds);
            }
          });
      // Kategorie nenalezena
      } else {
        // Nastaví proměnné headru na požadovanou hodnotu
        this.headerL1 = 'Jeejda!';
        this.headerL2 = 'Tudy cesta nevede...';
        this.btnType = 'back';
        this.errorText = 'Kategorie nebyla nalezena.';
      }
    });
  }

  selectFilter(category: ReferenceSubCategoryModel) {
    this.filter = category.id;
  }

  sumName(subCategory: string) {
    return this.category.subCategories.find(sub => sub.id === subCategory)?.name || subCategory;
  }
}
