import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FileService} from 'src/app/core/services/file.service';
import {ErrorResolutionService} from 'src/app/core/services/errorResolution.service';

import { Timestamp } from 'firebase/firestore';
import {FileUploadModel} from "../../../../../../core/interfaces/fileUpload.model";
import {
  MachineModel,
  MachineTypeEnum,
  MachineTypeEnum2LabelMapping
} from "../../../../../../core/interfaces/machine.model";
import {MachineService} from "../../../../../../core/services/machine.service";
import {EmployeeService} from "../../../../../../core/services/employee.service";
import {EmployeeModel} from "../../../../../../core/interfaces/employee.model";
import {UtilityService} from "../../../../../../core/services/utility.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-machine-form',
  templateUrl: './machine-form.component.html',
  styleUrls: ['./machine-form.component.scss']
})
export class MachineFormComponent implements OnInit {
  @Input() machine = new MachineModel();
  machineBackUp = new MachineModel();
  employeeList: EmployeeModel[] = [];
  machineForm!: FormGroup;
  icon = faPenToSquare;
  MachineTypeEnumMapping = MachineTypeEnum2LabelMapping;
  types = Object.values(MachineTypeEnum);

  smallImageDisableSending = false;
  smallImageUploadProgress = 0;
  largeImageDisableSending = false;
  largeImageUploadProgress = 0;

  isNew = true;

  constructor(
    private machineService: MachineService,
    private employeeService: EmployeeService,
    private utilityService: UtilityService,
    private fileService: FileService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private errorRes: ErrorResolutionService,
  ) { }

  ngOnInit(): void {
    this.getAllEmployees();
    if (this.machine.id !== '') {
      this.isNew = false;
    } else {
      this.machine.id = this.machineService.createId();
    }
    this.machineBackUp = Object.assign({}, this.machine);
    this.buildForm();
  }

  getAllEmployees(): void {
    this.employeeService.getAllActive().subscribe(result => {
      if (result.length > 0) {
        this.employeeList = result;
        if (this.employeeList.length <= 0) {
          this.employeeList = [];
          console.log('Žádný záznam aktivních zaměstnanců neexistuje!!!');
        }
      } else {
        this.employeeList = [];
        console.log('Žádný záznam zaměstnanců neexistuje!!!');
      }
    });
  }
  buildForm(): void {
    this.machineForm = this.formBuilder.group({
      manufacturer: new FormControl(this.machine.manufacturer || null, [Validators.required]),
      model: new FormControl(this.machine.model || null, [Validators.required]),
      type: new FormControl(this.machine.type || '', [Validators.required]),
      operator: new FormControl(this.machine.operator || null),
      purchaseDate: new FormControl(this.utilityService.timestampToString(this.machine.purchaseDate,'YYYY-MM-DD') || '', [Validators.required]),
      previewImage: new FormControl(''),
      gallery: new FormControl(''),
      gpsId: new FormControl(this.machine.gpsId || ''),
      vin: new FormControl(this.machine.vin || ''),
      spz: new FormControl(this.machine.spz || '')
    });
  }

  resetForm(sent:boolean): void {
    if(!sent) {
      this.machine = Object.assign({}, this.machineBackUp);
    }
    if (this.isNew) {
      this.machine.id = this.machineService.createId();
    }
    this.smallImageDisableSending = false;
    this.smallImageUploadProgress = 0;
    this.largeImageDisableSending = false;
    this.largeImageUploadProgress = 0;
    this.buildForm();
  }

  chooseSmallImage(event: any): void {
    this.fileService.delete(this.machine.previewImage.src).then(r => {
      if (event.target.files.length > 0) {
        this.smallImageUploadProgress = 0;
        this.smallImageDisableSending = true;
        const smallImage = event.target.files[0];
        this.fileService.upload(smallImage, '/machines/' + this.machine.id + '/preview', false).subscribe(result => {
          if (typeof result === 'number') {
            this.smallImageUploadProgress = Math.round(result);
          } else {
            this.machine.previewImage = result;
            this.smallImageDisableSending = false;
          }
        });
      }
    });
  }

  chooseLargeImage(event: any): void {
    this.largeImageUploadProgress = 0;
    this.largeImageDisableSending = true;
    const largeImage = event.target.files[0];
    this.fileService.upload(largeImage, '/machines/' + this.machine.id + '/gallery', false).subscribe(result => {
      if (typeof result === 'number') {
        this.largeImageUploadProgress = Math.round(result);
      } else {
        if (this.machine.gallery[0].src == ''){
          this.machine.gallery = [result];
        } else {
          this.machine.gallery.push(result);
        }
        this.largeImageDisableSending = false;
      }
    });
  }

  sendData(): void {
    this.machine.manufacturer = this.machineForm.get('manufacturer')?.value;
    this.machine.gpsId = this.machineForm.get('gpsId')?.value;
    this.machine.type = this.machineForm.get('type')?.value;
    if (this.machineForm.get('operator')?.value !== "null") {
      this.machine.operator = this.machineForm.get('operator')?.value;
    } else {
      this.machine.operator = null;
    }
    this.machine.purchaseDate = Timestamp.fromDate(new Date(this.machineForm.get('purchaseDate')?.value))
    this.machine.model = this.machineForm.get('model')?.value;
    this.machine.vin = this.machineForm.get('vin')?.value;
    this.machine.spz = this.machineForm.get('spz')?.value;
    this.machineService.upsert(this.machine);
  }

  openForm(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', scrollable: true, centered: true}).result.then(result => {
      this.sendData();
      this.resetForm(!this.isNew);
      console.log('Formulář uzavřen pomocí:' + result);
    }, (reason) => {
      this.resetForm(false);
      console.log(this.errorRes.getModalDismissReason(reason));
      if(this.isNew) {
        this.fileService.deleteFolder('/private/machines/' + this.machine.id + '/preview');
        this.fileService.deleteFolder('/private/machines/' + this.machine.id + '/images');
      }
    });
  }

  removeImg(image: FileUploadModel): void {
    this.fileService.delete(image.src).then(r => {
      const index = this.machine.gallery.indexOf(image);
      if (this.machine.gallery.length > 1) {
        this.machine.gallery.splice(index, 1);
      } else {
        this.machine.gallery = [new FileUploadModel()];
      }
    });
  }
}
