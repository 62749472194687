import { Component, OnInit } from '@angular/core';
import {ContactPersonModel} from '../../../../../../core/interfaces/contactPerson.model';
import {DataService} from '../../../../../../core/services/data.service';
import {UtilityService} from "../../../../../../core/services/utility.service";

@Component({
  selector: 'app-contact-people',
  templateUrl: './contact-people.component.html',
  styleUrls: ['./contact-people.component.scss']
})
export class ContactPeopleComponent implements OnInit {
  people: ContactPersonModel[] = [];
  asc = false;

  constructor(
    private dataService: DataService,
    private util: UtilityService
  ) { }

  ngOnInit(): void {
    this.dataService.getAllContactPeople().subscribe(result => {
      if (result.length > 0) {
        this.people = result;
      } else {
        this.people = [];
        console.log('Žádný záznam kontaktní osoby neexistuje!!!')
      }
    });
  }
  sort(key: string) {
    const sorter = this.util.sortByKey(this.people, key, this.asc);
    this.people = sorter.object;
    this.asc = sorter.asc;
  }
}
