<div class="card p-4 shadow mb-4">
  <h2 class="card-title text-center">URS Vyhledávač</h2>
  <hr>
  <p class="text-center"><span class="text-danger">Upozornění:</span> Ujistěte se, že jste přihlášení v <a href="https://cs-urs-online.urs.cz/index" class="text-decoration-underline">Cenová soustava URS</a></p>
  <p class="text-center"><span class="text-success">Postup:</span> Nahrajte soubor <b>.xlsx</b> s kódy cenové soustavy URS <sup class="text-danger"><i>(POZOR soubor musí obsahovat pouze kódy)</i></sup>  a my vám stránky se všemi kódy automaticky najdeme a otevřeme</p>
  <hr>
</div>
<div class="card p-5 shadow">
<div>
  <div class="col form-group">
    <input
      (change)="chooseFile($event)"
      required="true"
      [attr.disabled]="disableSending ? '' : null"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      id="categoryImage"
      type="file"
      class="form-control">
  </div>
</div>
<div class="tableFixHead mt-4 " *ngIf="items[0]">
  <table class="table table-hover">
    <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Kód</th>
      <th scope="col" class="text-end">Odkaz</th>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of items; index as i">
        <th scope="row" class="align-middle">{{i + 1}}</th>
        <td class="align-middle">{{item}}</td>
        <td class="align-middle text-end"><a class="btn btn-sm btn-primary" href="https://cs-urs-online.urs.cz/vyhledavani/constructions/{{item}}" target="_blank">Zobrazit</a></td>
      </tr>
    </tbody>
  </table>
</div>
</div>
