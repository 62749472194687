<h2 class="card-title text-center">Zaměstnanci</h2>
<hr class="d-block d-sm-none">
<div class="tableFixHead">
<table class="table table-hover text-center text-sm-start">
  <thead>
    <tr>
      <th scope="col" class="d-none d-sm-table-cell">#</th>
      <th scope="col" class="d-none d-sm-table-cell sorter" (click)="sort('lastName')">Příjmení a Jméno<mat-icon>sort</mat-icon></th>
      <th scope="col" class="d-none d-md-table-cell">Telefon</th>
      <th scope="col" class="d-none d-xl-table-cell">Email</th>
      <th scope="col" class="d-none d-lg-table-cell sorter" (click)="sort('active')">Aktivní<mat-icon>sort</mat-icon></th>
      <th scope="col" class="d-none d-sm-table-cell">Operace</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="employeeList[0]">
      <tr *ngFor="let employee of employeeList; index as i">
        <th scope="row" class="d-none d-sm-table-cell align-middle"><div class="mt-2 mt-md-0">{{ i + 1 }}</div></th>
        <td class="d-none d-sm-table-cell align-middle">
          <a routerLink="/admin/employees/list/profile/{{employee.id}}"><app-name-hover-id [object]="employee" class="d-none d-lg-table-cell text-nowrap"></app-name-hover-id></a>
          <a routerLink="/admin/employees/list/profile/{{employee.id}}"><app-name-hover-id *ngIf="employee.active" [object]="employee" class="d-table-cell d-lg-none text-success text-nowrap"></app-name-hover-id></a>
          <a routerLink="/admin/employees/list/profile/{{employee.id}}"><app-name-hover-id *ngIf="!employee.active" [object]="employee" class="d-table-cell d-lg-none text-danger text-nowrap"></app-name-hover-id></a>
          <a href="tel:{{employee.tel}}" class="d-block d-md-none mb-0">{{employee.tel}}</a>
        </td>
        <td class="d-none d-md-table-cell text-nowrap align-middle"><a href="tel:{{employee.tel}}">{{employee.tel}}</a></td>
        <td class="d-none d-xl-table-cell align-middle"><a href="mailto:{{employee.email}}">{{employee.email}}</a></td>
        <td class="d-none d-lg-table-cell fw-bold align-middle {{employee.active ? 'text-success':'text-danger'}}">{{employee.active ? 'Aktivní':'Neaktivní'}}</td>
        <td class="text-nowrap align-middle">
          <p class="d-sm-none justify-content-around">
            <b class="{{employee.active? 'text-success':'text-danger'}}">{{employee.lastName}} {{ employee.firstName }}</b><br>
            <a href="tel:{{employee.tel}}">{{employee.tel}}</a>
          </p>
          <div class="mt-2 mt-md-auto">
            <button routerLink="/admin/employees/list/profile/{{employee.id}}" class="btn btn-sm btn-primary mx-1" [ngbTooltip]="viewTip"><fa-icon [icon]="prewievIcon"></fa-icon></button>
            <app-employee-form [employee]="employee" [ngbTooltip]="editTip"></app-employee-form>
            <app-employee-deactivate [employee]="employee" [ngbTooltip]="fireTip"></app-employee-deactivate>
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
</div>
<app-employee-form class="d-grid"></app-employee-form>
<ng-template #viewTip>
  <div class="fs-7">
    Zobrazit
  </div>
</ng-template>
<ng-template #fireTip>
  <div class="fs-7">
    Propustit
  </div>
</ng-template>
<ng-template #editTip>
  <div class="fs-7">
    Upravit
  </div>
</ng-template>
