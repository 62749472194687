<h2 class="card-title text-center">Kontaktní osoby</h2>
<hr class="d-block d-sm-none">

<div class="tableFixHead">
  <table class="table table-hover text-center text-sm-start">
    <thead>
    <tr>
      <th scope="col" class="d-none d-lg-table-cell">#</th>
      <th scope="col" class="d-none d-sm-table-cell sorter" (click)="sort('function')">Funkce<mat-icon>sort</mat-icon></th>
      <th scope="col" class="d-none d-sm-table-cell sorter" (click)="sort('name')">Jméno<mat-icon>sort</mat-icon></th>
      <th scope="col" class="d-none d-sm-table-cell">Operace</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngIf="people[0]">
      <tr *ngFor="let person of people">
        <th scope="row" class="d-none d-lg-table-cell align-middle"> {{person.priority}}</th>
        <td class="d-none d-sm-table-cell text-nowrap align-middle">{{person.function}}</td>
        <td class="d-none d-sm-table-cell text-nowrap align-middle">{{person.name}}</td>
        <td class="text-nowrap align-middle">
          <p class="d-sm-none justify-content-around">
            {{person.name}}<br>
            {{person.function}}
          </p>
          <app-contact-person-preview [person]="person" [ngbTooltip]="viewTip"></app-contact-person-preview>
          <app-contact-person-form [person]="person" [ngbTooltip]="editTip"></app-contact-person-form>
          <app-contact-person-delete [person]="person" [ngbTooltip]="deleteTip"></app-contact-person-delete>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
<app-contact-person-form class="d-grid"></app-contact-person-form>
<ng-template #viewTip>
  <div class="fs-7">
    Zobrazit
  </div>
</ng-template>
<ng-template #deleteTip>
  <div class="fs-7">
    Odstranit
  </div>
</ng-template>
<ng-template #editTip>
  <div class="fs-7">
    Upravit
  </div>
</ng-template>
