<h2 class="text-center mb-3">Přehled</h2>
<div class="row">
  <div class="col-sm-6 col-xl-3 mb-4">
    <div class="card h-100 stat-card shadow-sm" routerLink="/admin/employees/list">
      <div class="card-body">
        <div class="row">
          <div class="col-4 position-relative">
            <div class="position-absolute top-50 start-50 translate-middle">
              <mat-icon class="card-icon">engineering</mat-icon>
            </div>
          </div>
          <div class="col-8">
            <p class="card-subtitle mb-2 text-muted text-nowrap">Zaměstnanci</p>
            <h1 class="card-title">{{numberOfEmployees}}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6 col-xl-3 mb-4">
    <div class="card h-100 stat-card shadow-sm" routerLink="/admin/content/contactPeople">
      <div class="card-body">
        <div class="row">
          <div class="col-4 position-relative">
            <div class="position-absolute top-50 start-50 translate-middle">
              <mat-icon class="card-icon">connect_without_contact</mat-icon>
            </div>
          </div>
          <div class="col-8">
            <p class="card-subtitle mb-2 text-muted text-nowrap">Kontaktní osoby</p>
            <h1 class="card-title">{{numberOfContacts}}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6 col-xl-3 mb-4">
    <div class="card h-100 stat-card shadow-sm" routerLink="/admin/references/reference">
      <div class="card-body">
        <div class="row">
          <div class="col-4 position-relative">
            <div class="position-absolute top-50 start-50 translate-middle">
              <mat-icon class="card-icon">newspaper</mat-icon>
            </div>
          </div>
          <div class="col-8">
            <p class="card-subtitle mb-2 text-muted text-nowrap">Referencí</p>
            <h1 class="card-title">{{numberOfReferences}}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6 col-xl-3 mb-4">
    <div class="card h-100 stat-card shadow-sm" routerLink="/home">
      <div class="card-body">
        <div class="row">
          <div class="col-4 position-relative">
            <div class="position-absolute top-50 start-50 translate-middle">
              <mat-icon class="card-icon">preview</mat-icon>
            </div>
          </div>
          <div class="col-8">
            <p class="card-subtitle mb-2 text-muted text-nowrap">Návštěv za 30 dní</p>
            <h1 class="card-title">{{numberOfViews}}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-6 mb-4">
    <div class="card info-card shadow-sm">
      <div class="card-body">
        <h3 class="card-title">{{data.name}}</h3>
        <span class="card-subtitle">IČ: {{data.in}} / DIČ: {{data.taxNumber}}</span>
      </div>
      <ul class="list-group list-group-flush ms-4">
        <li class="list-group-item">
          <b>Adresa:</b><br>
          <a href="https://www.google.cz/maps/place/{{data.address1}}" target="_blank">
            {{data.address1}},<br>
            {{data.address2}}<br><br>
          </a>
          <b>E-mail: </b><a href="mailto:{{data.email}}">{{data.email}}</a><br>
          <b>Tel: </b><a href="tel:+420{{data.phoneNumber}}">{{data.phoneNumber}}</a><br>
          <b>Datová schránka: </b><a href=" https://www.mojedatovaschranka.cz/">{{data.dataBox}}</a>
        </li>
      </ul>
      <div class="card-body">
        <div class="btn-group ms-2">
          <a
            href="https://or.justice.cz/ias/ui/rejstrik-$firma?ico={{aresIn}}"
            target="_blank"
            class="btn btn-primary"
          >
            Rejstřík
          </a>
          <a
            href="https://wwwinfo.mfcr.cz/cgi-bin/ares/darv_std.cgi?ico={{aresIn}}&xml=1"
            target="_blank"
            class="btn btn-outline-primary"
          >
            ARES
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="carousel-image my-auto shadow-sm">
      <ngb-carousel [interval]="2500" [pauseOnHover]="true" [showNavigationArrows]="images.length > 1" [showNavigationIndicators]="images.length > 1">
        <ng-template ngbSlide *ngFor="let image of images; index as i">
          <div class="picsum-img-wrapper">
            <img class="carousel-image rounded" [src]="image.src" alt="{{image.name}}">
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
  </div>
</div>
