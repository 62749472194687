import { Component, OnInit } from '@angular/core';

import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import {CompanyInfoModel} from 'src/app/core/interfaces/companyInfo.model';
import {DataService} from 'src/app/core/services/data.service';

@Component({
  selector: 'app-web-footer-links',
  templateUrl: './web-footer-links.component.html',
  styleUrls: ['./web-footer-links.component.scss']
})
export class WebFooterLinksComponent implements OnInit {
  contactData = new CompanyInfoModel();
  //fontAwsome icons
  Facebook = faFacebookF;
  Instagram = faInstagram;
  Mail = faEnvelope;
  Phone = faPhone;
  Location = faLocationDot;
  //Copyright year
  year: number = new Date().getFullYear();
  constructor(
    private dataService: DataService,
  ) { }

  ngOnInit(): void {
    this.dataService.getCompanyInfo().subscribe(result => {
      this.contactData = result.data();
    });
  }

}
