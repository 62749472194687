import {Component, OnInit} from '@angular/core';
import {ClothesModel} from "../../../../../../../core/interfaces/clothes.model";
import {ClothesService} from "../../../../../../../core/services/clothes.service";

@Component({
  selector: 'app-employee-clothing-list',
  templateUrl: './employee-clothing-list.component.html',
  styleUrls: ['./employee-clothing-list.component.scss']
})
export class EmployeeClothingListComponent implements OnInit {
  clothesList: ClothesModel[] = [];

  constructor(
    private clothesService: ClothesService
  ) {}

  ngOnInit(): void {
    this.getAllClothes();
  }
  getAllClothes(): void {
    this.clothesService.getAll().subscribe(result => {
      if (result.length > 0) {
        this.clothesList = result;
      } else {
        this.clothesList = [];
        console.log('Žádný záznam oblečení neexistuje!!!')
      }
    });
  }

}
