import { Component } from '@angular/core';

@Component({
  selector: 'app-web-footer',
  templateUrl: './web-footer.component.html',
})
export class WebFooterComponent {

  constructor() { }
}
