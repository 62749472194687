import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ClothesModel} from "../../../../../../../../../core/interfaces/clothes.model";
import {ClothesService} from "../../../../../../../../../core/services/clothes.service";
import {ClothingSizesInputComponent} from "./clothing-size-input/clothing-sizes-input.component";

@Component({
  selector: 'app-clothing-sizes',
  templateUrl: './clothing-sizes.component.html',
  styleUrls: ['./clothing-sizes.component.scss']
})
export class ClothingSizesComponent implements OnInit {
  clothesList: ClothesModel[] = [];
  @ViewChildren(ClothingSizesInputComponent) inputComponents!: QueryList<ClothingSizesInputComponent>;
  btnText = 'Uložit velikosti';

  constructor(
    private clothesService: ClothesService
  ) {}

  ngOnInit(): void {
    this.getAllPlaces();
  }

  getAllPlaces(): void {
    this.clothesService.getAll().subscribe(result => {
      if (result.length > 0) {
        this.clothesList = result;
      } else {
        this.clothesList = [];
        console.log('Žádný záznam oblečení neexistuje!!!')
      }
    });
  }

  onSubmit():void {
    let inputs: ClothingSizesInputComponent[] = this.inputComponents.toArray();
    inputs.forEach(input => {
      input.onSubmit();
    });
    this.btnText = 'Hotovo'
    setTimeout((): void => {this.btnText = 'Uložit velikosti'},500);
  }
}
