import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ErrorResolutionService} from 'src/app/core/services/errorResolution.service';
import {ClothesModel} from "../../../../../../../../core/interfaces/clothes.model";
import {ClothesService} from "../../../../../../../../core/services/clothes.service";
import {faPenToSquare, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {faSearchengin} from "@fortawesome/free-brands-svg-icons";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {HttpClient} from "@angular/common/http";


@Component({
  selector: 'app-clothing-form',
  templateUrl: './clothing-form.component.html',
  styleUrls: ['./clothing-form.component.scss']
})
export class ClothingFormComponent implements OnInit {
  @Input() clothing = new ClothesModel();
  clothingBackUp = new ClothesModel();
  placeForm!: FormGroup;
  icon = faPenToSquare;
  scrapeIcon = faSearchengin;
  loadingIcon = faSpinner;
  isNew = true;
  fetching = false;

  constructor(
    private clothesService: ClothesService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private errorRes: ErrorResolutionService,
    private auth: AngularFireAuth,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    if (this.clothing.id !== '') {
      this.isNew = false;
    }
    this.clothingBackUp = Object.assign({}, this.clothing);
    this.buildForm();
  }

  buildForm(): void {
    this.placeForm = this.formBuilder.group({
      name: new FormControl(this.clothing.name || '', [Validators.required]),
      link: new FormControl(this.clothing.link || '', [Validators.required]),
      price: new FormControl(this.clothing.price || '', [Validators.required]),
      ownLink: new FormControl(this.clothing.ownLink || false, [Validators.required]),
      numberedSizes: new FormControl(this.clothing.numberedSizes || false, [Validators.required]),
    });
  }

  resetForm(sent:boolean): void {
    if (!sent) {
      this.clothing = Object.assign({}, this.clothingBackUp);
    }
    this.buildForm();
  }

  sendData(): void {
    this.clothing.name = this.placeForm.get('name')?.value;
    this.clothing.link = this.placeForm.get('link')?.value;
    this.clothing.price = this.placeForm.get('price')?.value;
    this.clothing.ownLink = this.placeForm.get('ownLink')?.value;
    this.clothing.numberedSizes = this.placeForm.get('numberedSizes')?.value;
    this.clothesService.upsert(this.clothing);
  }

  openForm(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', scrollable: true, centered: true}).result.then(result => {
      this.sendData();
      this.resetForm(!this.isNew);
      console.log('Formulář uzavřen pomocí:' + result);
    }, (reason) => {
      this.resetForm(false);
      console.log(this.errorRes.getModalDismissReason(reason));
    });
  }
  getPrice() {
    let hasOwnLink = this.placeForm.get('ownLink')?.value;
    if(!hasOwnLink) {
      let url = this.placeForm.get('link')?.value;
      if (url) {
        if(url.includes('https://www.pracovni-obuv-odevy.cz/')) {
          this.fetching = true;
          this.auth.idToken.subscribe(token => {
            this.http.post('/fns/scrapePrice', {url: url}, {headers: {Authorization: `Bearer ${token}`},}).subscribe((data: any) => {
              this.placeForm.get('name')?.setValue(data.name);
              this.placeForm.get('price')?.setValue(data.price);
              this.fetching = false;
            });
          });
        } else {
          alert('Informace lze získat pouze ze stránky: https://www.pracovni-obuv-odevy.cz/');
        }
      } else {
        alert('Musíte prvně zadat odkaz na produkt.');
      }
    } else{
      alert('Nelze získat informace z odkazu, který není na určitou položku.');
    }
  }
}
