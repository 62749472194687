import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../core/services/auth.service";
import {CompanyInfoModel} from "../../core/interfaces/companyInfo.model";
import {DataService} from "../../core/services/data.service";

@Component({
  selector: 'app-tools-app',
  templateUrl: './tools-app.component.html',
  styleUrls: ['./tools-app.component.scss']
})
export class ToolsAppComponent implements OnInit{
  contactData = new CompanyInfoModel();
  constructor(
    public authService: AuthService,
    private dataService: DataService,
  ) {}
  ngOnInit(): void {
    this.dataService.getCompanyInfo().subscribe(result => {
      this.contactData = result.data();
    });
  }
}
