<button class="btn btn-sm btn-primary mx-1" (click)="showPlace(previewModal)"><fa-icon [icon]="icon"></fa-icon></button>
<ng-template #previewModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Náhled místa</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <b>Název: </b>{{place.name}}<br>
    <b>Odkaz: </b><a class="text-primary" href="{{place.link}}" target="_blank">{{place.link}}</a><br>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Hotovo</button>
  </div>
</ng-template>
