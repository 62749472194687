import {Component, OnInit} from '@angular/core';

import {EmployeeModel} from "../../../../../../core/interfaces/employee.model";
import {EmployeeService} from "../../../../../../core/services/employee.service";
import {UtilityService} from "../../../../../../core/services/utility.service";
import {faEye} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-reference-category',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss']
})
export class EmployeeListComponent implements OnInit {
  employeeList: EmployeeModel[] = [];
  asc = false;
  prewievIcon = faEye;

  constructor(
    private employeeService: EmployeeService,
    private util: UtilityService,
  ) {
  }

  ngOnInit(): void {
    this.getAllCategories();
  }

  getAllCategories(): void {
    this.employeeService.getAll().subscribe(result => {
      if (result.length > 0) {
        this.employeeList = result;
      } else {
        this.employeeList = [];
        console.log('Žádný záznam zaměstnance neexistuje!!!')
      }
    });
  }

  sort(key: string) {
    const sorter = this.util.sortByKey(this.employeeList, key, this.asc);
    this.employeeList = sorter.object;
    this.asc = sorter.asc;
  }
}
