import {Component, Input, OnInit} from '@angular/core';
import {ReferenceCategoryModel} from '../../../../../../core/interfaces/referenceCategory.model';
import {CategoryService} from '../../../../../../core/services/category.service';

@Component({
  selector: 'app-category-name',
  templateUrl: './category-name.component.html',
  styleUrls: ['./category-name.component.scss']
})
export class CategoryNameComponent implements OnInit {
  @Input() categoryID = '';
  categoryInfo = new ReferenceCategoryModel();

  constructor(
    private categoryService: CategoryService,
  ) { }

  ngOnInit(): void {
    if (this.categoryID) {
      this.categoryService.getById(this.categoryID).subscribe(result => {
        this.categoryInfo = result.data();
      });
    }
  }
}
