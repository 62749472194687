import { Injectable } from '@angular/core';
import {ReferencePlaceModel} from '../interfaces/referencePlace.model';
import {Observable} from 'rxjs';
import {PlaceRepositoryService} from '../repositories/place-repository.service';

@Injectable({
  providedIn: 'root'
})
export class PlaceService {

  constructor(
    private repository: PlaceRepositoryService,
  ) {}

  createId(): string {
    const id = this.repository.createId();
    console.log('Generuji ID ' + id + ' pro záznam místa...');
    return id;
  }

  update(place: ReferencePlaceModel): Observable<any> {
    console.log('Aktualizuji záznam místa ' + place.id + '...');
    return this.repository.update(place);
  }
  upsert(place: ReferencePlaceModel): Observable<any> {
    console.log('Aktualizuji/Vytvářím záznam místa ' + place.id + '...');
    return this.repository.upsert(place);
  }
  delete(id: string): Observable<void> {
    console.log('Mažu záznam místa ' + id + '...');
    return this.repository.deleteDocument(id);
  }

  getAll(): Observable<ReferencePlaceModel[]> {
    console.log('Načítám záznamy všech míst...');
    return this.repository.getAll({orderBy: 'name', orderByDirection: 'asc'});
  }
  getById(id: string): Observable<any> {
    console.log('Načítám záznam místa ' + id);
    return this.repository.getById(id);
  }
}
