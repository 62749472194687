import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {WebAppComponent} from "./layouts/web-app/web-app.component";
import {AdminAppComponent} from "./layouts/admin-app/admin-app.component";

import {WEB_ROUTES} from "./routes/web-layout.routes";
import {ADMIN_ROUTES} from "./routes/admin-layout.routes";
import {TOOLS_ROUTES} from "./routes/tools-layout.routes";
import {HomeComponent} from "./apps/web/components/pages/home/home.component";
import {ReferencesComponent} from "./apps/web/components/pages/references/references.component";
import {RedirectComponent} from "./apps/web/components/pages/redirect/redirect.component";
import {ReferencesCategoryComponent} from "./apps/web/components/pages/references/references-category/references-category.component";
import {ReferenceDetailComponent} from "./apps/web/components/pages/references/reference-detail/reference-detail.component";
import {ContactsComponent} from "./apps/web/components/pages/contacts/contacts.component";
import {PageNotFoundComponent} from "./apps/web/components/pages/page-not-found/page-not-found.component";
import {DashboardComponent} from "./apps/admin/components/pages/dashboard/dashboard.component";
import {CompanyInfoComponent} from "./apps/admin/components/pages/content/company-info/company-info.component";
import {ContactPeopleComponent} from "./apps/admin/components/pages/content/contact-people/contact-people.component";
import {ReferenceListComponent} from "./apps/admin/components/pages/reference/reference-list/reference-list.component";
import {ReferencePlaceComponent} from "./apps/admin/components/pages/reference/reference-place/reference-place.component";
import {ReferenceCategoryComponent} from "./apps/admin/components/pages/reference/reference-category/reference-category.component";
import {NotFoundComponent} from "./apps/admin/components/pages/errors/not-found/not-found.component";
import {
  EmployeeClothingTableComponent
} from "./apps/admin/components/pages/employees/employee-clothing-table/employee-clothing-table.component";
import {EmployeeListComponent} from "./apps/admin/components/pages/employees/employee-list/employee-list.component";
import {
  EmployeeProfileComponent
} from "./apps/admin/components/pages/employees/employee-list/employee-profile/employee-profile.component";
import {
  EmployeeClothingListComponent
} from "./apps/admin/components/pages/employees/employee-clothing-table/employee-clothing-list/employee-clothing-list.component";
import {LoginAppComponent} from "./layouts/login-app/login-app.component";
import {AuthGuard} from "./core/guards/auth.guard";
import {UsersComponent} from "./apps/admin/components/pages/users/users.component";
import {RedirectService} from "./core/redirects/redirect.service";
import {EmployeeAttendanceComponent} from "./apps/admin/components/pages/employees/employee-attendance/employee-attendance.component";
import {AttendanceGuard} from "./core/guards/attendance.guard";
import {SiteGuard} from "./core/guards/site.guard";
import {EmployeeGuard} from "./core/guards/employee.guard";
import {UserGuard} from "./core/guards/user.guard";
import {SuperAdminGuard} from "./core/guards/superAdmin.guard";
import {NotAuthorizedComponent} from "./apps/admin/components/pages/errors/not-authorized/not-authorized.component";
import {
  EmployeeAttendanceOverviewComponent
} from "./apps/admin/components/pages/employees/employee-attendance/employee-attendance-overview/employee-attendance-overview.component";
import {MachineProfileComponent} from "./apps/admin/components/pages/machines/machine-profile/machine-profile.component";
import {MachineListComponent} from "./apps/admin/components/pages/machines/machine-list.component";
import {MachineGuard} from "./core/guards/machine.guard";
import {CertificatesComponent} from "./apps/admin/components/pages/content/certificates/certificates.component";
import {UrsFinderComponent} from "./apps/tools/urs-finder/urs-finder.component";
import {ToolsAppComponent} from "./layouts/tools-app/tools-app.component";
import {CookiesComponent} from "./apps/web/components/pages/cookies/cookies.component";

const appRoutes: Routes = [
  {
    path: 'login',
    component: LoginAppComponent,
  },
  {
    path: 'tools',
    component: ToolsAppComponent,
    children: [
      { path: 'urs', component: UrsFinderComponent, data: {title: 'URS Vyhledávač'}},
    ]
  },
  {
    path: 'admin',
    component: AdminAppComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent, data: {title: 'Přehled', siteType: 'adminPanel'} },
      { path: 'content/companyInfo', component: CompanyInfoComponent, data: {title: 'O společnosti', siteType: 'adminPanel'}, canActivate: [SiteGuard] },
      { path: 'content/contactPeople', component: ContactPeopleComponent, data: {title: 'Kontaktní osoby', siteType: 'adminPanel'}, canActivate: [SiteGuard] },
      { path: 'content/companyCertificates', component: CertificatesComponent, data: {title: 'Certifikáty', siteType: 'adminPanel'}, canActivate: [SiteGuard] },
      { path: 'references/reference', component: ReferenceListComponent, data: {title: 'Reference', siteType: 'adminPanel'}, canActivate: [SiteGuard] },
      { path: 'references/place', component: ReferencePlaceComponent, data: {title: 'Místa referencí', siteType: 'adminPanel'}, canActivate: [SiteGuard] },
      { path: 'references/category', component: ReferenceCategoryComponent, data: {title: 'Kategorie referencí', siteType: 'adminPanel'}, canActivate: [SiteGuard] },
      { path: 'references/category/:link', component: ReferenceListComponent, data: {title: 'Reference', siteType: 'adminPanel'}, canActivate: [SiteGuard] },
      { path: 'employees/list', component: EmployeeListComponent, data: {title: 'Zaměstnanci', siteType: 'adminPanel'}, canActivate: [EmployeeGuard] },
      { path: 'employees/list/profile/:id', component: EmployeeProfileComponent, data: {title: 'Zaměstnanec', siteType: 'adminPanel'}, canActivate: [EmployeeGuard] },
      { path: 'employees/attendance', component: EmployeeAttendanceComponent, data: {title: 'Docházka', siteType: 'adminPanel'}, canActivate: [AttendanceGuard] },
      { path: 'employees/attendance/overview', component: EmployeeAttendanceOverviewComponent, data: {title: 'Přehled docházky', siteType: 'adminPanel'}, canActivate: [AttendanceGuard] },
      { path: 'employees/clothes', component: EmployeeClothingTableComponent, data: {title: 'Tabulka oblečení', siteType: 'adminPanel'}, canActivate: [EmployeeGuard] },
      { path: 'employees/clothes/list', component: EmployeeClothingListComponent, data: {title: 'Seznam oblečení', siteType: 'adminPanel'}, canActivate: [EmployeeGuard] },
      { path: 'employees/gps', component: RedirectComponent, data: {redirectUrl: 'https://www.onisystem.net/inetgweb/login.do?TYPLOGIN=OWNER'}, canActivate: [SuperAdminGuard, RedirectService] },
      { path: 'machines/list', component: MachineListComponent, data: {title: 'Seznam strojů', siteType: 'adminPanel'}, canActivate: [MachineGuard] },
      { path: 'machines/list/profile/:id', component: MachineProfileComponent, data: {title: 'Stroj', siteType: 'adminPanel'}, canActivate: [MachineGuard] },
      { path: 'users', component: UsersComponent, data: {title: 'Správa uživatelů', siteType: 'adminPanel'}, canActivate: [UserGuard] },
      { path: '401', component: NotAuthorizedComponent, data: {title: '401 Error', siteType: 'adminPanel'} }, // Page not found
      { path: '**', component: NotFoundComponent, data: {title: '404 Error', siteType: 'adminPanel'}}, // Page not found
    ]
  },
  {
    path: '',
    component: WebAppComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' }, // Redirects empty path to /home
      { path: 'home', component: HomeComponent, data: {title: 'Domů'} },
      { path: 'references', component: ReferencesComponent, data: {title: 'Služby a reference'} },
      { path: 'references/category/:category', component: ReferencesCategoryComponent },
      { path: 'references/id/:id', component: ReferenceDetailComponent },
      { path: 'contacts', component: ContactsComponent, data: {title: 'Kontakty'} },
      // { path: 'cookies', component: CookiesComponent, data: {title: 'GDPR'} },
      { path: '**', component: PageNotFoundComponent, data: {title: '404'} }, // Page not found
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
