import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {FileService} from 'src/app/core/services/file.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ErrorResolutionService} from 'src/app/core/services/errorResolution.service';
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {CertificateModel} from "../../../../../../../core/interfaces/certificate.model";
import {DataService} from "../../../../../../../core/services/data.service";

@Component({
  selector: 'app-certificate-form',
  templateUrl: './certificate-form.component.html',
  styleUrls: ['./certificate-form.component.scss']
})
export class CertificateFormComponent implements OnInit {
  @Input() certificate = new CertificateModel();
  certificateBackUp = new CertificateModel();
  certificateForm!: FormGroup;
  icon = faPenToSquare;

  smallImageDisableSending = false;
  smallImageUploadProgress = 0;
  largeImageDisableSending = false;
  largeImageUploadProgress = 0;

  isNew = true;

  constructor(
    private dataService: DataService,
    private fileService: FileService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private errorRes: ErrorResolutionService,
  ) { }

  ngOnInit(): void {
    if (this.certificate.id !== '') {
      this.isNew = false;
    } else {
      this.certificate.id = this.dataService.createId();
    }
    this.certificateBackUp = Object.assign({}, this.certificate);
    this.buildForm();
  }

  buildForm(): void {
    this.certificateForm = this.formBuilder.group({
      name: new FormControl(this.certificate.name || '', [Validators.required]),
      priority: new FormControl(this.certificate.priority || '', [Validators.required]),
      fileLow: new FormControl(''),
      fileHigh: new FormControl(''),
    });
  }
  resetForm(sent:boolean): void {
    if (!sent) {
      this.certificate = Object.assign({}, this.certificateBackUp);
    }
    if (this.isNew) {
      this.certificate.id = this.dataService.createId();
    }
    this.smallImageDisableSending = false;
    this.smallImageUploadProgress = 0;
    this.largeImageDisableSending = false;
    this.largeImageUploadProgress = 0;
    this.buildForm();
  }

  chooseSmallImage(event: any): void {
    if(event.target.files.length > 0) {
      this.smallImageUploadProgress = 0;
      this.smallImageDisableSending = true;
      const smallImage = event.target.files[0];
      this.fileService.upload(smallImage, '/certificates/' + this.certificate.id, true).subscribe(result => {
        if (typeof result === 'number') {
          this.smallImageUploadProgress = Math.round(result);
        } else {
          this.certificate.low = result;
          this.smallImageDisableSending = false;
        }
      });
    }
  }

  chooseLargeImage(event: any): void {
    if(event.target.files.length > 0) {
      this.largeImageUploadProgress = 0;
      this.largeImageDisableSending = true;
      const largeImage = event.target.files[0];
      this.fileService.upload(largeImage, '/certificates/' + this.certificate.id, true).subscribe(result => {
        if (typeof result === 'number') {
          this.largeImageUploadProgress = Math.round(result);
        } else {
          this.certificate.high = result;
          this.largeImageDisableSending = false;
        }
      });
    }
  }
  sendData(): void {
    this.certificate.name = this.certificateForm.get('name')?.value;
    this.certificate.priority = this.certificateForm.get('priority')?.value;
    this.dataService.upsertCertificate(this.certificate);
  }
  openForm(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', scrollable: true, centered: true}).result.then(result => {
      this.sendData();
      this.resetForm(!this.isNew);
      console.log('Formulář uzavřen pomocí:' + result);
    }, (reason) => {
      this.resetForm(false);
      console.log(this.errorRes.getModalDismissReason(reason));
    });
  }
}
