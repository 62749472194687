import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-web-app',
  templateUrl: './web-app.component.html',
  styleUrls: ['./web-app.component.scss']
})
export class WebAppComponent implements OnInit {
  showPopup = true;
  ngOnInit() {
    // Načtení stavu showPopup z localStorage při inicializaci komponenty
    const storedState = localStorage.getItem('showPopup');
    this.showPopup = storedState === null ? true : JSON.parse(storedState);
  }
  consent() {
    this.showPopup = false;
    // Uložení stavu showPopup do localStorage při změně stavu
    localStorage.setItem('showPopup', JSON.stringify(this.showPopup));
    console.log('Příjmout tlačítko bylo rozmím');
  }
  constructor() { }
}
