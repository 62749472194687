import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {ReferenceCategoryModel, ReferenceSubCategoryModel} from 'src/app/core/interfaces/referenceCategory.model';
import {CategoryService} from 'src/app/core/services/category.service';
import {FileService} from 'src/app/core/services/file.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ErrorResolutionService} from 'src/app/core/services/errorResolution.service';
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html',
  styleUrls: ['./category-form.component.scss']
})
export class CategoryFormComponent implements OnInit {
  @Input() category = new ReferenceCategoryModel();
  categoryBackUp = new ReferenceCategoryModel();
  categoryForm!: FormGroup;
  icon = faPenToSquare;

  disableSending = false;
  uploadProgress = 0;
  file: any;

  isNew = true;

  constructor(
    private categoryService: CategoryService,
    private fileService: FileService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private errorRes: ErrorResolutionService,
  ) { }

  ngOnInit(): void {
    if (this.category.id !== '') {
      this.isNew = false;
    } else {
      this.category.id = this.categoryService.createId();
    }
    this.categoryBackUp = Object.assign({}, this.category);
    this.buildForm();
  }

  buildForm(): void {
    this.categoryForm = this.formBuilder.group({
      priority: new FormControl(this.category.priority || '', [Validators.required]),
      name: new FormControl(this.category.name || '', [Validators.required]),
      link: new FormControl(this.category.link || '', [Validators.required]),
      file: new FormControl(''),
      galleryMode: new FormControl(this.category.galleryMode || false),
      subcategoryName: new FormControl(''),
    });
  }
  resetForm(sent:boolean): void {
    if (!sent) {
      this.category = Object.assign({}, this.categoryBackUp);
    }
    if (this.isNew) {
      this.category.id = this.categoryService.createId();
    }
    this.disableSending = false;
    this.uploadProgress = 0;
    this.buildForm();
  }
  chooseFile(event: any): void{
    if(event.target.files.length > 0) {
      this.uploadProgress = 0;
      this.disableSending = true;
      this.file = event.target.files[0];
      this.fileService.upload(this.file, '/categories/' + this.category.id, true).subscribe(result => {
        if (typeof result === 'number') {
          this.uploadProgress = Math.round(result);
        } else {
          this.category.file = result;
          this.disableSending = false;
        }
      });
    }
  }
  sendData(): void {
    this.category.priority = this.categoryForm.get('priority')?.value;
    this.category.name = this.categoryForm.get('name')?.value;
    this.category.link = this.categoryForm.get('link')?.value;
    if (this.isNew) {
      this.category.galleryMode = this.categoryForm.get('galleryMode')?.value;
    }
    this.categoryService.upsert(this.category);
  }
  openForm(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', scrollable: true, centered: true}).result.then(result => {
      this.sendData();
      this.resetForm(!this.isNew);
      console.log('Formulář uzavřen pomocí:' + result);
    }, (reason) => {
      this.resetForm(false);
      console.log(this.errorRes.getModalDismissReason(reason));
    });
  }
  removeSub(sub: ReferenceSubCategoryModel): void {
    const index = this.category.subCategories.indexOf(sub);
    if (this.category.subCategories.length > 1) {
      this.category.subCategories.splice(index, 1);
    } else {
      this.category.subCategories = [];
    }
  }

  addSub() {
    let name = this.categoryForm.get('subcategoryName')?.value;
    let id = this.categoryForm.get('subcategoryName')?.value.normalize("NFD").replace(/\p{Diacritic}/gu, "").replace(/ /g, "-").toLowerCase();
    this.category.subCategories.push({id, name});
    this.categoryForm.get('subcategoryName')?.setValue('');
  }
}
