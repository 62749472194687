<app-site-header
  [headerLine1]="headerL1"
  [headerLine2]="headerL2"
  [btnType]="btnType"
></app-site-header>
<main>
  <div class="row">
    <div class="col-12 text-center bg-white shadow-lg">
      <ng-container *ngIf="reference">
        <div class="row mt-5">
          <h1>{{reference.name}}</h1>
        </div>
        <div class="row my-2 justify-content-center text-start position-relative">
          <div class="col-lg-6 col-12 text-center mt-4 mb-5 d-flex">
            <div class="reference-image my-auto">
              <ngb-carousel #carousel [interval]="2500" [pauseOnHover]="true" [showNavigationArrows]="reference.mainImage.length > 1" [showNavigationIndicators]="reference.mainImage.length > 1">
                <ng-template ngbSlide *ngFor="let image of reference.mainImage; index as i">
                  <div class="picsum-img-wrapper">
                    <a class="reference-image" href="{{image.src}}" data-fancybox="carousel-set"><img class="reference-image rounded" [src]="image.src" alt="{{image.name}}"></a>
                  </div>
                </ng-template>
              </ngb-carousel>
            </div>
          </div>
          <div class="col-lg-6 col-10 mt-4 mb-5 text-lg-start text-center d-flex">
            <div class="my-auto">
              <h3 class="mb-0">Místo výstavby:</h3>
              <p class="link"><a href="{{referencePlace.link}}" target="_blank">{{referencePlace.name}}</a></p>
              <h3 class="mb-0">Doba výstavby:</h3>
              <p>{{referenceDateFrom}} - {{referenceDateTo}}</p>
              <h3 class="mb-0">Popis:</h3>
              <p class="reference-info">{{reference.description}}</p>

              <div class="link">
                Pro bližší informace nás kontaktujte: <a href="mailto:{{contactData.email}}" class="text-nowrap"><b>{{contactData.email}}</b></a>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!reference">
        <div class="row mt-5">
          <h1>Kategorie nebyla nalezena.</h1>
        </div>
      </ng-container>
      <div class="row">
        <div class="col-12 bg-white content-box">
          <br/>
          <br/>
        </div>
      </div>
    </div>
  </div>
</main>

