import {Component, Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {ErrorResolutionService} from 'src/app/core/services/errorResolution.service';
import {EmployeeModel} from "../../../../../../../../../core/interfaces/employee.model";
import {EmployeeService} from "../../../../../../../../../core/services/employee.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {equales} from "../../../../../../../../../core/validators/equals.validator";
import {Router} from "@angular/router";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-employee-delete',
  templateUrl: './employee-delete.component.html',
  styleUrls: ['./employee-delete.component.scss']
})
export class EmployeeDeleteComponent{
  @Input() employee = new EmployeeModel();
  nameForm!: FormGroup;
  icon = faTrashCan;

  constructor(
    private employeeService: EmployeeService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private errorRes: ErrorResolutionService,
    private router: Router,
  ) { }

  buildForm(): void {
    this.nameForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required, equales(this.employee.lastName + ' ' + this.employee.firstName)]),
    });
  }

  showAlert(content: any): void {
    this.buildForm();
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', scrollable: true, centered: true}).result.then(result => {
      this.employeeService.delete(this.employee.id);
      console.log('Formulář uzavřen pomocí:' + result);
      this.router.navigate(['/admin/employees/list']).then();
    }, (reason) => {
      console.log(this.errorRes.getModalDismissReason(reason));
    });
  }
}
