<button *ngIf="!isNew" class="btn btn-sm btn-success mx-1" (click)="openForm(previewModal)">
  <fa-icon [icon]="icon"></fa-icon>
</button>
<button *ngIf="isNew" class="btn btn-lg btn-gradient-custom" (click)="openForm(previewModal)">Přidat kategorii</button>
<ng-template #previewModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{isNew ? 'Vytvořit' : 'Upravit'}} kategorii</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="categoryForm">
      <div class="col form-floating form-group mb-3">
        <input formControlName="priority" id="priority" type="number" class="form-control" placeholder="nothing" required>
        <label for="priority">Priorita:</label>
      </div>
      <div class="col form-floating form-group mb-3">
        <input
          formControlName="name"
          name="categoryName"
          id="categoryName"
          class="form-control"
          placeholder="nothing"
          required>
        <label for="categoryName">Název:</label>
      </div>
      <div class="col form-floating form-group mb-3">
        <input
          formControlName="link"
          id="categoryLink"
          class="form-control"
          placeholder="nothing"
          required>
        <label for="categoryLink">Odkaz:</label>
      </div>
      <div class="col form-group">
        <label for="categoryImage" class="mb-1">Obrázek:</label>
        <div *ngIf="(!isNew && !disableSending) || (isNew && !disableSending && uploadProgress === 100)"
             class="text-center mb-3">
          <img class="image" src="{{category.file.src}}" alt="{{category.file.name}}">
        </div>
        <p *ngIf="disableSending">
          <ngb-progressbar
            [showValue]="true"
            [value]="uploadProgress"
            [striped]="true"
            [animated]="true"
          ></ngb-progressbar>
        </p>
        <input
          (change)="chooseFile($event)"
          [required]="isNew&&category.file.src===''"
          [attr.disabled]="disableSending ? '' : null"
          accept="image/jpeg, image/png"
          formControlName="file"
          id="categoryImage"
          type="file"
          class="form-control">
      </div>
      <div *ngIf="isNew" class="col form-check mt-3">
        <input formControlName="galleryMode" class="form-check-input" type="checkbox" value="" id="galleryMode">
        <label class="form-check-label" for="galleryMode">Režim galerie</label>
      </div>
      <div class="my-3" *ngIf="category.galleryMode">
        <p class="mb-1">Podkategoie galerie:</p>
        <table class="table table-hover">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Název</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let sub of category.subCategories; index as i">
            <th scope="row">{{ i + 1 }}</th>
            <td>{{sub.name}}</td>
            <td><button type="button" class="btn-close btn-close-danger" (click)="removeSub(sub)"></button></td>
          </tr>
          <tr>
            <th>#</th>
            <td>
                <input
                  formControlName="subcategoryName"
                  name="subcategoryName"
                  id="subcategoryName"
                  class="form-control form-control-sm"
                  placeholder="Název:">
            </td>
            <td><button class="btn btn-sm btn-success" (click)="addSub()">Odeslat</button></td>
          </tr>
          </tbody>
        </table>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button *ngIf="isNew" (click)="sendData()" [disabled]="disableSending || !categoryForm.valid"
            class="btn btn-success" type="button">Další
    </button>
    <button (click)="modal.close('Save click')" [disabled]="disableSending || !categoryForm.valid" type="button"
            class="btn btn-primary">Uložit
    </button>
  </div>
</ng-template>
