import { Injectable } from '@angular/core';
import {ReferenceRepositoryService} from '../repositories/reference-repository.service';
import {Observable} from 'rxjs';
import {ReferenceModel} from '../interfaces/reference.model';

@Injectable({
  providedIn: 'root'
})
export class ReferenceService {

  constructor(
    private repository: ReferenceRepositoryService,
  ) {}

  createId(): string {
    const id = this.repository.createId();
    console.log('Generuji ID ' + id + ' pro záznam reference...');
    return id;
  }
  update(reference: ReferenceModel): Observable<any> {
    console.log('Aktualizuji záznam reference ' + reference.id + '...');
    return this.repository.update(reference);
  }
  upsert(reference: ReferenceModel): Observable<any> {
    console.log('Aktualizuji/Vytvářím záznam reference ' + reference.id + '...');
    return this.repository.upsert(reference);
  }
  delete(id: string): Observable<void> {
    console.log('Mažu záznam reference ' + id + '...');
    return this.repository.deleteDocument(id);
  }
  getAll(): Observable<ReferenceModel[]> {
    console.log('Načítám záznamy všech referencí...');
    return this.repository.getAll();
  }
  getById(id: string): Observable<any> {
    console.log('Načítám záznam reference ' + id);
    return this.repository.getById(id);
  }
  getAllByCategory(category: string): Observable<ReferenceModel[]> {
    return this.repository.getAllReferencesByCategories(category);
  }
}
