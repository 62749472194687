import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-name-hover-id',
  templateUrl: './name-hover-id.component.html',
  styleUrls: ['./name-hover-id.component.scss']
})
export class NameHoverIdComponent {
  @Input() object!: any;

  constructor() { }

  copyId(): void {
    // navigator.clipboard.writeText(this.object.id).then();
  }
}
