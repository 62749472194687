import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ErrorResolutionService} from 'src/app/core/services/errorResolution.service';
import {MonthlyAttendance} from "../../../../../../../../../../core/interfaces/employeeAttendance.model";
import {ExcelService} from "../../../../../../../../../../core/services/excel.service";
import {EmployeeService} from "../../../../../../../../../../core/services/employee.service";
import {AttendanceService} from "../../../../../../../../../../core/services/attendance.service";
import {EmployeeModel} from "../../../../../../../../../../core/interfaces/employee.model";
import {SalaryService} from "../../../../../../../../../../core/services/salary.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[attendance-modal]',
  templateUrl: './attendance-modal.component.html',
  styleUrls: ['./attendance-modal.component.scss']
})
export class AttendanceModalComponent implements OnInit{
  @Input() attendance = new MonthlyAttendance();
  @Input() employeeID = "";
  @Input() mode = 0;
  @Input() haveMoney = {
    5000: 0,
    2000: 0,
    1000: 0,
    500: 0,
    200: 0,
    100: 0,
    50: 0,
    20: 0,
    10: 0,
    5: 0
  }

  employee: EmployeeModel = new EmployeeModel();

  constructor(
    private modalService: NgbModal,
    private errorRes: ErrorResolutionService,
    private excelService: ExcelService,
    public salaryService: SalaryService,
    public attendanceService: AttendanceService,
    private employeeService: EmployeeService,
  ) { }
  ngOnInit(): void {
    this.employeeService.getById(this.employeeID).subscribe(result => {
      this.employee = result.data();
    });
  }
  openModal(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', scrollable: true, centered: true, size: "lg"}).result.then(result => {
      console.log('Formulář uzavřen pomocí:' + result);
    }, (reason) => {
      console.log(this.errorRes.getModalDismissReason(reason));
    });
  }
  export(doc: MonthlyAttendance): void {
      this.excelService.saveAsExcel(this.employee, doc);
  }
}
