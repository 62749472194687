<button *ngIf="!isNew" class="btn btn-sm btn-success mx-1" (click)="openForm(previewModal)"><fa-icon [icon]="icon"></fa-icon></button>
<button *ngIf="isNew" class="btn btn-lg btn-gradient-custom" (click)="openForm(previewModal)">Přidat uživatele</button>
<ng-template #previewModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{isNew ? 'Vytvořit' : 'Upravit'}} Uživatele</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="signUpForm">
      <div class="col form-floating form-group mb-3">
        <input
          formControlName="name"
          id="name"
          class="form-control"
          placeholder="nothing"
          required>
        <label for="name">Jméno:</label>
      </div>
      <div *ngIf="isNew" class="col form-floating form-group mb-3">
        <input
          formControlName="email"
          id="email"
          class="form-control"
          placeholder="nothing"
          required>
        <label for="email">Email:</label>
      </div>
      <div class="card">
        <div class="card-body">
          <p class="card-title">Oprávnění uživatele:</p>
          <div class="card-text">
            <div class="col form-check">
              <input formControlName="siteAdmin" class="form-check-input" type="checkbox" value="" id="siteAdmin">
              <label class="form-check-label" for="siteAdmin">- Spravovat obsah webové prezentace</label>
            </div>
            <div class="col form-check">
              <input formControlName="attendanceAdmin" class="form-check-input" type="checkbox" value="" id="attendanceAdmin">
              <label class="form-check-label" for="attendanceAdmin">- Zapisovat docházku zaměstnancům</label>
            </div>
            <div class="col form-check">
              <input formControlName="employeeAdmin" class="form-check-input" type="checkbox" value="" id="employeeAdmin">
              <label class="form-check-label" for="employeeAdmin">- Přístup k informacím o zaměstnancích</label>
            </div>
            <div class="col form-check">
              <input formControlName="machinesAdmin" class="form-check-input" type="checkbox" value="" id="machinesAdmin">
              <label class="form-check-label" for="machinesAdmin">- Přístup k informacím o strojích</label>
            </div>
            <div class="col form-check">
              <input formControlName="userAdmin" class="form-check-input" type="checkbox" value="" id="userAdmin">
              <label class="form-check-label" for="userAdmin">- Spravovat uživatele</label>
            </div>
            <div *ngIf="authService.appUser as appUser">
              <div *ngIf="appUser.permissions.superAdmin" class="col form-check">
                <input formControlName="superAdmin" class="form-check-input" type="checkbox" value="" id="superAdmin">
                <label class="form-check-label" for="superAdmin">- Bez restrikcí</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button *ngIf="isNew" (click)="signUp()" [disabled]="!signUpForm.valid" type="button" class="btn btn-success">Další</button>
    <button (click)="modal.close('Save click')" [disabled]="!signUpForm.valid" type="button" class="btn btn-primary">Uložit</button>
  </div>
</ng-template>
