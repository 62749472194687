<button *ngIf="!isNew" class="btn btn-sm btn-success mx-1" (click)="openForm(previewModal)">
  <fa-icon [icon]="icon"></fa-icon>
</button>
<button *ngIf="isNew" class="btn btn-lg btn-gradient-custom" (click)="openForm(previewModal)">Přidat certifikát</button>
<ng-template #previewModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{isNew ? 'Vytvořit' : 'Upravit'}} certifikát</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="certificateForm">
      <div class="col form-floating form-group mb-3">
        <input formControlName="priority" id="priority" type="number" class="form-control" placeholder="nothing" required>
        <label for="priority">Priorita:</label>
      </div>
      <div class="col form-floating form-group mb-3">
        <input formControlName="name" id="name" class="form-control" placeholder="nothing" required>
        <label for="name">Název:</label>
      </div>
      <div class="col form-group">
        <label for="categoryImage" class="mb-1">Obrázek:<sup>(Nízká kvalita)</sup></label>
        <div *ngIf="(!isNew && !smallImageDisableSending) || (isNew && !smallImageDisableSending && smallImageUploadProgress === 100)"
             class="text-center mb-3">
          <img class="image" src="{{certificate.low.src}}" alt="{{certificate.low.name}}">
        </div>
        <p *ngIf="smallImageDisableSending">
          <ngb-progressbar
            [showValue]="true"
            [value]="smallImageUploadProgress"
            [striped]="true"
            [animated]="true"
          ></ngb-progressbar>
        </p>
        <input
          (change)="chooseSmallImage($event)"
          [required]="isNew&&certificate.low.src===''"
          [attr.disabled]="smallImageDisableSending ? '' : null"
          accept="image/jpeg, image/png"
          formControlName="fileLow"
          id="smallImage"
          type="file"
          class="form-control">
      </div>
      <div class="col form-group">
        <label for="categoryImage" class="mb-1">Obrázek:<sup>(Vysoká kvalita)</sup></label>
        <div *ngIf="(!isNew && !largeImageDisableSending) || (isNew && !largeImageDisableSending && largeImageUploadProgress === 100)"
             class="text-center mb-3">
          <img class="image" src="{{certificate.high.src}}" alt="{{certificate.high.name}}">
        </div>
        <p *ngIf="largeImageDisableSending">
          <ngb-progressbar
            [showValue]="true"
            [value]="largeImageUploadProgress"
            [striped]="true"
            [animated]="true"
          ></ngb-progressbar>
        </p>
        <input
          (change)="chooseLargeImage($event)"
          [required]="isNew&&certificate.high.src===''"
          [attr.disabled]="largeImageDisableSending ? '' : null"
          accept="image/jpeg, image/png"
          formControlName="fileHigh"
          id="largeImage"
          type="file"
          class="form-control">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button *ngIf="isNew" (click)="sendData()" [disabled]="smallImageDisableSending || largeImageDisableSending || !certificateForm.valid" class="btn btn-success" type="button">Další</button>
    <button (click)="modal.close('Save click')" [disabled]="smallImageDisableSending || largeImageDisableSending || !certificateForm.valid" type="button" class="btn btn-primary">Uložit</button>
  </div>
</ng-template>
