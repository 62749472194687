import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AngularFireModule} from '@angular/fire/compat';
import {environment} from 'src/environments/environment';
import { RouterModule } from '@angular/router';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {AdminAppComponent} from "./layouts/admin-app/admin-app.component";
import {WebAppComponent} from "./layouts/web-app/web-app.component";

import {WebNavbarComponent} from './layouts/web-app/navbar/web-navbar.component';
import {WebFooterComponent} from './layouts/web-app/footer/web-footer.component';
import {WebFooterLinksComponent} from './layouts/web-app/footer/footer-links/web-footer-links.component';
import {WebFooterContactsComponent} from './layouts/web-app/footer/footer-contacts/web-footer-contacts.component';
import {
  WebFooterContactFormComponent
} from './layouts/web-app/footer/footer-contacts/footer-contact-form/web-footer-contact-form.component';
import {WebScrollUpButtonComponent} from './layouts/web-app/footer/scroll-up-button/web-scroll-up-button.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {MatToolbarModule} from "@angular/material/toolbar";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatDividerModule} from "@angular/material/divider";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatListModule} from "@angular/material/list";
import {MatMenuModule} from "@angular/material/menu";
import {LoginAppComponent} from "./layouts/login-app/login-app.component";
import {AuthService} from "./core/services/auth.service";
import {AngularFireAuthModule} from "@angular/fire/compat/auth";
import {AngularFirestoreModule} from "@angular/fire/compat/firestore";
import {AngularFireStorageModule} from "@angular/fire/compat/storage";
import {HttpClientModule} from "@angular/common/http";
import {ToolsAppComponent} from "./layouts/tools-app/tools-app.component";
import {FlexLayoutModule} from '@angular/flex-layout';
@NgModule({
  declarations: [
    AppComponent,
    WebAppComponent,
    AdminAppComponent,
    LoginAppComponent,
    ToolsAppComponent,
    WebNavbarComponent,
    WebFooterComponent,
    WebFooterLinksComponent,
    WebFooterContactsComponent,
    WebFooterContactFormComponent,
    WebScrollUpButtonComponent,
  ],
  imports: [
    FlexLayoutModule,
    RouterModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgbModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatExpansionModule,
    MatListModule,
    MatMenuModule,
    FormsModule
  ],
  providers: [AuthService],
  bootstrap: [AppComponent]
})
export class AppModule {}
