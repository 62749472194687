<div class="row">
  <div class="col">
    <div class="card mt-3">
      <div class="card-body">
        <div class="justify-content-between d-lg-flex">
          <h3 class="text-nowrap d-sm-none text-center">{{machine.manufacturer}} {{machine.model}}</h3>
          <div class="d-flex flex-column flex-sm-row">
            <a class="example-image-link text-center text-sm-start mb-2 mb-sm-0" href="{{machine.previewImage.src}}" data-fancybox="preview-set"><img src="{{machine.previewImage.src}}" class="machine-img" alt="Náhled stroje"></a>
            <div class="ms-sm-3 text-center text-sm-start">
              <h3 class="mb-0 text-nowrap d-none d-sm-block">{{machine.manufacturer}} {{machine.model}}</h3>
              <span class="text-muted">Typ stroje: <span class="fst-italic">{{MachineTypeEnumMapping[machine.type]}}</span></span><br>
              <span *ngIf="operator" class="text-muted">Operátor: <span class="fst-italic">{{operator.lastName}} {{operator.firstName}}</span></span>
            </div>
          </div>
          <div class="mt-3 mt-lg-0">
            <div class="w-100 d-flex justify-content-center">
              <app-machine-form *ngIf="machine.id" [machine]="machine" [ngbTooltip]="editTip"></app-machine-form>
              <app-machine-delete [machine]="machine" [ngbTooltip]="deleteTip"></app-machine-delete>
            </div>
          </div>
        </div>
        <nav class="mt-3">
          <ul ngbNav #nav="ngbNav" [activeId]=activeTab (navChange)="onNavChange($event)"
              class="nav-tabs justify-content-center justify-content-md-start">
            <li [ngbNavItem]=1>
              <a ngbNavLink class="nav-link text-muted d-flex align-items-center">
                <span class="d-none d-sm-block">Informace</span>
                <fa-icon class="d-sm-none mx-1" [icon]="info"></fa-icon>
              </a>
              <ng-template ngbNavContent>

                <h5>Informace</h5>
                <div class="row">
                  <div class="col-xl-4 col-lg-6">
                    <table class="table table-borderless">
                      <tbody>
                      <tr class="text-nowrap">
                        <td class="text-end">Výrobce:</td>
                        <td class="fw-bold fst-italic">{{machine.manufacturer}}</td>
                      </tr>
                      <tr class="text-nowrap">
                        <td class="text-end">Model:</td>
                        <td class="fw-bold fst-italic">{{machine.model}}</td>
                      </tr>
                      <tr *ngIf="machine.vin !== ''">
                        <td class="text-end">VIN:</td>
                        <td class="fw-bold fst-italic">{{machine.vin}}</td>
                      </tr>
                      <tr *ngIf="machine.spz !== ''">
                        <td class="text-end">RZ:</td>
                        <td class="fw-bold fst-italic">{{machine.spz}}</td>
                      </tr>
                      <tr>
                        <td class="text-end"></td>
                        <td class="fw-bold fst-italic"></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-xl-4 col-lg-6">
                    <table class="table table-borderless text-nowrap">
                      <tbody>
                      <tr class="text-nowrap">
                        <td class="text-end">Typ stroje:</td>
                        <td class="fw-bold fst-italic">{{MachineTypeEnumMapping[machine.type]}}</td>
                      </tr>
                      <tr *ngIf="operator">
                        <td class="text-end">Operátor:</td>
                        <td class="fw-bold fst-italic"><a routerLink="/admin/employees/list/profile/{{operator.id}}">{{operator.lastName}} {{operator.firstName}}</a></td>
                      </tr>
                      <tr>
                        <td class="text-end">Datum pořízení:</td>
                        <td class="fw-bold fst-italic">{{toDate(machine.purchaseDate)}}</td>
                      </tr>
                      <tr>
                        <td class="text-end"></td>
                        <td class="fw-bold fst-italic"></td>
                      </tr>
                      <tr>
                        <td class="text-end"></td>
                        <td class="fw-bold fst-italic"></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-xl-4 col-lg-12">
                    <table class="table table-borderless text-nowrap">
                      <tbody>
                      <tr>
                        <td class="text-end"></td>
                        <td class="fw-bold fst-italic"></td>
                      </tr>
                      <tr>
                        <td class="text-end"></td>
                        <td class="fw-bold fst-italic"></td>
                      </tr>
                      <tr>
                        <td class="text-end"></td>
                        <td class="fw-bold fst-italic"></td>
                      </tr>
                      <tr>
                        <td class="text-end"></td>
                        <td class="fw-bold fst-italic"></td>
                      </tr>
                      <tr>
                        <td class="text-end"></td>
                        <td class="fw-bold fst-italic"></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </ng-template>
            </li>
            <li [ngbNavItem]=2>
              <a ngbNavLink class="nav-link text-muted d-flex align-items-center">
                <span class="d-none d-sm-block">Soubory</span>
                <fa-icon class="d-sm-none mx-1" [icon]="files"></fa-icon>
              </a>
              <ng-template ngbNavContent>

                <app-file-manager [model]="machine"></app-file-manager>

              </ng-template>
            </li>
            <li [ngbNavItem]=3>
              <a ngbNavLink class="nav-link text-muted d-flex align-items-center">
                <span class="d-none d-sm-block">Galerie</span>
                <fa-icon class="d-sm-none mx-1" [icon]="gallery"></fa-icon>
              </a>
              <ng-template ngbNavContent>

                <h5>Galerie</h5>
                  <ngb-carousel [interval]="5000" [pauseOnHover]="true"
                                [showNavigationArrows]="machine.gallery[0].name !== ''"
                                [showNavigationIndicators]="machine.gallery[0].name !== ''">
                    <ng-template ngbSlide>
                      <div class="picsum-img-wrapper">
                        <a class="example-image-link" href="{{machine.previewImage.src}}" data-fancybox="carousel-set"><img class="carousel-image shadow-1-strong rounded" [src]="machine.previewImage.src" alt="{{machine.previewImage.name}}"></a>
                      </div>
                    </ng-template>
                    <ng-container *ngIf="machine.gallery[0].name !== ''">
                      <ng-template ngbSlide *ngFor="let image of machine.gallery; index as i">
                        <div class="picsum-img-wrapper">
                          <a class="example-image-link" href="{{image.src}}" data-fancybox="carousel-set"><img class="carousel-image shadow-1-strong rounded" [src]="image.src" alt="{{image.name}}"></a>
                        </div>
                      </ng-template>
                    </ng-container>
                  </ngb-carousel>
                  <div class="row mt-4">
                    <div class="col-xl-2 col-lg-3 col-sm-6 col-12 mb-4 mb-lg-0">
                      <a class="example-image-link" href="{{machine.previewImage.src}}" data-fancybox="gallery-set"><img src="{{machine.previewImage.src}}" class="gallery-img w-100 shadow-1-strong rounded mb-4" alt="{{machine.previewImage.name}}"/></a>
                    </div>
                    <ng-container *ngIf="machine.gallery[0].name !== ''">
                      <div *ngFor="let image of machine.gallery" class="col-xl-2 col-lg-3 col-sm-6 col-12 mb-4 mb-lg-0">
                        <a class="example-image-link" href="{{image.src}}" data-fancybox="gallery-set"><img src="{{image.src}}" class="gallery-img w-100 shadow-1-strong rounded mb-4" alt="{{image.name}}"/></a>
                      </div>
                    </ng-container>
                  </div>
              </ng-template>
            </li>
            <li *ngIf="machine.gpsId !== ''" [ngbNavItem]=4>
              <a ngbNavLink class="nav-link text-muted d-flex align-items-center" (click)="redirectGPS()">
                <span class="d-none d-sm-block">GPS</span>
                <fa-icon class="d-sm-none mx-1" [icon]="gps"></fa-icon>
              </a>
              <ng-template ngbNavContent>
                <a href="https://www.onisystem.net/inetgweb/jsp/obj/infoobj.jsp?IDOBJ={{machine.gpsId}}" target="_blank">Otevřít GPS profil.</a>
              </ng-template>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
<div class="row profile-content-row">
  <div class="col profile-content-col">
    <div class="card mt-3 mb-5 card profile-content">
      <div class="card-body">
        <div [ngbNavOutlet]="nav"></div>
      </div>
    </div>
  </div>
</div>
<ng-template #viewTip>
  <div class="fs-7">
    Zobrazit
  </div>
</ng-template>
<ng-template #deleteTip>
  <div class="fs-7">
    Odstranit
  </div>
</ng-template>
<ng-template #editTip>
  <div class="fs-7">
    Upravit
  </div>
</ng-template>
