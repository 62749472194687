import {Component, OnInit} from '@angular/core';

import {AppUser} from "../../../../../core/interfaces/user.interface";
import {AuthService} from "../../../../../core/services/auth.service";
import {UserService} from "../../../../../core/services/user.service";
import {UtilityService} from "../../../../../core/services/utility.service";
import {faTrashCan, faKey} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-reference-place',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  userList: AppUser[] = [];
  asc = false;
  deleteIcon = faTrashCan;
  resetPasswordIcon = faKey;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private util: UtilityService
  ) {}

  ngOnInit(): void {
    this.getAllUsers();
  }

  getAllUsers(): void {
    this.userService.getAll().subscribe(result => {
      if (result.length > 0) {
        this.userList = result
      } else {
        this.userList = [];
        console.log('Žádný záznam uživatele neexistuje!!!')
      }
    })
  }
  deleteUser(id:string): void {
    this.authService.delete(id).subscribe(result => {
      this.userService.delete(id);
    });
  }
  resetPassword(email: string): void {
    this.authService.ForgotPassword(email).then();
  }
  sort(key: string) {
    const sorter = this.util.sortByKey(this.userList, key, this.asc);
    this.userList = sorter.object;
    this.asc = sorter.asc;
  }
}
