import {Injectable} from '@angular/core';
import {AppUser} from '../interfaces/user.interface';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from "rxjs";
import {UserService} from "./user.service";
import {ErrorResolutionService} from "./errorResolution.service";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public appUser: AppUser | null = null;
  public initialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private userService: UserService,
    private errorRes: ErrorResolutionService,
    private auth: AngularFireAuth,
    private router: Router,
    private http: HttpClient,
  ) {
    this.auth.user.subscribe(user => {
      if (user?.uid) {
        if (this.appUser?.id !== user.uid) {
          this.initialized.next(false);
          this.initialize(user.uid).subscribe(status => {
            if (status == 1) {
              this.initialized.next(true);
            }
          });
        }
      } else {
        this.clearUser();
      }
    });
  }

  public initialize(uid: string): Observable<number> {
    let status = 0;
    return new Observable<number>(initializer => {
      try {
        initializer.next(status);
        this.userService.getById(uid).subscribe(result => {
          this.appUser = result.data();
          if (this.appUser) {
            status = 1;
            initializer.next(status);
          }
        });
      } catch (error) {
        console.log(error);
        initializer.error(error);
        initializer.complete();
      }
    });
  }


  SignIn(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  SignOut() {
    return this.auth.signOut().then(() => {
      this.clearUser();
      this.router.navigate(['login']).then();
    });
  }

  ForgotPassword(passwordResetEmail: string) {
    return this.auth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Požadavek odesnán na email: ' + passwordResetEmail);
      })
      .catch((error) => {
        window.alert(this.errorRes.getAuthErrorReason(error.code));
        console.log(error.message);
      });
  }

  private clearUser(): void {
    this.appUser = null;
  }

  create(displayName: string, email: string, password: string): Observable<any> {
    return new Observable<any>(subscriber => {
      this.auth.idToken.subscribe({
        next: token => {
          this.http.post('/fns/users', {email: email, password, displayName: displayName,}, {headers: {Authorization: `Bearer ${token}`}}).subscribe({
            next: result => {
              subscriber.next(result);
              subscriber.complete();
            },
            error: error => {
              window.alert(error.error);
              subscriber.complete();
            }
        });
        },
        error: error => {
          subscriber.error(error);
          subscriber.complete();
        }
    });
    });
  }

  delete(id: string): Observable<any> {
    return new Observable<any>(subscriber => {
      this.auth.idToken.subscribe({
        next: token => {
          this.http.delete('/fns/users/' + id, {headers: {Authorization: `Bearer ${token}`},}).subscribe({
            next: result => {
              subscriber.next(result);
              subscriber.complete();
            },
            error: error => {
              window.alert(error.error);
              subscriber.complete();
            }
          });
        },
        error: error => {
          subscriber.error(error);
          subscriber.complete();
        }
      });
    });
  }
}
