import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {EmployeeModel} from "../interfaces/employee.model";
import {EmployeeRepositoryService} from "../repositories/employee-repository.service";

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(
    private repository: EmployeeRepositoryService,
  ) {}

  createId(): string {
    const id = this.repository.createId();
    console.log('Generuji ID ' + id + ' pro záznam zaměstnance...');
    return id;
  }

  update(employee: EmployeeModel): Observable<any> {
    console.log('Aktualizuji záznam zaměstnance ' + employee.id + '...');
    return this.repository.update(employee);
  }

  upsert(employee: EmployeeModel): Observable<any> {
    console.log('Aktualizuji/Vytvářím záznam zaměstnance ' + employee.id + '...');
    return this.repository.upsert(employee);
  }

  delete(id: string): Observable<void> {
    console.log('Mažu záznam zaměstnance ' + id + '...');
    return this.repository.deleteDocument(id);
  }

  getAll(): Observable<EmployeeModel[]> {
    console.log('Načítám záznamy všech zaměstnanců...');
    return this.repository.getAllEmployees();
  }
  getById(id: string): Observable<any> {
    console.log('Načítám záznam zaměstnance ' + id);
    return this.repository.getById(id);
  }
  getAllActive(): Observable<EmployeeModel[]> {
    return this.repository.getAllActive();
  }
}
