import {Injectable} from '@angular/core';

import {AngularFirestore, AngularFirestoreCollection, CollectionReference, DocumentReference} from '@angular/fire/compat/firestore';
import {combineLatest, from, Observable} from 'rxjs';
import {RepositoryOptionsInterface} from '../interfaces/repository-options.interface';
import firebase from 'firebase/compat/app';
import {tap} from 'rxjs/operators';
import WriteBatch = firebase.firestore.WriteBatch;

@Injectable({
  providedIn: 'root'
})
export abstract class Repository {


  converter = {
    toFirestore: this.toDb,
    fromFirestore: this.fromDb
  };
  protected abstract db: AngularFirestore;

  abstract fromDb(snapshot: any, options: any): object;

  abstract toDb(data: {}): object;

  abstract collectionName(options?: RepositoryOptionsInterface): string;


  createId(): string {
    return this.db.createId();
  }

  watchChanges(options?: RepositoryOptionsInterface): Observable<any> {
    return this.collection(options).valueChanges();
  }

  getAll(options?: RepositoryOptionsInterface): Observable<any[]> {
    let collection: AngularFirestoreCollection;
    if (!options?.query && !options?.orderBy) {
      collection = this.db.collection<any>(this.collectionWithConverter(options));
    } else {
      collection = this.db.collection<any>(this.collectionWithConverter(options), (colRef) => {
        let query;
        if (options?.query) {
          query = colRef.where(options.query[0], options.query[1], options.query[2]);
          if (options.orderBy) {
            query = query.orderBy(options.orderBy);
          }
        } else {
          query = colRef.orderBy(options.orderBy || '');
        }
        return query;
      });
    }
    return collection.valueChanges({idField: 'id'});
  }

  findById(id: string[] | string, options?: RepositoryOptionsInterface): Observable<any> {
    const observables: Observable<any>[] = [];
    id = typeof id === 'string' ? [id] : id;
    id.map(documentId => {
      if (documentId) {
        observables.push(
          this.db.collection<any>(this.collectionWithConverter(options), (colRef) => {
            return colRef.where(firebase.firestore.FieldPath.documentId(), '==', documentId);
          }).valueChanges({idField: 'id'})
        );
      }
    });
    return new Observable<any>(subscriber => {
      combineLatest(observables).subscribe({
        next: resultsArrays => {
          subscriber.next([].concat(...resultsArrays));
        },
        error: error => {
          console.log(error);
          subscriber.next(error);
        }
      });
    });
  }


  getById(id: string | string[], options?: RepositoryOptionsInterface): Observable<any> {
    if (typeof id === 'string') {
      return from(this.collectionWithConverter(options).doc(id).get());
    } else if (id.length > 0) {
      const documentRefs: DocumentReference[] = [];
      id.forEach(value => {
        documentRefs.push(this.collectionWithConverter(options).doc(value));
      });
      return new Observable<any>(subscriber => {
        Promise.all(documentRefs.map(ref => ref.get())).then(result => {
          subscriber.next({docs: result});
        });
      });
      // return from(this.collectionWithConverter(options).where('id', 'in', id).get());
    } else {
      return new Observable(subscriber => {
        subscriber.next(null);
        subscriber.complete();
        subscriber.unsubscribe();
      });
    }
  }

  getByCode(code: string, options?: RepositoryOptionsInterface): Observable<any> {
    return from(this.collectionWithConverter(options).where('code', '==', code).get());
  }

  getBy(field: string, value: string, options?: RepositoryOptionsInterface): Observable<any> {
    return from(this.collectionWithConverter(options).where(field, '==', value).get());
  }

  upsertBatch(data: any[], options?: RepositoryOptionsInterface): Observable<any> {
    const writeBatch: WriteBatch = this.db.firestore.batch();
    const result: any[] = [];
    data.forEach(doc => {
      if (!doc.id) {
        doc.id = this.db.createId();
      }
      writeBatch.set(this.collectionWithConverter(options).doc(doc.id), doc, {});
      result.push(doc);
    });
    return new Observable(subscriber => {
      writeBatch.commit().then(onFullFilled => {
        subscriber.next(result);
        subscriber.complete();
      }, onRejected => {
        console.error(onRejected);
        subscriber.next([]);
        subscriber.complete();
      });
    });
  }

  upsert(data: any, options?: RepositoryOptionsInterface, merge?: boolean): Observable<void> {
    if (!data.id) {
      data.id = this.db.createId();
    }
    return from(this.db.collection<any>(this.collectionWithConverter(options)).doc(data.id).set(data, {merge})).pipe(
      tap(_ => console.log(`UPSERT ${this.collectionName(options)} - ID ${data.id}`))
    );
  }

  insert(data: any, options?: RepositoryOptionsInterface): Observable<any> {
    if (!data.id) {
      return from(this.collectionWithConverter(options).add(data));
    }
    return new Observable(subscriber => {
      this.getById(data.id, options).subscribe(existing => {
        if (existing.data()) {
          subscriber.error(new Error(`Document id: ${data.id} already exists in collection: ${this.collectionName(options)}`));
          subscriber.complete();
        } else {
          this.update(data, options).subscribe(result => {
            subscriber.next(result);
            subscriber.complete();
          });
        }
      });
    });
  }

  update(data: any, options?: RepositoryOptionsInterface): Observable<any> {
    return new Observable(subscriber => {
        this.collectionWithConverter(options).doc(data.id).set(data).then(
          _ => this.getById(data.id, options).subscribe(result => {
            subscriber.next(result);
            subscriber.complete();
          }),
          error => {
            subscriber.error(error);
            subscriber.complete();
          }
        );
      }
    );
  }

  deleteDocument(id: string, options?: RepositoryOptionsInterface): Observable<any> {
    return from(this.collectionWithConverter(options).doc(id).delete());
  }

  deleteBatch(ids: string[], options?: RepositoryOptionsInterface): Observable<any> {
    const writeBatch: WriteBatch = this.db.firestore.batch();
    ids.forEach(id => {
      writeBatch.delete(this.collectionWithConverter(options).doc(id));
    });
    return from(writeBatch.commit());
  }

  protected collection(options?: RepositoryOptionsInterface): AngularFirestoreCollection {
    return this.db.collection(this.collectionName(options));
  }

  protected collectionWithConverter(options?: RepositoryOptionsInterface): CollectionReference {
    return this.db.firestore.collection(this.collectionName(options)).withConverter(this.converter);
  }

}
