import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../core/services/auth.service";
import {Router} from "@angular/router";
import {ErrorResolutionService} from "../../core/services/errorResolution.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CompanyInfoModel} from "../../core/interfaces/companyInfo.model";
import {DataService} from "../../core/services/data.service";

@Component({
  selector: 'app-admin-app',
  templateUrl: './login-app.component.html',
  styleUrls: ['./login-app.component.scss']
})
export class LoginAppComponent implements OnInit {
  loginForm!: FormGroup;
  emailForm!: FormGroup;
  errorMessage = '';
  passwordInputType = 'password';

  contactData = new CompanyInfoModel();
  year: number = new Date().getFullYear();

  constructor(
    private authService: AuthService,
    private errorRes: ErrorResolutionService,
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private dataService: DataService,
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.dataService.getCompanyInfo().subscribe(result => {
      this.contactData = result.data();
    });
  }

  logIn(): void {
    this.authService.SignIn(this.email(this.loginForm.get('email')?.value), this.loginForm.get('password')?.value)
      .then(() => {
        this.router.navigate(['admin/dashboard']).then();
      })
      .catch(error => {
        this.errorMessage = this.errorRes.getAuthErrorReason(error.code);
        console.log(error.message);
      });
  }

  buildForm(): void {
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
    this.emailForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  openForgotPassword(content: any): void {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      scrollable: true,
      centered: true
    }).result.then(result => {
      console.log('Formulář uzavřen pomocí:' + result);
      this.authService.ForgotPassword(this.email(this.loginForm.get('email')?.value)).then();
    }, (reason) => {
      console.log(this.errorRes.getModalDismissReason(reason));
    });
  }
  email(username: string): string {
    let user = username;
    if (!user.includes('@')) {
      user = user + '@geo-stav.cz';
    }
    return user;
  }

  togglePassword(): void {
    if (this.passwordInputType == 'text'){
      this.passwordInputType = 'password';
    } else {
      this.passwordInputType = 'text';
    }
  }
}
