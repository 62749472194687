import {Component, Input, OnInit} from '@angular/core';
import {EmployeeService} from "../../../../../../core/services/employee.service";
import {EmployeeModel} from "../../../../../../core/interfaces/employee.model";

@Component({
  selector: 'app-operator-name',
  templateUrl: './operator-name.component.html',
  styleUrls: ['./operator-name.component.scss']
})
export class OperatorNameComponent implements OnInit {
  @Input() employeeID = '';
  employeeInfo = new EmployeeModel();

  constructor(
    private employeeService: EmployeeService,
  ) { }

  ngOnInit(): void {
    if (this.employeeID) {
      this.employeeService.getById(this.employeeID).subscribe(result => {
        this.employeeInfo = result.data();
      });
    }
  }
}
