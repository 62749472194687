<button class="btn btn-sm btn-dark mx-1" (click)="showAlert(alert)"><fa-icon [icon]="icon"></fa-icon></button>
<ng-template #alert let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Smazat {{employee.lastName}} {{employee.firstName}}?</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p>Pro potvrzení poždavku napište jméno zaměstnance, kterého chcete smazat:</p>
    <form [formGroup]="nameForm">
      <div class="col input-group mb-1">
        <input
          formControlName="name"
          id="name"
          class="form-control form-control-lg"
          placeholder="{{employee.lastName}} {{employee.firstName}}"
          required>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="modal.close('Submit')" [disabled]="!nameForm.valid" type="button" class="btn btn-danger">Smazat</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('cancel')">Zrušit</button>
  </div>
</ng-template>

