import {Component, Input, OnInit} from '@angular/core';
import {ReferencePlaceModel} from '../../../../../../core/interfaces/referencePlace.model';
import {PlaceService} from '../../../../../../core/services/place.service';

@Component({
  selector: 'app-place-name',
  templateUrl: './place-name.component.html',
  styleUrls: ['./place-name.component.scss']
})
export class PlaceNameComponent implements OnInit {
  @Input() placeID = '';
  placeInfo = new ReferencePlaceModel();

  constructor(
    private placeService: PlaceService,
  ) { }

  ngOnInit(): void {
    if (this.placeID) {
      this.placeService.getById(this.placeID).subscribe(result => {
        this.placeInfo = result.data();
      });
    }
  }
}
