import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ClothesModel} from "../interfaces/clothes.model";
import {ClothesRepositoryService} from "../repositories/clothes-repository.service";

@Injectable({
  providedIn: 'root'
})
export class ClothesService {

  constructor(
    private repository: ClothesRepositoryService,
  ) {}

  createId(): string {
    const id = this.repository.createId();
    console.log('Generuji ID ' + id + ' pro záznam oblečení...');
    return id;
  }

  update(place: ClothesModel): Observable<any> {
    console.log('Aktualizuji záznam oblečení ' + place.name + '... (' + place.name + ')');
    return this.repository.update(place);
  }
  upsert(place: ClothesModel): Observable<any> {
    console.log('Aktualizuji/Vytvářím záznam oblečení ' + place.id + '...');
    return this.repository.upsert(place);
  }
  delete(id: string): Observable<void> {
    console.log('Mažu záznam oblečení ' + id + '...');
    return this.repository.deleteDocument(id);
  }

  getAll(): Observable<ClothesModel[]> {
    console.log('Načítám záznamy všeho oblečení...');
    return this.repository.getAll();
  }
  getById(id: string): Observable<any> {
    console.log('Načítám záznam oblečení ' + id);
    return this.repository.getById(id);
  }
}
