<button class="btn btn-sm btn-primary mx-1" (click)="showPlace(previewModal)"><fa-icon [icon]="icon"></fa-icon></button>
<ng-template #previewModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Náhled oblečení</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <b>Název: </b>{{clothing.name}}<br>
    <b>Odkaz: </b><a class="text-primary" href="{{clothing.link}}" target="_blank">link</a><br>
    <b>Cena s DPH: </b>{{clothing.price | number}} Kč<br>
    <b>Cena bez DPH: </b>{{clothing.price / 1.21 | number:'0.0-2'}} Kč<br>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Hotovo</button>
  </div>
</ng-template>
