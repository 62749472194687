import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {CategoryRepositoryService} from '../repositories/category-repository.service';
import {ReferenceCategoryModel} from '../interfaces/referenceCategory.model';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private repository: CategoryRepositoryService,
  ) {}

  createId(): string {
    const id = this.repository.createId();
    console.log('Generuji ID ' + id + ' pro záznam kategorie...');
    return id;
  }

  update(category: ReferenceCategoryModel): Observable<any> {
    console.log('Aktualizuji záznam kategorie ' + category.id + '...');
    return this.repository.update(category);
  }

  upsert(category: ReferenceCategoryModel): Observable<any> {
    console.log('Aktualizuji/Vytvářím záznam kategorie ' + category.id + '...');
    return this.repository.upsert(category);
  }

  delete(id: string): Observable<void> {
    console.log('Mažu záznam kategorie ' + id + '...');
    return this.repository.deleteDocument(id);
  }

  getAll(): Observable<ReferenceCategoryModel[]> {
    console.log('Načítám záznamy všech kategorií...');
    return this.repository.getAll({orderBy: 'priority', orderByDirection: 'asc'});
  }
  getById(id: string): Observable<any> {
    console.log('Načítám záznam kategorie ' + id);
    return this.repository.getById(id);
  }
  getByLink(link: string): Observable<ReferenceCategoryModel[]> {
    return this.repository.getAllCategoriesByLink(link);
  }
}
