import { Component, OnInit } from '@angular/core';

import { ReferenceCategoryModel } from 'src/app/core/interfaces/referenceCategory.model';
import {CategoryService} from 'src/app/core/services/category.service';
import {UtilityService} from "../../../../../../core/services/utility.service";
import {faEye, faImages, faNewspaper, faPlus} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-reference-category',
  templateUrl: './reference-category.component.html',
  styleUrls: ['./reference-category.component.scss']
})
export class ReferenceCategoryComponent implements OnInit {
  previewIcon = faEye;
  addReferenceIcon = faPlus;
  galleryIcon = faImages;
  classicIcon = faNewspaper;
  categoryList: ReferenceCategoryModel[] = [];
  asc = false;

  constructor(
    private categoryService: CategoryService,
    private util: UtilityService
  ) { }

  ngOnInit(): void {
    this.getAllCategories();
  }

  getAllCategories(): void {
    this.categoryService.getAll().subscribe(result => {
      if (result.length > 0) {
        this.categoryList = result;
      } else {
        this.categoryList = [];
        console.log('Žádný záznam kategorie neexistuje!!!')
      }
    });
  }
  sort(key: string) {
    const sorter = this.util.sortByKey(this.categoryList, key, this.asc);
    this.categoryList = sorter.object;
    this.asc = sorter.asc;
  }
}
