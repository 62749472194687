<div class="header">

  <!--Content before waves-->
  <div class="inner-header">
    <section class="h-100 login-background">
      <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-12 col-md-9 col-lg-7 col-xl-6 col-xxl-5">
            <div class="card rounded-3 text-black mat-elevation-z8">
              <div class="card-body p-md-5 mx-md-4">
                <div class="text-center">
                  <img class="mb-4 logo-login" src="./assets/images/logo.svg" alt="logo">
                </div>
                <form [formGroup]="loginForm">
                  <p class="mb-4">Přihlášení: <i class="text-danger" [innerText]="errorMessage"></i></p>
                  <div class="input-group mb-4">
                    <span class="input-group-text"><mat-icon>alternate_email</mat-icon></span>
                    <input formControlName="email" type="email" id="email" class="form-control form-control-lg"
                           placeholder="username@geo-satv.cz" required>
                  </div>
                  <div class="input-group mb-4">
                    <span class="input-group-text"><mat-icon>lock</mat-icon></span>
                    <input formControlName="password" type="{{passwordInputType}}" id="password"
                           class="form-control form-control-lg" placeholder="heslo" required>
                    <span class="input-group-text" (click)="togglePassword()">
                      <mat-icon *ngIf="passwordInputType === 'password'">visibility_off</mat-icon>
                      <mat-icon *ngIf="passwordInputType === 'text'">visibility</mat-icon>
                    </span>
                  </div>
                  <div class="text-center pt-1">
                    <button (click)="logIn()" class="btn btn-lg btn-gradient-custom text-white w-100" type="button">
                      Přihlásit se
                    </button>
                    <p class="mt-3 text-fp" (click)="openForgotPassword(passwordMaodal)">Zapoměli jste heslo?</p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <!--Waves Container-->
  <div>
    <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g class="parallax">
        <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
        <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
    </svg>
  </div>

</div>
<!--Header ends-->

<!--Content starts-->
<div class="content">
  <p class="text-lightmuted">©{{year}} {{contactData.name}} <span class="d-sm-inline-block d-none">|</span><br class="d-inline-block d-sm-none"> Vytvořil <a class="text-lightmuted" href="https://ales-urbanek.cz/" target="_blank">Aleš Urbánek ml.</a></p>
</div>
<!--Content ends-->

<ng-template #passwordMaodal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Zapoměli jste heslo?</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="emailForm">
      <div class="col form-floating form-group mb-3">
        <input
          formControlName="email"
          id="forgottenEmail"
          class="form-control"
          placeholder="nothing"
          required>
        <label for="forgottenEmail">Email:</label>
      </div>
    </form>
    <div class="text-end">
      <button (click)="modal.close('Submit')" [disabled]="!emailForm.valid" type="button" class="btn btn-primary w-100">
        Odeslat požadavek
      </button>
    </div>
  </div>
</ng-template>
