<button *ngIf="employee.active" class="btn btn-sm btn-danger mx-1" (click)="showAlert(alert)"><fa-icon [icon]="disableIcon"></fa-icon></button>
<button *ngIf="!employee.active" class="btn btn-sm btn-warning mx-1" (click)="showAlert(alert)"><fa-icon [icon]="enableIcon"></fa-icon></button>
<ng-template #alert let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{employee.active? 'Propustit':'Přijmout zpět'}} zaměstnance?</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p>Opravdu chcete {{employee.active? 'Propustit':'přijmout zpět'}} zaměstnance <b>{{employee.lastName}} {{employee.firstName}}</b>?</p>
    <div *ngIf="employee.active" class="col form-floating form-group mb-3">
      <input
        name="firstName"
        id="firstName"
        class="form-control form-control-sm"
        placeholder="nothing"
        required
        [(ngModel)]="fireDate"
      >
      <label for="firstName">Propustit ke dni:</label>
    </div>
  </div>
  <div class="modal-footer">
    <button *ngIf="employee.active" type="button" class="btn btn-danger" (click)="modal.close('toggle')">Propustit</button>
    <button *ngIf="!employee.active" type="button" class="btn btn-warning" (click)="modal.close('toggle')">Přijmout</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('cancel')">Zrušit</button>
  </div>
</ng-template>

