<h5>Velikosti oblečení</h5>
<div *ngIf="clothesList[0]" class="row">
  <div class="col-12">
    <div *ngFor="let clothing of clothesList">
      <app-clothing-size-input [clothing]="clothing"></app-clothing-size-input>
    </div>
  </div>
  <div class="col-12 text-center">
    <button (click)="onSubmit()" class="btn btn-gradient-custom w-100" [innerHTML]="btnText"></button>
  </div>
</div>
<ng-container *ngIf="!clothesList[0]">
  <h3 class="text-center text-danger my-5">Nebylo nalezeno žádné oblečení!</h3>
</ng-container>

