<button *ngIf="!isNew" class="btn btn-sm btn-success mx-1" (click)="openForm(previewModal)"><fa-icon [icon]="icon"></fa-icon></button>
<button *ngIf="isNew" class="btn btn-lg btn-gradient-custom" (click)="openForm(previewModal)">Přidat zaměstnance</button>
<ng-template #previewModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{isNew ? 'Vytvořit' : 'Upravit'}} zaměstnance</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-6">
          <div class="col form-floating form-group mb-3">
            <input
              formControlName="firstName"
              name="firstName"
              id="firstName"
              class="form-control"
              placeholder="nothing"
              required>
            <label for="firstName">Jméno:</label>
          </div>
        </div>
        <div class="col-6">
          <div class="col form-floating form-group mb-3">
            <input
              formControlName="lastName"
              id="lastName"
              class="form-control"
              placeholder="nothing"
              required>
            <label for="lastName">Přijmení:</label>
          </div>
        </div>
      </div>
      <div class="col form-floating form-group mb-3">
        <input
          formControlName="rc"
          id="rc"
          class="form-control"
          placeholder="nothing"
          required>
        <label for="rc">Rodné číslo:</label>
      </div>
      <div class="col form-floating form-group mb-3">
        <input
          formControlName="address"
          id="address"
          class="form-control"
          placeholder="nothing"
          required>
        <label for="address">Adresa:</label>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="col form-floating form-group mb-3">
            <input
              formControlName="tel"
              id="tel"
              (change)="onTelChange()"
              class="form-control"
              placeholder="nothing"
              required>
            <label for="tel">Tel:</label>
          </div>
        </div>
        <div class="col-6">
          <div class="col form-floating form-group mb-3">
            <input
              formControlName="email"
              id="email"
              class="form-control"
              placeholder="nothing"
              required>
            <label for="email">Email:</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="col form-floating form-group mb-3">
            <input
              formControlName="entryDate"
              id="entryDate"
              type="date"
              class="form-control"
              placeholder="nothing"
              required>
            <label for="entryDate">Datum Nástupu:</label>
          </div>
        </div>
        <div class="col-6">
          <div class="col form-floating form-group mb-3">
            <input
              formControlName="vacationHours"
              id="vacationHours"
              type="number"
              class="form-control"
              placeholder="nothing"
              required>
            <label for="vacationHours">Hodin dovolené:</label>
          </div>
        </div>
      </div>
      <div class="col form-floating form-group mb-3">
        <textarea
          formControlName="note"
          id="note"
          class="form-control"
          placeholder="nothing"
        ></textarea>
        <label for="note">Poznámka:</label>
      </div>
      <div class="row">
        <div class="col-4">
          <div class="col form-floating form-group mb-3">
            <input
              formControlName="construction"
              id="construction"
              type="number"
              class="form-control"
              placeholder="nothing"
              required>
            <label for="construction">Stavba:</label>
          </div>
        </div>
        <div class="col-4">
          <div class="col form-floating form-group mb-3">
            <input
              formControlName="workshop"
              id="workshop"
              type="number"
              class="form-control"
              placeholder="nothing"
              required>
            <label for="workshop">Dílna:</label>
          </div>
        </div>
        <div class="col-4">
          <div class="col form-floating form-group mb-3">
            <input
              formControlName="other"
              id="other"
              type="number"
              class="form-control"
              placeholder="nothing"
              required>
            <label for="other">Jiné:</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="col form-floating form-group mb-3">
            <input
              formControlName="defaultHours"
              id="defaultHours"
              type="number"
              class="form-control"
              placeholder="nothing"
              min="0"
              max="24"
              step="1"
              required>
            <label for="construction">Pracovní doba:</label>
          </div>
        </div>
        <div class="col-6">
          <div class="col form-floating form-group mb-3">
            <input
              formControlName="defaultBonus"
              id="defaultBonus"
              type="number"
              class="form-control"
              placeholder="nothing"
              required>
            <label for="construction">Výchozí odměna:</label>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button *ngIf="isNew" (click)="sendData()" [disabled]="!form.valid" type="button" class="btn btn-success">Další</button>
    <button (click)="modal.close('Save click')" [disabled]="!form.valid" type="button" class="btn btn-primary">Uložit</button>
  </div>
</ng-template>
