import { Component, OnInit } from '@angular/core';

import { toggle } from 'slidetoggle'
import {DataService} from 'src/app/core/services/data.service';
import {CompanyInfoModel} from 'src/app/core/interfaces/companyInfo.model';

@Component({
  selector: 'app-web-footer-contacts',
  templateUrl: './web-footer-contacts.component.html',
  styleUrls: ['./web-footer-contacts.component.scss']
})
export class WebFooterContactsComponent implements OnInit {
  contactData = new CompanyInfoModel();

  constructor(
    private dataService: DataService,
  ) { }

  ngOnInit(): void {
    this.dataService.getCompanyInfo().subscribe(result => {
      this.contactData = result.data();
    });
  }

  contactFormSlide(): void {
    toggle('#contactform', {miliseconds: 500});
    setTimeout((): void => {
      document.getElementById("contactform")!.scrollIntoView();
    }, 500);
  }
  slideToTop(): void{
    window.scroll(0,0);
  }
}
