import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbNav, NgbNavChangeEvent} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {faCircleInfo, faImages, faFolderOpen, faMapLocationDot} from "@fortawesome/free-solid-svg-icons";
import {MachineService} from "../../../../../../core/services/machine.service";
import {EmployeeModel} from "../../../../../../core/interfaces/employee.model";
import {MachineModel, MachineTypeEnum2LabelMapping} from "../../../../../../core/interfaces/machine.model";
import {EmployeeService} from "../../../../../../core/services/employee.service";

import { Timestamp } from 'firebase/firestore';
import {UtilityService} from "../../../../../../core/services/utility.service";
import {SetupService} from "../../../../../../core/services/setup.service";

@Component({
  selector: 'app-machine-profile',
  templateUrl: './machine-profile.component.html',
  styleUrls: ['./machine-profile.component.scss']
})
export class MachineProfileComponent implements OnInit {
  info = faCircleInfo;
  files = faFolderOpen;
  gallery = faImages;
  gps = faMapLocationDot;
  @ViewChild("nav") nav!: NgbNav;
  activeTab = 1;
  machine = new MachineModel();
  MachineTypeEnumMapping = MachineTypeEnum2LabelMapping;
  operator!: EmployeeModel;

  constructor(
    private employeeService: EmployeeService,
    private machineService: MachineService,
    private utilityService: UtilityService,
    private route: ActivatedRoute,
    private router: Router,
    private setup: SetupService,
  ) { }

  ngOnInit(): void {
    this.machineService.getById(String(this.route.snapshot.paramMap.get('id'))).subscribe(result => {
      this.machine = result.data();
      this.setup.setTitle(this.machine.manufacturer + ' ' + this.machine.model, 'adminPanel');
      if (this.machine.operator) {
        this.employeeService.getById(this.machine.operator).subscribe(result => {
          this.operator = result.data();
        });
      }
    });
    this.route.queryParamMap.subscribe(queryParamMap => {
      if (queryParamMap.get('tab')) {
        this.activeTab = +queryParamMap.get('tab')!;
      }
    });
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.router.navigate([], {
      relativeTo: this.route, queryParams: {
        tab: changeEvent.nextId
      }
    }).then();
  }
  toDate(uploadTime: Timestamp): string {
    return this.utilityService.timestampToString(uploadTime);
  }

  redirectGPS() {
    this.utilityService.openSite('https://www.onisystem.net/inetgweb/jsp/obj/infoobj.jsp?IDOBJ=' + this.machine.gpsId);
  }
}
