import {Component, OnInit} from '@angular/core';

import {ReferenceCategoryModel} from 'src/app/core/interfaces/referenceCategory.model';
import {DataService} from 'src/app/core/services/data.service';
import {CategoryService} from 'src/app/core/services/category.service';

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss']
})
export class ReferencesComponent implements OnInit {
  categories!: ReferenceCategoryModel[];
  headerL1 = 'Služby a reference';
  headerL2 = 'Dokážeme prakticky cokoliv.';
  btnType = 'contacts';

  constructor(
    private data: DataService,
    private categoryService: CategoryService,
  ) { }

  ngOnInit(): void {
    this.getCategories();
  }
  getCategories(): void {
    this.categoryService.getAll().subscribe(result => {
      this.categories = result;
    });
  }

}
