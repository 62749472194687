<h2 class="card-title text-center">Tabulka velikostí</h2>

<ng-container *ngIf="employeeList[0]">
  <div class="tableFixHead">
    <table class="table table-hover text-center">
      <thead>
      <tr>
        <th></th>
        <th *ngFor="let clothing of clothesList" scope="col" class="align-middle text-end text-vertical">
          <a href="{{clothing.link}}" target="_blank">{{clothing.name}}</a>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let employee of employeeList">
        <th scope="row" class="text-end employee-name">
          <a routerLink="/admin/employees/list/profile/{{employee.id}}" [queryParams]="{tab: 3}">{{employee.lastName}} {{employee.firstName}}</a>
        </th>
        <td *ngFor="let clothing of clothesList" class="align-middle">
          <ng-template ngFor let-size [ngForOf]="getSize(employee.id,clothing) | async">
            <a *ngIf="size.link" href="{{size.link}}" target="_blank">{{size.size}}</a>
            <a *ngIf="!size.link" href="{{clothing.link}}" target="_blank">{{size.size}}</a>
          </ng-template>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</ng-container>
<ng-container *ngIf="!employeeList[0]">
  <h3 class="text-center text-danger my-5">Nebyli nalezeni žádní zaměstnanci!</h3>
</ng-container>
<button class="btn btn-lg btn-gradient-custom w-100" routerLink="list">Spravovat oblečení</button>
