import { Injectable } from '@angular/core';
import * as moment from "moment";
import { Timestamp } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() {}

  sortByKey(obj: any, key: string, asc: boolean): { object: any, asc: boolean } {
    if (asc) {
      // @ts-ignore
      obj.sort((a, b) => {
        if (!a[key]) {return +1;}
        if (!b[key]) {return -1;}
        return a[key].localeCompare(b[key])
      });
    } else {
      // @ts-ignore
      obj.sort((a, b) => {
        if (!a[key]) {return +1;}
        if (!b[key]) {return -1;}
        return b[key].localeCompare(a[key])
      });
    }
    return {object:obj, asc: !asc};
  }
  timestampToString(timestamp: Timestamp, format: string = 'DD.MM.YYYY'): string {
    return moment(new Date(timestamp.seconds * 1000)).format(format)
  }
  openSite(src: string, newTab = true) {
    window.open(src, newTab?'_blank':'_self');
  }
}
