<div class="row">
  <div class="col-xl-4 col-lg-5">
    <div class="card mb-4">
      <div class="card-body text-center">
        <img
          src="https://www.doesport.co.uk/wp-content/uploads/2017/11/profile-icon-male-avatar-portrait-casual-person-silhouette-face-flat-design-vector-illustration-58249394.jpg"
          alt="avatar"
          class="rounded-circle img-fluid profile-img">
        <h5 class="my-3 {{employee.active? '':'text-danger'}}">{{employee.lastName}} {{employee.firstName}}</h5>
        <p class="mb-1 {{employee.active? 'text-muted':'text-danger'}}">{{convertTimestemp(employee.entryDate)}}{{employee.active? '':' - ' + convertTimestemp(employee.fireDate)}}</p>
        <p class="mb-4"><a href="mailto:{{employee.email}}"
                           class="{{employee.active? 'text-muted':'text-danger'}}">{{employee.email}}</a></p>
        <div class="d-flex justify-content-center mb-2">
          <app-employee-form *ngIf="employee.id" [employee]="employee" [ngbTooltip]="editTip"></app-employee-form>
          <app-employee-deactivate [employee]="employee" [ngbTooltip]="fireTip"></app-employee-deactivate>
          <app-employee-delete [employee]="employee" [ngbTooltip]="deleteTip"></app-employee-delete>
        </div>
      </div>
    </div>
    <div class="card mb-4  mb-lg-0">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-4 text-sm-end text-start">
            <p class="mb-0 text-nowrap">Celé jméno:</p>
          </div>
          <div class="col-sm-8">
            <p
              class="{{employee.active? 'text-muted':'text-danger'}} mb-0">{{employee.firstName}} {{employee.lastName}}</p>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-4 text-sm-end text-start">
            <p class="mb-0">Email:</p>
          </div>
          <div class="col-sm-8">
            <p class="mb-0"><a href="mailto:{{employee.email}}" class="{{employee.active? 'text-muted':'text-danger'}}">{{employee.email}}</a></p>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-4 text-sm-end text-start">
            <p class="mb-0">Tel:</p>
          </div>
          <div class="col-sm-8">
            <p class="{{employee.active? 'text-muted':'text-danger'}} mb-0"><a href="tel:+420 {{employee.tel}}" class="{{employee.active? 'text-muted':'text-danger'}}">+420 {{employee.tel}}</a></p>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-4 text-sm-end text-start">
            <p class="mb-0">Adresa:</p>
          </div>
          <div class="col-sm-8">
            <p class="{{employee.active? 'text-muted':'text-danger'}} mb-0">{{employee.address}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-8 col-lg-7">
    <div class="row h-100">
      <div class="col-12">
        <ul ngbNav #nav="ngbNav" [activeId]=activeTab (navChange)="onNavChange($event)" class="nav-tabs justify-content-center justify-content-sm-start">
          <li [ngbNavItem]=1>
            <a ngbNavLink class="nav-link text-muted d-flex align-items-center">
              <span class="d-none d-xl-block">Informace</span>
              <fa-icon class="d-xl-none mx-1" [icon]="info"></fa-icon>
            </a>
            <ng-template ngbNavContent>

              <h5>Informace</h5>
              <div class="row">
                <div class="col-xl-6">
                  <table class="table table-borderless">
                    <tbody>
                    <tr class="text-nowrap">
                      <td class="text-end">Celé jméno:</td>
                      <td class="fw-bold fst-italic">{{employee.lastName}} {{employee.firstName}}</td>
                    </tr>
                    <tr class="text-nowrap">
                      <td class="text-end">Rodné číslo:</td>
                      <td class="fw-bold fst-italic">{{employee.rc}}</td>
                    </tr>
                    <tr>
                      <td class="text-end">Adresa:</td>
                      <td class="fw-bold fst-italic">{{employee.address}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-xl-6">
                  <table class="table table-borderless text-nowrap">
                    <tbody>
                    <tr>
                      <td class="text-end">Telefon:</td>
                      <td class="fw-bold fst-italic"><a href="tel:+420 {{employee.tel}}">+420 {{employee.tel}}</a></td>
                    </tr>
                    <tr>
                      <td class="text-end">Email:</td>
                      <td class="fw-bold fst-italic"><a href="mailto:{{employee.email}}">{{employee.email}}</a></td>
                    </tr>
                    <tr>
                      <td class="text-end">Status:</td>
                      <td class="fw-bold fst-italic {{employee.active? 'text-success':'text-danger'}}">{{employee.active? 'Aktivní od ' + convertTimestemp(employee.entryDate):'Propuštěn ' + convertTimestemp(employee.fireDate)}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div *ngIf="employee.note" class="row">
                <div class="col note">
                  <hr class="mt-0">
                  <h5>Poznámka</h5>
                  <p class="mx-4">{{employee.note}}</p>
                </div>
              </div>

            </ng-template>
          </li>
          <li [ngbNavItem]=2>
            <a ngbNavLink class="nav-link text-muted d-flex align-items-center">
              <span class="d-none d-xl-block">Docházka</span>
              <fa-icon class="d-xl-none mx-1" [icon]="attendance"></fa-icon>
            </a>
            <ng-template ngbNavContent>

              <app-employee-attendance></app-employee-attendance>

            </ng-template>
          </li>
          <li [ngbNavItem]=3>
            <a ngbNavLink class="nav-link text-muted d-flex align-items-center">
              <span class="d-none d-xl-block">Oblečení</span>
              <fa-icon class="d-xl-none mx-1" [icon]="clothing"></fa-icon>
            </a>
            <ng-template ngbNavContent>

              <app-clothing-sizes></app-clothing-sizes>

            </ng-template>
          </li>
          <li [ngbNavItem]=4>
            <a ngbNavLink class="nav-link text-muted d-flex align-items-center">
              <span class="d-none d-xl-block">Soubory</span>
              <fa-icon class="d-xl-none mx-1" [icon]="files"></fa-icon>
            </a>
            <ng-template ngbNavContent>

              <app-file-manager [model]="employee"></app-file-manager>

            </ng-template>
          </li>
        </ul>
      </div>
      <div class="col-12 profile-content-col">
        <div class="card border-top-0 profile-content">
          <div class="card-body">
            <div [ngbNavOutlet]="nav"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #fireTip>
  <div class="fs-7">
    Propustit
  </div>
</ng-template>
<ng-template #deleteTip>
  <div class="fs-7">
    Odstranit
  </div>
</ng-template>
<ng-template #editTip>
  <div class="fs-7">
    Upravit
  </div>
</ng-template>
