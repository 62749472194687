<app-site-header
  [headerLine1]="headerL1"
  [headerLine2]="headerL2"
  [btnType]="btnType"
></app-site-header>
<main>
  <div class="row">
    <div class="col-12 text-center bg-white shadow-lg">
      <div class="row mt-5">
        <h1>Stránka nebyla nalezena.</h1>
      </div>
      <div class="row">
        <div class="col-12 bg-white content-box">
          <br />
          <br />
        </div>
      </div>
    </div>
  </div>
</main>
