import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {EmployeeService} from "../../../../../../core/services/employee.service";
import {EmployeeModel} from "../../../../../../core/interfaces/employee.model";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {DailyAttendanceInputComponent} from "./daily-attendance-input/daily-attendance-input.component";
import {MonthlyAttendance} from "../../../../../../core/interfaces/employeeAttendance.model";

import {AttendanceService} from "../../../../../../core/services/attendance.service";
import {ExcelService} from "../../../../../../core/services/excel.service";
import {UtilityService} from "../../../../../../core/services/utility.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ErrorResolutionService} from "../../../../../../core/services/errorResolution.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-employee-clothing-list',
  templateUrl: './employee-attendance.component.html',
  styleUrls: ['./employee-attendance.component.scss']
})
export class EmployeeAttendanceComponent implements OnInit {
  selectionForm!: FormGroup;
  employeeList: EmployeeModel[] = [];

  selectedEmployee = new EmployeeModel();
  attendance = new MonthlyAttendance()

  @ViewChildren(DailyAttendanceInputComponent) inputComponents = new QueryList<DailyAttendanceInputComponent>();
  employeeBackUp = new EmployeeModel();
  vacationForm!: FormGroup;
  @ViewChild('previewModal') vacationModal!: ElementRef;

  year = '';
  month = '';
  daysOfMonth!: { date: string, name: string }[];
  currentMonth = new Date().getFullYear().toString() + '-' + ('0' + (new Date().getMonth() + 1)).toString().slice(-2);
  currentPreviousMonth = new Date().getFullYear().toString() + '-' + ('0' + (new Date().getMonth())).toString().slice(-2);
  intervalMin = new Date().getFullYear().toString() + '-' + ('0' + (new Date().getMonth())).toString().slice(-2);

  vacationModalCaption = ''
  btnText = 'Odeslat';
  btnDelText = 'Smazat';

  constructor(
    private excelService: ExcelService,
    private service: AttendanceService,
    private employeeService: EmployeeService,
    private utilityService: UtilityService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private errorRes: ErrorResolutionService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    if (this.currentPreviousMonth.slice(-2) == '00') {
      let year = new Date().getFullYear() - 1;
      let month = 12;
      this.currentPreviousMonth = year + '-' + month;
    }
    this.getAllEmployees();
    this.buildForm();
    this.getDaysArray();
  }

  onSubmit(send = true): void {
    let inputs: DailyAttendanceInputComponent[] = this.inputComponents.toArray();
    this.attendance.salary = 0;
    this.attendance.workHours = 0;
    this.attendance.sickHours = 0;
    this.attendance.vacationHoursDuring = 0;

    this.attendance.days = [];
    inputs.forEach(input => {
      this.attendance.salary += input.salary;
      if (input.place !== 'Dovolená' && input.place !== 'Nemoc' && input.place !== 'Svátek') {
        this.attendance.workHours += input.hours;
      } else if (input.place == 'Dovolená') {
        this.attendance.vacationHoursDuring += input.hours;
      } else if (input.place == 'Nemoc') {
        this.attendance.sickHours += input.hours;
      }
      this.attendance.days.push({
        date: input.day.date,
        hours: input.hours,
        activity: input.activity,
        place: input.place,
        multiplier: input.multiplier,
      });
    });
    if (send) {
      this.attendance.id = this.year + '-' + this.month;
      this.service.upsert(this.attendance, this.selectedEmployee.id);
      this.btnText = 'Hotovo'
      setTimeout((): void => {
        this.btnText = 'Odeslat'
      }, 750);
    }
  }

  onDelete(): void {
    this.service.delete(this.attendance.id, this.selectedEmployee.id);
    this.loadThisMonth();
    this.loadPreviousMonth();
    this.btnDelText = 'Hotovo'
    setTimeout((): void => {
      this.btnDelText = 'Smazat'
    }, 750);
  }

  loadPreviousMonth(callModal = true): void {
    const previousMonth = this.year + '-' + ('0' + (+this.month - 1)).toString().slice(-2)
    this.service.getByDateForEmployee(previousMonth, this.selectedEmployee.id).subscribe(result => {
      const month = result.data();
      if (month && previousMonth.slice(-2) !== '00') {
        this.attendance.vacationHoursAtStart = month.vacationHoursAtStart - month.vacationHoursDuring;
      } else {
        this.attendance.vacationHoursAtStart = this.selectedEmployee.vacationHours;
        if (callModal) {
          this.openVacationForm();
        }
      }
    });
  }

  loadThisMonth(): void {
    this.attendance = new MonthlyAttendance();
    this.service.getByDateForEmployee(this.year + '-' + this.month, this.selectedEmployee.id).subscribe(result => {
      const month: MonthlyAttendance = result.data();
      if (month) {
        this.attendance = month;
        this.selectedEmployee.salary = month.currentSalary;
      } else {
        this.attendance.vacationHoursAtStart = this.selectedEmployee.vacationHours;
        this.attendance.currentSalary = this.selectedEmployee.salary;
        this.attendance.salaryBonus = this.selectedEmployee.defaultBonus;
      }
    });
  }

  getAllEmployees(): void {
    this.employeeService.getAllActive().subscribe(result => {
      if (result.length > 0) {
        this.employeeList = result;
        this.route.queryParamMap.subscribe(queryParamMap => {
          const empId = queryParamMap.get('empId');
          if (empId != null && this.findEmployeeById(empId) != undefined){
            this.selectedEmployee = this.findEmployeeById(empId)!;
          } else {
            this.selectedEmployee = this.employeeList[0];
          }
        });
        this.intervalMin = this.utilityService.timestampToString(this.selectedEmployee.entryDate, 'YYYY-MM');
        this.selectionForm.get('employee')?.setValue(this.selectedEmployee.id);
        this.router.navigate([], {
          relativeTo: this.route, queryParams: {
            empId: this.selectedEmployee.id,
          }
        }).then();
        this.employeeBackUp = Object.assign({}, this.selectedEmployee);
        this.loadThisMonth();
        this.loadPreviousMonth(this.employeeList.length === 0);
      } else {
        this.employeeList = [];
        console.log('Žádný záznam zaměstnanců neexistuje!!!');
      }
    });
  }

  buildForm(): void {
    this.selectionForm = this.formBuilder.group({
      month: new FormControl(this.currentPreviousMonth || '', [Validators.required]),
      employee: new FormControl('', [Validators.required]),
    });
  }

  getDaysArray(): void {
    const yearMonth = this.selectionForm.get('month')?.value;
    this.month = yearMonth.slice(-2);
    this.year = yearMonth.substring(0, 4);

    let numDaysInMonth: number[] = [];
    if(+this.year % 4 == 0) {
      numDaysInMonth = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    } else {
      numDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    }
    let daysInWeek = ['NE', 'PO', 'ÚT', 'ST', 'ČT', 'PÁ', 'SO'];
    let dayIndex = new Date(+this.year, +this.month - 1).getDay();
    let daysArray = [];
    let date = 1;

    for (let i = 0; i < numDaysInMonth[+this.month - 1]; i++) {
      daysArray.push({date: ('0' + date).slice(-2) + '.' + this.month + '.' + this.year, name: daysInWeek[dayIndex]});
      if (dayIndex == 6) {
        dayIndex = 0;
      } else {
        dayIndex++;
      }
      date++;
    }
    this.daysOfMonth = daysArray;
    if (this.selectedEmployee.id) {
      this.loadThisMonth();
      this.loadPreviousMonth();
    }
  }

  changeEmployee(action: string): void {
    let index = this.employeeList.indexOf(this.employeeList.filter(emp => {
      return emp.id == this.selectionForm.get('employee')?.value
    })[0]);
    const lenght = this.employeeList.length;
    if (action == "previous") {
      if (index == 0) {
        index = lenght - 1;
      } else {
        index -= 1;
      }
    } else if (action == "next") {
      if (index == lenght - 1) {
        index = 0;
      } else {
        index += 1;
      }
    }
    this.selectionForm.get('employee')?.setValue(this.employeeList[index].id);
    this.selectedEmployee = this.employeeList[index];
    this.employeeBackUp = Object.assign({}, this.selectedEmployee);
    this.intervalMin = this.utilityService.timestampToString(this.selectedEmployee.entryDate, 'YYYY-MM');
    this.loadThisMonth();
    this.loadPreviousMonth();
    this.router.navigate([], {
      relativeTo: this.route, queryParams: {
        empId: this.selectedEmployee.id,
      }
    }).then();
  }

  export(): void {
    if (this.currentMonth == (this.year + '-' + this.month) || this.currentPreviousMonth == (this.year + '-' + this.month)) {
      console.log('ukládám')
      this.onSubmit();
    }
    this.excelService.saveAsExcel(this.selectedEmployee, this.attendance);
  }

  checkNewYear(): boolean {
    return this.month.slice(-2) === '01';
  }

  buildVacationForm(): void {
    this.vacationForm = this.formBuilder.group({
      vacationHours: new FormControl(this.selectedEmployee.vacationHours || 0, [Validators.required]),
    });
  }

  resetVacationForm(sent: boolean): void {
    if (!sent) {
      this.selectedEmployee = Object.assign({}, this.employeeBackUp);
    }
    this.buildVacationForm();
  }

  openVacationForm(): void {
    if (this.attendance.id === '') {
      this.buildVacationForm();
      if (this.month.slice(-2) === '01') {
        this.vacationModalCaption = '<span class="text-success">Je nový rok!</span></br>Nastavte kolik hodin dovolené má <b>' + this.selectedEmployee.lastName + ' ' + this.selectedEmployee.firstName + '</b> na tento rok.'
      } else {
        this.vacationModalCaption = '<span class="text-danger">Nenašli jsem záznam z minulého měsíce!</span></br>Nastavte kolik má <b>' + this.selectedEmployee.lastName + ' ' + this.selectedEmployee.firstName + '</b> hodin dovolené.'
      }
      this.modalService.open(this.vacationModal, {
        ariaLabelledBy: 'modal-basic-title',
        scrollable: true,
        centered: true
      }).result.then(result => {
        this.selectedEmployee.vacationHours = this.vacationForm.get('vacationHours')?.value;
        this.employeeService.upsert(this.selectedEmployee);
        this.onSubmit();
        this.resetVacationForm(true);
        console.log('Formulář uzavřen pomocí:' + result);
      }, (reason) => {
        this.resetVacationForm(false);
        console.log(this.errorRes.getModalDismissReason(reason));
      });
    }
  }

  navigateToOverview() {
    this.router.navigate(['/admin/employees/attendance/overview'], {
      relativeTo: this.route, queryParams: {
        month: this.selectionForm.get('month')?.value
      }
    }).then();
  }

  findEmployeeById(id: string): EmployeeModel | undefined {
    return this.employeeList.find(employee => employee.id === id);
  }
}
