import { Injectable } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class SetupService {
  private companyName = 'GEO-STAV';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {}

  setTitle(title: string = '', siteType: string = ''): void {
    if (title != '') {
      if (siteType === 'adminPanel') {
        this.titleService.setTitle('AdminPanel | ' + title);
      } else {
        this.titleService.setTitle(this.companyName + ' | ' + title);
      }
    } else {
      this.titleService.setTitle(this.companyName);
    }
  }
}
