import { Injectable } from '@angular/core';
import {Repository} from './repository.abstract';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {RepositoryOptionsInterface} from '../interfaces/repository-options.interface';
import {AppUser} from "../interfaces/user.interface";

@Injectable({
  providedIn: 'root'
})
export class UserRepositoryService extends Repository {
  constructor(protected db: AngularFirestore) {
    super();
  }

  collectionName(options?: RepositoryOptionsInterface): string {
    return 'Users';
  }

  fromDb(snapshot: any, options: any): AppUser {
    const data = snapshot.data(options);
    const result = new AppUser();
    Object.assign(result, data);
    result.id = data.id || snapshot.id;

    return result;
  }

  toDb(data: AppUser): object {
    return JSON.parse(JSON.stringify(data));
  }
}

