import {SalaryModel} from "./employee.model";

export enum AttendancePlaceEnum {
  construction = 'Stavba',
  workshop = 'Dílna',
  other = 'Jiné',
  vacation = 'Dovolená',
  sick = 'Nemoc',
  weekend = 'Víkend',
  publicHoliday = 'Svátek'
}

class DailyAttendance {
  constructor() {
    this.place = AttendancePlaceEnum.construction;
  }
  date = '';
  hours = 0;
  activity = '';
  place: AttendancePlaceEnum;
  multiplier = 1;
}

export class MonthlyAttendance {
  id = '';
  vacationHoursAtStart = 0;
  vacationHoursDuring = 0;
  salaryBonus = 5000;
  days = [new DailyAttendance()];
  currentSalary = new SalaryModel();
  salary = 0;
  workHours = 0;
  sickHours = 0;
}
