<h2 class="card-title text-center">Uživatelé</h2>
<hr class="d-block d-sm-none">
<div class="tableFixHead">
<table class="table table-hover text-center text-sm-start">
  <thead>
  <tr>
    <th scope="col" class="d-none d-lg-table-cell">#</th>
    <th scope="col" class="d-none d-sm-table-cell sorter" (click)="sort('displayName')">Jméno<mat-icon>sort</mat-icon></th>
    <th scope="col" class="d-none d-md-table-cell">Email</th>
    <th scope="col" class="d-none d-sm-table-cell">Operace</th>
  </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="userList[0]">
      <tr *ngFor="let user of userList; index as i">
        <th scope="row" class="d-none d-lg-table-cell align-middle">{{ i + 1 }}</th>
        <td class="text-nowrap d-none d-sm-table-cell align-middle">
          <app-name-hover-id [object]="user" ></app-name-hover-id><br class="d-table-cell d-md-none">
          <a href="mailto:{{user.email}}" class="d-table-cell d-md-none">{{user.email}}</a>
        </td>
        <td class="text-nowrap d-none d-md-table-cell align-middle"><a href="mailto:{{user.email}}">{{user.email}}</a></td>
        <td class="text-nowrap align-middle">
          <p class="d-sm-none justify-content-around">
            <b>{{user.displayName}}</b><br>
            <a href="mailto:{{user.email}}">{{user.email}}</a>
          </p>
          <div class="mt-2 mt-md-auto">
            <button class="btn btn-sm btn-warning mx-1" (click)="resetPassword(user.email)" [ngbTooltip]="resetTip"><fa-icon [icon]="resetPasswordIcon"></fa-icon></button>
            <app-user-form [user]="user" [ngbTooltip]="editTip"></app-user-form>
            <button class="btn btn-sm btn-danger mx-1" (click)="deleteUser(user.id)" [ngbTooltip]="deleteTip"><fa-icon [icon]="deleteIcon"></fa-icon></button>
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
</div>
<app-user-form class="d-grid"></app-user-form>
<ng-template #resetTip>
  <div class="fs-7">
    Resetovat heslo
  </div>
</ng-template>
<ng-template #deleteTip>
  <div class="fs-7">
    Odstranit
  </div>
</ng-template>

<ng-template #editTip>
  <div class="fs-7">
    Upravit
  </div>
</ng-template>
