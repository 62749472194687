import { Component, OnInit } from '@angular/core';

import { ReferenceModel } from 'src/app/core/interfaces/reference.model';
import {ReferenceService} from 'src/app/core/services/reference.service';
import {UtilityService} from "../../../../../../core/services/utility.service";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {ActivatedRoute} from "@angular/router";
import {CategoryService} from "../../../../../../core/services/category.service";
import {ReferenceCategoryModel} from "../../../../../../core/interfaces/referenceCategory.model";

@Component({
  selector: 'app-reference-list',
  templateUrl: './reference-list.component.html',
  styleUrls: ['./reference-list.component.scss']
})
export class ReferenceListComponent implements OnInit {
  referenceList: ReferenceModel[] = [];
  asc = false;
  previewIcon = faEye;

  category = new ReferenceCategoryModel();

  constructor(
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private referenceService: ReferenceService,
    private util: UtilityService
  ) { }

  ngOnInit(): void {
    this.getAllReferences();
  }
  getAllReferences(): void {

    // Vytáhne požadovanou kategorii z URL
    const link = this.route.snapshot.paramMap.get('link');
    // Vyhledá v databázi data pro požadovanou kategorii
    if(link === null){
      this.referenceService.getAll().subscribe(result => {
        if (result.length > 0) {
          this.referenceList = result;
          this.referenceList.sort((a,b) => b.dateTo.seconds-a.dateTo.seconds);
        } else {
          this.referenceList = []
          console.log('Žádný záznam reference neexistuje!!!')
        }
      });
    } else {
      this.categoryService.getByLink(link).subscribe(categoryData => {
        if (categoryData[0]) {
          this.category = categoryData[0];
          this.referenceService.getAllByCategory(this.category.id).subscribe(result => {
            if (result.length > 0) {
              this.referenceList = result;
              this.referenceList.sort((a, b) => b.dateTo.seconds - a.dateTo.seconds);
            } else {
              this.referenceList = []
              console.log('Žádný záznam reference neexistuje!!!')
            }
          });
        }
      });
    }
  }
  sort(key: string) {
    const sorter = this.util.sortByKey(this.referenceList, key, this.asc);
    this.referenceList = sorter.object;
    this.asc = sorter.asc;
  }
}
