import { Injectable } from '@angular/core';
import {Repository} from './repository.abstract';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {RepositoryOptionsInterface} from '../interfaces/repository-options.interface';
import {ReferenceCategoryModel} from '../interfaces/referenceCategory.model';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CategoryRepositoryService extends Repository {
  constructor(protected db: AngularFirestore) {
    super();
  }

  collectionName(options?: RepositoryOptionsInterface): string {
    return 'ReferenceCategories';
  }

  fromDb(snapshot: any, options: any): ReferenceCategoryModel {
    const data = snapshot.data(options);
    const result = new ReferenceCategoryModel();
    Object.assign(result, data);
    result.id = data.id || snapshot.id;

    return result;
  }

  toDb(data: ReferenceCategoryModel): object {
    return JSON.parse(JSON.stringify(data));
  }
  getAllCategoriesByLink(link: string, options?: RepositoryOptionsInterface): Observable<ReferenceCategoryModel[]> {
    const result = this.db.collection<ReferenceCategoryModel>(this.collectionWithConverter(options), (colRef) => {
      return colRef.where('link', '==', link)
    });
    return result.valueChanges({idField: 'id'});
  }
}

