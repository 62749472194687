<button *ngIf="!isNew" class="btn btn-sm btn-success mx-1" (click)="openForm(previewModal)"><fa-icon [icon]="icon"></fa-icon></button>
<button *ngIf="isNew" class="btn btn-lg btn-gradient-custom" (click)="openForm(previewModal)">Přidat místo</button>
<ng-template #previewModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{isNew ? 'Vytvořit' : 'Upravit'}} místo</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="placeForm">
      <div class="col form-floating form-group mb-3">
        <input
          formControlName="name"
          id="placeName"
          class="form-control"
          placeholder="nothing"
          required>
        <label for="placeName">Název:</label>
      </div>
      <div class="col form-floating form-group mb-3">
        <input
          formControlName="link"
          id="placeLink"
          class="form-control"
          placeholder="nothing"
          required>
        <label for="placeLink">Odkaz:</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button *ngIf="isNew" (click)="sendData()" [disabled]="!placeForm.valid" type="button" class="btn btn-success">Další</button>
    <button (click)="modal.close('Save click')" [disabled]="!placeForm.valid" type="button" class="btn btn-primary">Uložit</button>
  </div>
</ng-template>
