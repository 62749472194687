<div class="d-flex justify-content-between">
  <h2 class=" my-4">Máte dotaz? Napište nám!</h2>
  <fa-icon (click)="contactFormSlide()" class="close fs-2" [icon]="x"></fa-icon>
</div>
<form [formGroup]="contactForm" (ngSubmit)="onSubmit($event)" action="https://mailthis.to/webmaster@geo-stav.cz" id="contactformform" class="needs-validation">
  <div class="row">
    <div class="col-sm-6 col-12 form-floating form-group">
      <input formControlName="fname" name="fname" type="text" required id="name" class="form-control" placeholder="Aleš">
      <label class="mx-4" for="name"><sup>*</sup>Jméno:</label>
    </div>
    <div class="col-sm-6 col-12 form-floating form-group mt-sm-0 mt-4">
      <input formControlName="lname" name="lname" type="text" required id="lastName" class="form-control" placeholder="Urbánek">
      <label class="mx-4" for="lastName"><sup>*</sup>Příjmení:</label>
    </div>
  </div>
  <div class="col form-floating form-group mt-4">
    <input formControlName="email" name="email" type="email" required id="email" class="form-control" placeholder="urbanek@geo-stav.cz">
    <label class="mx-2" for="email"><sup>*</sup>Email:</label>
  </div>
  <div class="col form-floating form-group mt-4">
    <input formControlName="tel" (change)="onTelChange()" name="tel" type="tel" id="tel" class="form-control" placeholder="{{contactData.phoneNumber}}" pattern="\d*|[0-9]{3} [0-9]{3} [0-9]{3}">
    <label class="mx-2" for="tel">Tel: <sup>({{contactData.phoneNumber}})</sup></label>
  </div>
  <div class="col form-floating form-group mt-4">
    <textarea formControlName="message" name="message" required class="form-control messageTextArea" placeholder="Leave a comment here" id="floatingTextarea2"></textarea>
    <label for="floatingTextarea2"><sup>*</sup>Zpráva:</label>
  </div>
  <div class="mt-3 form-check  form-group">
    <input formControlName="tos" type="checkbox" class="form-check-input" required>
    <label class="form-check-label tooltipX">
      <sup>*</sup>Souhlasím se zpracováním zadaných údajů
      <span class="tooltiptextX text-nowrap shadow">Údaje budou zpracovány za účelem odpovědi na váš dotaz a umožnění budoucí komunikace.</span>
    </label>
  </div>
  <button [innerHTML]="submitButtonText" type="submit" class="btn btn-sm btn-primary mt-3 mb-4"></button>
<!--  [disabled]="disabled || !contactForm.valid"-->
</form>
