import {Component, OnInit} from '@angular/core';
import {CompanyInfoModel} from '../../../../../../core/interfaces/companyInfo.model';
import {DataService} from '../../../../../../core/services/data.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss']
})
export class CompanyInfoComponent implements OnInit {
  data = new CompanyInfoModel();
  companyForm!: FormGroup;
  btnText = 'Aktualizovat';

  constructor(
    private dataService: DataService,
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.dataService.getCompanyInfo().subscribe(result => {
      if (result.data()) {
        this.data = result.data();
        this.companyForm.get('name')?.setValue(this.data.name);
        this.companyForm.get('in')?.setValue(this.data.in);
        this.companyForm.get('taxNumber')?.setValue(this.data.taxNumber);
        this.companyForm.get('dataBox')?.setValue(this.data.dataBox);
        this.companyForm.get('address1')?.setValue(this.data.address1);
        this.companyForm.get('address2')?.setValue(this.data.address2);
        this.companyForm.get('email')?.setValue(this.data.email);
        this.companyForm.get('phoneNumber')?.setValue(this.data.phoneNumber);
      } else {
        console.log('Žádný záznam informací o společnosti neexistuje!!!');
      }
    });
  }

  buildForm(): void {
    this.companyForm = this.formBuilder.group({
      name: new FormControl(this.data.name || '', [Validators.required]),
      in: new FormControl(this.data.in || '', [Validators.required]),
      taxNumber: new FormControl(this.data.taxNumber || '', [Validators.required]),
      dataBox: new FormControl(this.data.dataBox || '', [Validators.required]),
      address1: new FormControl(this.data.address1 || '', [Validators.required]),
      address2: new FormControl(this.data.address2 || '', [Validators.required]),
      email: new FormControl(this.data.email || '', [Validators.required, Validators.email]),
      phoneNumber: new FormControl(this.data.phoneNumber || '', [Validators.required]),
    });
  }
  resetForm(): void {
    this.data = new CompanyInfoModel();
    this.ngOnInit();
  }
  onTelChange(): void {
    const tel = this.companyForm.get('phoneNumber')?.value.replace(/\s/g, '');
    this.companyForm.get('phoneNumber')?.setValue(tel.slice(0, 3) + ' ' + tel.slice(3, 6) + ' ' + tel.slice(6));
  }
  onInChange(): void {
    const trimmedIn = this.companyForm.get('in')?.value.replace(/\s/g, '');
    this.companyForm.get('in')?.setValue(trimmedIn.slice(0, 3) + ' ' + trimmedIn.slice(3, 5) + ' ' + trimmedIn.slice(5));
    this.companyForm.get('taxNumber')?.setValue('CZ ' + trimmedIn);
  }
  onSubmit(): void {
    this.data.id = 'CompanyInfo';
    this.data.name = this.companyForm.get('name')?.value;
    this.data.in = this.companyForm.get('in')?.value;
    this.data.taxNumber = this.companyForm.get('taxNumber')?.value;
    this.data.dataBox = this.companyForm.get('dataBox')?.value;
    this.data.address1 = this.companyForm.get('address1')?.value;
    this.data.address2 = this.companyForm.get('address2')?.value;
    this.data.email = this.companyForm.get('email')?.value;
    this.data.phoneNumber = this.companyForm.get('phoneNumber')?.value;
    this.dataService.updateCompanyInfo(this.data);
    this.btnText = 'Hotovo'
    setTimeout((): void => {this.btnText = 'Aktualizovat'},500);
    this.resetForm();
  }
}
