import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import { Timestamp } from 'firebase/firestore';

import {ErrorResolutionService} from 'src/app/core/services/errorResolution.service';
import {EmployeeModel} from "../../../../../../../core/interfaces/employee.model";
import {EmployeeService} from "../../../../../../../core/services/employee.service";
import {UtilityService} from "../../../../../../../core/services/utility.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-employee-form',
  templateUrl: './employee-form.component.html',
  styleUrls: ['./employee-form.component.scss']
})
export class EmployeeFormComponent implements OnInit {
  @Input() employee = new EmployeeModel();
  employeeBackUp = new EmployeeModel();
  form!: FormGroup;
  icon = faPenToSquare;
  isNew = true;

  constructor(
    private employeeService: EmployeeService,
    private utilityService: UtilityService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private errorRes: ErrorResolutionService,
  ) { }

  ngOnInit(): void {
    if (this.employee.id !== '') {
      this.isNew = false;
    }
    this.employeeBackUp = Object.assign({}, this.employee);
    this.buildForm();
  }

  buildForm(): void {
    this.form = this.formBuilder.group({
      firstName: new FormControl(this.employee.firstName || '', [Validators.required]),
      lastName: new FormControl(this.employee.lastName || '', [Validators.required]),
      rc: new FormControl(this.employee.rc || '', [Validators.required]),
      address: new FormControl(this.employee.address || '', [Validators.required]),
      tel: new FormControl(this.employee.tel || '', [Validators.required]),
      email: new FormControl(this.employee.email || '', [Validators.required, Validators.email]),
      entryDate: new FormControl(this.utilityService.timestampToString(this.employee.entryDate, 'YYYY-MM-DD') || '', [Validators.required]),
      vacationHours: new FormControl(this.employee.vacationHours || 0, [Validators.required]),
      note: new FormControl(this.employee.note || ''),
      construction: new FormControl(this.employee.salary.construction || 0, [Validators.required]),
      workshop: new FormControl(this.employee.salary.workshop || 0, [Validators.required]),
      other: new FormControl(this.employee.salary.other || 0, [Validators.required]),
      defaultBonus: new FormControl(this.employee.defaultBonus || 0, [Validators.required]),
      defaultHours: new FormControl(this.employee.defaultHours || 0, [Validators.required]),
    });
  }

  resetForm(sent:boolean): void {
    if (!sent) {
      this.employee = Object.assign({}, this.employeeBackUp);
    }
    this.buildForm();
  }

  sendData(): void {
    this.employee.firstName = this.form.get('firstName')?.value;
    this.employee.lastName = this.form.get('lastName')?.value;
    this.employee.rc = this.form.get('rc')?.value;
    this.employee.address = this.form.get('address')?.value;
    this.employee.tel = this.form.get('tel')?.value;
    this.employee.email = this.form.get('email')?.value;
    this.employee.entryDate = Timestamp.fromDate(new Date(this.form.get('entryDate')?.value));
    this.employee.vacationHours = this.form.get('vacationHours')?.value;
    this.employee.note = this.form.get('note')?.value;
    this.employee.salary.construction = this.form.get('construction')?.value;
    this.employee.salary.workshop = this.form.get('workshop')?.value;
    this.employee.salary.other = this.form.get('other')?.value;
    this.employee.defaultBonus = this.form.get('defaultBonus')?.value;
    this.employee.defaultHours = this.form.get('defaultHours')?.value;
    this.employeeService.upsert(this.employee);
  }

  openForm(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', scrollable: true, centered: true}).result.then(result => {
      this.sendData();
      this.resetForm(!this.isNew);
      console.log('Formulář uzavřen pomocí:' + result);
    }, (reason) => {
      this.resetForm(false);
      console.log(this.errorRes.getModalDismissReason(reason));
    });
  }

  onTelChange(): void {
    const tel = this.form.get('tel')?.value.replace(/\s/g, '');
    this.form.get('tel')?.setValue(tel.slice(0, 3) + ' ' + tel.slice(3, 6) + ' ' + tel.slice(6));
  }
}
