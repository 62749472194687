<h5>Soubory</h5>
<div *ngIf="model.files[0]" class="table-div">
  <div class="tableFixHead">
    <table class="table table-striped mb-0">
      <thead>
      <tr>
        <th>Název</th>
        <th class="d-none d-xl-table-cell">Nahráno</th>
        <th class="d-none d-sm-table-cell">Velikost</th>
        <th class="text-end">Operace</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let file of model.files, index as i">
        <ng-container *ngIf="getFileType(file.name) === 'image' || getFileType(file.name) === 'video'">
          <a href="{{file.src}}" data-fancybox="a" class="d-table-cell align-middle pointer">
            <div *ngIf="getFileType(file.name) === 'image'" class="d-flex align-items-center text-info">
              <fa-icon class="mx-2 fs-5" [icon]="fa['image']"></fa-icon>
              <div class="font-weight-bold file-name">{{file.name}}</div>
            </div>
            <div *ngIf="getFileType(file.name) === 'video'" class="d-flex align-items-center text-info">
              <fa-icon class="mx-2 fs-5" [icon]="fa['video']"></fa-icon>
              <div class="font-weight-bold file-name">{{file.name}}</div>
            </div>
            <div *ngIf="getFileType(file.name) === 'audio'" class="d-flex align-items-center text-info">
              <fa-icon class="mx-2 fs-5" [icon]="fa['audio']"></fa-icon>
              <div class="font-weight-bold file-name">{{file.name}}</div>
            </div>
          </a>
          <a href="{{file.src}}" data-fancybox="b"
             class="d-table-cell align-middle d-none d-xl-table-cell pointer">{{utilityService.timestampToString(file.uploadTime)}}</a>
          <a href="{{file.src}}" data-fancybox="c"
             class="d-table-cell text-nowrap align-middle d-none d-sm-table-cell pointer">{{formatBytes(file.size)}}</a>
        </ng-container>
        <ng-container
          *ngIf="getFileType(file.name) === 'pdf' || getFileType(file.name) === 'word' || getFileType(file.name) === 'excel' || getFileType(file.name) === 'powerpoint'">
          <td data-src="#dialog-content" data-fancybox="a" class="d-table-cell align-middle pointer">
            <div *ngIf="getFileType(file.name) === 'pdf'" class="d-flex align-items-center text-danger">
              <fa-icon class="mx-2 fs-5" [icon]="fa['pdf']"></fa-icon>
              <div class="font-weight-bold file-name">{{file.name}}</div>
            </div>
            <div *ngIf="getFileType(file.name) === 'word'" class="d-flex align-items-center text-primary">
              <fa-icon class="mx-2 fs-5" [icon]="fa['word']"></fa-icon>
              <div class="font-weight-bold file-name">{{file.name}}</div>
            </div>
            <div *ngIf="getFileType(file.name) === 'excel'" class="d-flex align-items-center text-success">
              <fa-icon class="mx-2 fs-5" [icon]="fa['excel']"></fa-icon>
              <div class="font-weight-bold file-name">{{file.name}}</div>
            </div>
            <div *ngIf="getFileType(file.name) === 'powerpoint'" class="d-flex align-items-center text-warning">
              <fa-icon class="mx-2 fs-5" [icon]="fa['powerpoint']"></fa-icon>
              <div class="font-weight-bold file-name">{{file.name}}</div>
            </div>
          </td>
          <td data-src="#dialog-content" data-fancybox="b"
              class="d-table-cell align-middle d-none d-xl-table-cell pointer">{{utilityService.timestampToString(file.uploadTime)}}</td>
          <td data-src="#dialog-content" data-fancybox="c"
              class="d-table-cell text-nowrap align-middle d-none d-sm-table-cell pointer">{{formatBytes(file.size)}}</td>
          <ngx-doc-viewer
            [url]=file.src
            viewer="google"
            id="dialog-content"
            style="display:none;width:100%;height:100vh;"
          ></ngx-doc-viewer>
        </ng-container>
        <ng-container *ngIf="getFileType(file.name) === 'archive' || getFileType(file.name) === 'other'">
          <td (click)="utilityService.openSite(file.src)" class="align-middle pointer">
            <div *ngIf="getFileType(file.name) === 'archive'" class="d-flex align-items-center text-archive">
              <fa-icon class="mx-2 fs-5" [icon]="fa['archive']"></fa-icon>
              <div class="font-weight-bold file-name">{{file.name}}</div>
            </div>
            <div *ngIf="getFileType(file.name) === 'other'" class="d-flex align-items-center text-muted-light">
              <fa-icon class="mx-2 fs-5" [icon]="fa['file']"></fa-icon>
              <div class="font-weight-bold file-name">{{file.name}}</div>
            </div>
          </td>
          <td (click)="utilityService.openSite(file.src)"
              class="align-middle d-none d-xl-table-cell pointer">{{utilityService.timestampToString(file.uploadTime)}}</td>
          <td (click)="utilityService.openSite(file.src)"
              class="text-nowrap align-middle d-none d-sm-table-cell pointer">{{formatBytes(file.size)}}</td>
        </ng-container>
        <td class="text-end text-nowrap align-middle">
          <a href="{{file.src}}" download [ngbTooltip]="downloadTip">
            <fa-icon class="fs-5 mx-2 text-primary" [icon]="fa.download"></fa-icon>
          </a>
          <fa-icon class="fs-5 mx-2 text-danger pointer" [icon]="fa.trash" (click)="delete(file, deleteAlert)"
                   [ngbTooltip]="deleteTip"></fa-icon>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-container *ngIf="!model.files[0]">
  <h3 class="text-center text-danger my-5">Nebyly nalezeny žádné dokumenty!</h3>
</ng-container>
<ngb-progressbar
  [showValue]="true"
  [value]="uploadProgress"
  [striped]="true"
  [animated]="true"
  class="my-2"
></ngb-progressbar>

<div class="input-group">
  <input
    (change)="onFileChange($event)"
    [attr.disabled]="disableSending? '' : null"
    type="file"
    class="form-control">
  <span class="input-group-text"><fa-icon [icon]="fa.upload"></fa-icon></span>
</div>
<ng-template #deleteAlert let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Smazat soubor?</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    Opravdu chcete soubor smazat?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close('delete')">Smazat</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('cancel')">Zrušit</button>
  </div>
</ng-template>
<ng-template #viewTip>
  <div class="fs-7">
    Zobrazit
  </div>
</ng-template>
<ng-template #deleteTip>
  <div class="fs-7">
    Odstranit
  </div>
</ng-template>
<ng-template #downloadTip>
  <div class="fs-7">
    Stáhnout
  </div>
</ng-template>
