import {Component, Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ErrorResolutionService} from 'src/app/core/services/errorResolution.service';
import {ClothesModel} from "../../../../../../../../core/interfaces/clothes.model";
import {ClothesService} from "../../../../../../../../core/services/clothes.service";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-clothing-delete',
  templateUrl: './clothing-delete.component.html',
  styleUrls: ['./clothing-delete.component.scss']
})
export class ClothingDeleteComponent {
  @Input() clothing = new ClothesModel();
  icon = faTrashCan;

  constructor(
    private clothesService: ClothesService,
    private modalService: NgbModal,
    private errorRes: ErrorResolutionService,
  ) { }

  showAlert(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', scrollable: true, centered: true}).result.then(result => {
      if (result === 'delete') {
        this.delete();
      }
      console.log('Formulář uzavřen pomocí: ' + result);
    }, (reason) => {
      console.log(this.errorRes.getModalDismissReason(reason));
    });
  }

  delete(): void {
    console.log('Mažu záznam ' + this.clothing.id + ' oblečení ' + this.clothing.name);
    this.clothesService.delete(this.clothing.id);
  }
}
