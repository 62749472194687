<div class="d-flex align-items-center justify-content-center h-100">
  <div class="text-center">
    <h1 class="display-1 fw-bold" [countUp]="404" [duration]="2000"></h1>
    <p class="fs-3"> <span class="text-danger">Opps!</span> Stránka nenalezena.</p>
    <p class="lead">
      Vypadá to, že stránka neexistuje.
    </p>
    <button type="submit" class="btn btn-primary" (click)="location.back()">Jít Zpět</button>
  </div>
</div>
