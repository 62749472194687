import {Component, OnInit} from '@angular/core';
import * as XLSX from 'xlsx';
import {UtilityService} from "../../../core/services/utility.service";
@Component({
  selector: 'app-finedr',
  templateUrl: './urs-finder.component.html',
  styleUrls: ['./urs-finder.component.scss']
})
export class UrsFinderComponent {
  disableSending = false;
  arrayBuffer:any;
  file: any;
  items: string[] = [];
  constructor(
    private utilityService: UtilityService
  ) { }

  chooseFile(event: any): void{
    if(event.target.files.length == 1) {
      this.file = event.target.files[0];
      this.items = [];
      const target: DataTransfer = <DataTransfer>(event.target);
      const reader: FileReader = new FileReader();

      reader.readAsBinaryString(target.files[0]);
      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        /* save data */
        const data = XLSX.utils.sheet_to_json(ws,{range:0}); // to get 2d array pass 2nd parameter as object {header: 1}
        data.forEach((item:any) => {
          const code = item.__EMPTY;
          this.items.push(code)
          this.utilityService.openSite("https://cs-urs-online.urs.cz/vyhledavani/constructions/" + code);
        });
        console.log(data); // Data will be logged in array format containing objects
      };
    } else {
      throw new Error('Cannot use multiple files');
    }
  }
}
