import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ClothingSizeModel} from "../interfaces/clothingSize.model";
import {ClothingSizeRepositoryService} from "../repositories/clothing-size-repository.service";

@Injectable({
  providedIn: 'root'
})
export class ClothingSizeService {

  constructor(
    private repository: ClothingSizeRepositoryService,
  ) {}

  createId(): string {
    const id = this.repository.createId();
    console.log('Generuji ID ' + id + ' pro záznam velikosti oblečení...');
    return id;
  }

  upsert(size: ClothingSizeModel, clothingId: string): Observable<any> {
    console.log('Aktualizuji/Vytvářím záznam velikosti oblečení ' + size.id + '...');
    const parentPath = `ClothingTypes/${clothingId}/sizes`;
    return this.repository.upsert(size,{parentPath});
  }
  getByEmployee(clothingId: string, employeeId: string): Observable<any> {
    const parentPath = `ClothingTypes/${clothingId}/sizes`;
    return this.repository.getBy('employeeID', employeeId ,{parentPath});
  }
}
