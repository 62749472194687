import {Component, OnInit, Input} from '@angular/core';

import {Router} from "@angular/router"
import {Location} from '@angular/common';

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss']
})
export class SiteHeaderComponent implements OnInit {
  @Input() headerLine1: string = ""; //Different on every page
  @Input() headerLine2: string = ""; //Different on every page
  @Input() btnType: string = "";

  buttonTitle: string = ""; //On /contacts it will be Zpět

  constructor(
    private location: Location,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.btnTxt();
  }
  btnTxt(): void{
    if (this.btnType === "back") {
      this.buttonTitle = "Zpět";
    } else {
      this.buttonTitle = "Kontaktujte nás";
    }
  }
  btnAction(): void {
    if (this.btnType === "back") {
      this.location.back();
    } else {
      this.router.navigate(['/contacts']).then();
    }
  }
}
