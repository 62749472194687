<button class="btn btn-sm btn-danger mx-1" (click)="showAlert(alert)"><fa-icon [icon]="icon"></fa-icon></button>
<ng-template #alert let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Smazat oblečení?</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    Opravdu chcete smazat oblečení <b>{{clothing.name}}</b>?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close('delete')">Smazat</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('cancel')">Zrušit</button>
  </div>
</ng-template>

