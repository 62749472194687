import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {EmployeeModel} from "../../../../../../../core/interfaces/employee.model";
import {AttendancePlaceEnum, MonthlyAttendance} from "../../../../../../../core/interfaces/employeeAttendance.model";
import {AttendanceService} from "../../../../../../../core/services/attendance.service";
import {SalaryService} from "../../../../../../../core/services/salary.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[inputTr]',
  templateUrl: './daily-attendance-input.component.html',
  styleUrls: ['./daily-attendance-input.component.scss']
})
export class DailyAttendanceInputComponent implements OnInit, OnChanges {
  @Input() day!: { date: string, name: string };
  @Input() employee!: EmployeeModel;
  @Input() attendance!: MonthlyAttendance;
  @Output() inputChange = new EventEmitter<string>();
  attendancePlaces = Object.values(AttendancePlaceEnum);

  multiplier = 1;
  publicHoliday = false;
  place = AttendancePlaceEnum.construction;
  hours = 8;
  salary = 0;
  activity = '';

  constructor(
    private service: AttendanceService,
    private salaryService: SalaryService,
  ) {
  }

  ngOnInit(): void {
    this.checkDay();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.employee.id) {
      const yearMonth = this.day.date.substring(6, 10) + '-' + this.day.date.substring(3, 5)
      this.service.getByDateForEmployee(yearMonth, this.employee.id).subscribe(result => {
        let month = result.data();
        if (month) {
          const day = month.days.filter((day: any) => {return day.date == this.day.date})[0];
          if (day) {
            switch (day.place) {
              case 'Stavba':
                this.place = AttendancePlaceEnum.construction;
                break;
              case 'Dílna':
                this.place = AttendancePlaceEnum.workshop;
                break;
              case 'Jiné':
                this.place = AttendancePlaceEnum.other;
                break;
              case 'Dovolená':
                this.place = AttendancePlaceEnum.vacation;
                break;
              case 'Nemoc':
                this.place = AttendancePlaceEnum.sick;
                break;
              case 'Víkend':
                this.place = AttendancePlaceEnum.weekend;
                break;
              case 'Svátek':
                this.place = AttendancePlaceEnum.publicHoliday;
                break;
              default:
                this.place = AttendancePlaceEnum.construction;
                break;
            }
            this.hours = day.hours;
            this.activity = day.activity;
            this.multiplier = day.multiplier;
          }
        } else {
          this.multiplier = 1;
          this.activity = '';
          this.checkDay();
        }
      });
    }
  }

  Easter(Y: number):string {
    let C = Math.floor(Y / 100);
    let N = Y - 19*Math.floor(Y/19);
    let K = Math.floor((C - 17)/25);
    let I = C - Math.floor(C/4) - Math.floor((C - K)/3) + 19*N + 15;
    I = I - 30*Math.floor((I/30));
    I = I - Math.floor(I/28)*(1 - Math.floor(I/28)*Math.floor(29/(I + 1))*Math.floor((21 - N)/11));
    let J = Y + Math.floor(Y/4) + I + 2 - C + Math.floor(C/4);
    J = J - 7*Math.floor(J/7);
    let L = I - J;
    let M = 3 + Math.floor((L + 40)/44);
    let D = L + 28 - 31*Math.floor(M/4);

    return this.padout(D) + '.' + this.padout(M);
  }

  padout(num:number):string {
    return (num < 10) ? '0' + num : num.toString();
  }

  checkDay(): void {
    const easterDate = this.Easter(+this.day.date.substring(6,10));
    const easterDay = +easterDate.substring(0,2);
    const easterMonth = +easterDate.substring(3,5);
    const goodFriday = easterDay - 2;
    let easterMonday = easterDay + 1;
    let easterMondayMonth = easterMonth;

    // Adjust Easter Monday if it falls in the next month
    if (easterMonday > 31 && easterMonth == 3) {
      easterMonday -= 31;
      easterMondayMonth = 4;
    }

    const holidays = [
      {date: '01.01', name: 'Nový rok'},
      {date: this.padout(goodFriday) + '.' + this.padout(easterMonth), name: 'Velký pátek'},
      {date: this.padout(easterMonday) + '.' + this.padout(easterMondayMonth), name: 'Velikonoční pondělí'},
      {date: '01.05', name: 'Svátek práce'},
      {date: '08.05', name: 'Den vítězství'},
      {date: '05.07', name: 'Den věrozvěstů Cyrila a Metoděje'},
      {date: '06.07', name: 'Den upálení mistra Jana Husa'},
      {date: '28.09', name: 'Den české státnosti'},
      {date: '28.10', name: 'Den vzniku Československa'},
      {date: '17.11', name: 'Den boje za svobodu a demokracii'},
      {date: '24.12', name: 'Štědrý den'},
      {date: '25.12', name: '1. svátek vánoční'},
      {date: '26.12', name: '2. svátek vánoční'}
    ]
    const holiday = holidays.filter(day => {
      return day.date == this.day.date.substring(0,5);
    })[0];
    if (this.day.name == 'SO' || this.day.name == 'NE') {
      this.place = AttendancePlaceEnum.weekend;
      this.hours = 0;
    } else {
      this.place = AttendancePlaceEnum.construction;
      this.hours = this.employee.defaultHours;
    }
    if (holiday) {
      this.place = AttendancePlaceEnum.publicHoliday;
      this.activity = holiday.name;
      this.publicHoliday = true
    }
  }

  checkPlaceHours(): void {
    if (this.place == AttendancePlaceEnum.vacation || this.place == AttendancePlaceEnum.sick) {
      this.hours = this.employee.defaultHours;
    }
  }

  calculateSalary(): number {
    const salary = this.salaryService.calculateSalaryForDay(this.place,this.hours,this.multiplier,this.employee.salary);
    this.salary = salary;
    this.inputChange.emit("Salary recalculated!");
    return salary;
  }
}
