import { Timestamp } from 'firebase/firestore';
import {FileUploadModel} from "./fileUpload.model";
export enum MachineTypeEnum {
  car =  'car',
  van =  'van',
  truck =  'truck',
  crawlerExcavator = 'crawlerExcavator',
  wheeledExcavator = 'wheeledExcavator',
  skidLoader = 'skidLoader',
  trailer = 'trailer',
  other = 'other',
}
export const MachineTypeEnum2LabelMapping: Record<MachineTypeEnum, string> = {
  [MachineTypeEnum.car]: 'Osobní automobyl',
  [MachineTypeEnum.van]: 'Dodávka',
  [MachineTypeEnum.truck]: 'Nákladní automobil',
  [MachineTypeEnum.crawlerExcavator]: 'Pásový bagr',
  [MachineTypeEnum.wheeledExcavator]: 'Kolový bagr',
  [MachineTypeEnum.skidLoader]: 'Smykový nakladač',
  [MachineTypeEnum.trailer]: 'Přívěs',
  [MachineTypeEnum.other]: 'Jiné',
};
export class MachineModel {
  constructor () {
    this.type = MachineTypeEnum.car;
  }
  id = '';
  manufacturer = '';
  model = '';
  type: MachineTypeEnum;
  operator: string | null = '';
  purchaseDate = Timestamp.now();
  previewImage = new FileUploadModel();
  gallery = [new FileUploadModel()];
  files: FileUploadModel[] = [];
  vin ='';
  spz ='';
  gpsId = '';
}
