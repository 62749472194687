import { Injectable } from '@angular/core';
import {Repository} from './repository.abstract';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {RepositoryOptionsInterface} from '../interfaces/repository-options.interface';
import {CertificateModel} from "../interfaces/certificate.model";

@Injectable({
  providedIn: 'root'
})
export class CertificateRepositoryService extends Repository {
  constructor(protected db: AngularFirestore) {
    super();
  }

  collectionName(options?: RepositoryOptionsInterface): string {
    return 'Certificates';
  }

  fromDb(snapshot: any, options: any): CertificateModel{
    const data = snapshot.data(options);
    const result = new CertificateModel();
    Object.assign(result, data);
    result.id = data.id || snapshot.id;

    return result;
  }

  toDb(data: CertificateModel): object {
    return JSON.parse(JSON.stringify(data));
  }
}
