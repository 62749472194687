import {Component, Input, OnInit} from '@angular/core';
import {ContactPersonModel} from '../../../../../../../core/interfaces/contactPerson.model';
import {DataService} from '../../../../../../../core/services/data.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ErrorResolutionService} from '../../../../../../../core/services/errorResolution.service';
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-contact-person-form',
  templateUrl: './contact-person-form.component.html',
  styleUrls: ['./contact-person-form.component.scss']
})
export class ContactPersonFormComponent implements OnInit {
  @Input() person = new ContactPersonModel();
  personBackUp = new ContactPersonModel();
  personForm!: FormGroup;
  icon = faPenToSquare;
  isNew = true;

  constructor(
    private dataService: DataService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private errorRes: ErrorResolutionService,
  ) { }

  ngOnInit(): void {
    if (this.person.id !== '') {
      this.isNew = false;
    }
    this.personBackUp = Object.assign({}, this.person);
    this.buildForm();
  }

  buildForm(): void {
    this.personForm = this.formBuilder.group({
      name: new FormControl(this.person.name || '', [Validators.required]),
      function: new FormControl(this.person.function || '', [Validators.required]),
      email: new FormControl(this.person.email || '', [Validators.required, Validators.email]),
      phoneNumber: new FormControl(this.person.phoneNumber || '', [Validators.required]),
      priority: new FormControl(this.person.priority || '', [Validators.required]),
    });
  }

  resetForm(sent:boolean): void {
    if (!sent) {
      this.person = Object.assign({}, this.personBackUp);
    }
    this.buildForm();
  }

  onTelChange(): void {
    const tel = this.personForm.get('phoneNumber')?.value.replace(/\s/g, '');
    this.personForm.get('phoneNumber')?.setValue(tel.slice(0, 3) + ' ' + tel.slice(3, 6) + ' ' + tel.slice(6));
  }

  sendData(): void {
    this.person.name = this.personForm.get('name')?.value;
    this.person.function = this.personForm.get('function')?.value;
    this.person.email = this.personForm.get('email')?.value;
    this.person.phoneNumber = this.personForm.get('phoneNumber')?.value;
    this.person.priority = this.personForm.get('priority')?.value;
    this.dataService.upsertContactPerson(this.person);
  }

  openForm(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', scrollable: true, centered: true}).result.then(result => {
      this.sendData();
      this.resetForm(!this.isNew);
      console.log('Formulář uzavřen pomocí:' + result);
    }, (reason) => {
      this.resetForm(false);
      console.log(this.errorRes.getModalDismissReason(reason));
    });
  }
}
