import { Injectable } from '@angular/core';
import {Repository} from './repository.abstract';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {RepositoryOptionsInterface} from '../interfaces/repository-options.interface';
import {EmployeeModel} from "../interfaces/employee.model";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EmployeeRepositoryService extends Repository {
  constructor(protected db: AngularFirestore) {
    super();
  }

  collectionName(options?: RepositoryOptionsInterface): string {
    return 'Employees';
  }

  fromDb(snapshot: any, options: any): EmployeeModel {
    const data = snapshot.data(options);
    const result = new EmployeeModel();
    Object.assign(result, data);
    result.id = data.id || snapshot.id;

    return result;
  }

  toDb(data: EmployeeModel): object {
    return JSON.parse(JSON.stringify(data));
  }

  getAllActive(options?: RepositoryOptionsInterface): Observable<EmployeeModel[]> {
    const result = this.db.collection<EmployeeModel>(this.collectionWithConverter(options), (colRef) => {
      return colRef.where('active', '==', true)
        .orderBy('lastName', 'asc');
    });
    return result.valueChanges({idField: 'id'});
  }
  getAllEmployees(options?: RepositoryOptionsInterface): Observable<EmployeeModel[]> {
    const result = this.db.collection<EmployeeModel>(this.collectionWithConverter(options), (colRef) => {
      return colRef.orderBy('active', 'desc').orderBy('lastName', 'asc');
    });
    return result.valueChanges({idField: 'id'});
  }
}

