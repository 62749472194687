import { Component, OnInit } from '@angular/core';

import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-web-scroll-up-button',
  templateUrl: './web-scroll-up-button.component.html',
  styleUrls: ['./web-scroll-up-button.component.scss']
})
export class WebScrollUpButtonComponent implements OnInit {
  // fontAwsome icons
  Arrow = faAngleDoubleUp;
  constructor() { }

  ngOnInit(): void {
    this.scrollUpButton();
  }
  // ScrollUpButton functions
  scrollUpButton(): void {
    window.onscroll = () => {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        // $Pokud uživatel scrollnul o více jak 20px dolů od vršku stránky
        document.getElementById('btn-back-to-top')!.style.display = 'block'; // Zobrazí tlačítko
      } else {
        // $Pokud uživatel nescrollnul o více jak 20px dolů od vršku stránky
        document.getElementById('btn-back-to-top')!.style.display = 'none'; // Skryje tlačítko
      }
    };
  }
  backToTop(): void {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
}
