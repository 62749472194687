import {Component, OnInit} from '@angular/core';

import {toggle} from 'slidetoggle';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import emailjs from '@emailjs/browser';
import {DataService} from 'src/app/core/services/data.service';
import {CompanyInfoModel} from 'src/app/core/interfaces/companyInfo.model';
import {faX} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-web-footer-contact-form',
  templateUrl: './web-footer-contact-form.component.html',
  styleUrls: ['./web-footer-contact-form.component.scss']
})

export class WebFooterContactFormComponent implements OnInit {
  submitButtonText = 'Odeslat';
  disabled = false;
  contactData = new CompanyInfoModel();
  x = faX;
  contactForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.dataService.getCompanyInfo().subscribe(result => {
      this.contactData.phoneNumber = result.data().phoneNumber;
    });
  }
  buildForm(): void {
    this.contactForm = this.formBuilder.group({
      fname: new FormControl('', [Validators.required]),
      lname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      tel: new FormControl(''),
      message: new FormControl('', [Validators.required]),
      tos: new FormControl('', [Validators.required]),
    });
  }

  onTelChange(): void {
    const tel = this.contactForm.get('tel')!.value!.replace(/\s/g, '');
    if (tel.length == 9) {
      this.contactForm.get('tel')?.setValue(tel.slice(0, 3) + ' ' + tel.slice(3, 6) + ' ' + tel.slice(6));
    }
  }

  validate() {
    let form = document.getElementsByClassName('needs-validation')[0] as HTMLFormElement;
    if (!form.checkValidity()) {
      event!.preventDefault();
      event!.stopPropagation();
    }
    form.classList.add('was-validated');
  }

  onSubmit(e: Event): void {
    this.validate();
    if (!this.disabled && this.contactForm.valid) {
      emailjs.sendForm('service_f89sb25', 'contact_form', e.target as HTMLFormElement, 'NeOqml0d8CQHjFg97')
        .then(() => {
            this.submitButtonText = "Děkujeme";
            this.disabled = true;
            setTimeout((): void => {
              toggle('#contactform', {miliseconds: 500});
              setTimeout((): void => {
                this.contactForm.reset();
                this.submitButtonText = "Odeslat";
                this.disabled = false;
              }, 200);
            }, 1000);
          }, () => {
            this.submitButtonText = "Něco nevyšlo (5)";
            this.disabled = true;
            setTimeout((): void => {
              this.submitButtonText = "Něco nevyšlo (4)";
              setTimeout((): void => {
                this.submitButtonText = "Něco nevyšlo (3)";
                setTimeout((): void => {
                  this.submitButtonText = "Něco nevyšlo (2)";
                  setTimeout((): void => {
                    this.submitButtonText = "Něco nevyšlo (1)";
                    setTimeout((): void => {
                      this.submitButtonText = "Zkusit znovu";
                      this.disabled = false;
                    }, 1000);
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 1000);
          }
        );
    }
  }
  contactFormSlide(): void {
    this.buildForm();
    toggle('#contactform', {miliseconds: 500});
  }
}
