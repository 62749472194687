<div class="container-fluid bg-light bg-gradient">
  <app-web-navbar></app-web-navbar>
  <router-outlet></router-outlet>
  <app-web-footer></app-web-footer>
</div>

<!--<div id="cookie-consent-box" class="cookie-consent cookie-consent&#45;&#45;box" *ngIf="showPopup">-->
<!--  <div class="cookie-consent-content mt-1 mb-2">-->
<!--    <p>-->
<!--      Tato stránka používá cookies k analýze návštěvnosti.-->
<!--      Více informací naleznete <a routerLink="/cookies">zde</a>.-->
<!--    </p>-->
<!--    <button href="#" tabindex="0" class="btn btn-sm btn-primary mt-3" (click)="consent()">Rozumím</button>-->
<!--  </div>-->
<!--</div>-->
