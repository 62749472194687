import { Component, OnInit } from '@angular/core';

import { ReferenceCategoryModel } from 'src/app/core/interfaces/referenceCategory.model';
import {CategoryService} from 'src/app/core/services/category.service';
import {UtilityService} from "../../../../../../core/services/utility.service";
import {faEye, faImages, faNewspaper} from "@fortawesome/free-solid-svg-icons";
import {CertificateModel} from "../../../../../../core/interfaces/certificate.model";
import {DataService} from "../../../../../../core/services/data.service";

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss']
})
export class CertificatesComponent implements OnInit {
  certificateList: CertificateModel[] = [];
  asc = false;
  previewIcon = faEye;

  constructor(
    private dataService: DataService,
    public util: UtilityService
  ) { }

  ngOnInit(): void {
    this.getAllCategories();
  }

  getAllCategories(): void {
    this.dataService.getAllCertificates().subscribe(result => {
      if (result.length > 0) {
        this.certificateList = result;
      } else {
        this.certificateList = [];
        console.log('Žádný záznam kategorie neexistuje!!!')
      }
    });
  }
  sort(key: string) {
    const sorter = this.util.sortByKey(this.certificateList, key, this.asc);
    this.certificateList = sorter.object;
    this.asc = sorter.asc;
  }
}
