import {Injectable} from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {Observable} from 'rxjs';
import {AngularFireAuth} from "@angular/fire/compat/auth";

@Injectable({
  providedIn: 'root',
})

export class MachineGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    public auth: AngularFireAuth,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      this.authService.initialized.subscribe(result => {
        if (result) {
          if (this.authService.appUser?.permissions.machinesAdmin || this.authService.appUser?.permissions.superAdmin) {
            return resolve(true);
          } else {
            this.router.navigate(['admin/error/401']).then();
            return resolve(false);
          }
        }
      });
    });
  }
}
