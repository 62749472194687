export class CompanyInfoModel {
  id = '';
  name = '';
  in = '';
  taxNumber = '';
  dataBox = '';
  email = '';
  phoneNumber = '';
  address1 = '';
  address2 = '';
}
