<div class="row mb-2">
  <label for="{{clothing.name}}" class="col-sm-3 col-form-label text-sm-end text-nowrap">{{clothing.name}}</label>
  <div class="col-sm-9">
    <div class="row">
      <div class="{{clothing.ownLink? 'sizeInput col-xxl-2 col-xl-3 col-lg-4 col-md-3':''}} mb-2 mb-md-0">
        <select *ngIf="!clothing.numberedSizes" [(ngModel)]="selectedSize.size" class="form-select">
          <option
            *ngFor="let size of sizes"
            id="{{clothing.name}}"
            value="{{size}}">
            {{size}}
          </option>
        </select>
        <select *ngIf="clothing.numberedSizes" [(ngModel)]="selectedSize.size" class="form-select">
          <option
            *ngFor="let size of numberedSizes"
            id="{{clothing.name}}"
            value="{{size}}">
            {{size}}
          </option>
        </select>
      </div>
      <div *ngIf="clothing.ownLink" class="linkInput col-xxl-10 col-xl-9 col-lg-8 col-md-9">
        <input
          [(ngModel)]="selectedSize.link"
          class="form-control"
          type="text"
          placeholder="Odkaz"
        >
      </div>
    </div>
  </div>
</div>


