<app-site-header
  [headerLine1]="headerL1"
  [headerLine2]="headerL2"
  [btnType]="btnType"
></app-site-header>
<main>
  <div class="row">
    <div class="col-12 text-center bg-white shadow">
      <div class="row mt-5">
        <h1>Naše Hodnoty</h1>
      </div>
      <div class="row mb-2 justify-content-evenly">
        <div class="col-lg-3 col-md-6 col-12">
          <img class="value-img" src="../../../../../../assets/images/home/kvalita.svg" alt="Kvalita" />
          <h2>Kvalita</h2>
          <p class="content-text mx-auto">
            Víme že vybudovat dobré jméno trvá léta a šetřit na kvalitě se
            nevyplácí.
          </p>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <img class="value-img" src="../../../../../../assets/images/home/profesionalita.svg" alt="Profesionalita" />
          <h2>Profesionalita</h2>
          <p class="content-text mx-auto">
            Zabýváme se tím, co umíme nejlépe a pořád se posouváme
            kupředu.
          </p>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <img class="value-img" src="../../../../../../assets/images/home/spolehlivost.svg" alt="Spolehlivost" />
          <h2>Spolehlivost</h2>
          <p class="content-text mx-auto">
            Dobré vztahy mezi společností a klientem jsou základem dobré
            spolupráce.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="certificates.length > 0" class="row cert-section">
    <div class="col-12 text-center">
      <div class="row mt-5 mb-4">
        <h1>Certifikace</h1>
      </div>
      <div class="row mb-5 justify-content-center">
        <div *ngFor="let cert of certificates" class="col-lg-3 col-md-6 col-12 mb-4">
          <a href="{{cert.high.src}}" data-fancybox="certificate-set">
            <img class="cert shadow-lg" src="{{cert.low.src}}" alt="{{cert.name}}"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</main>
