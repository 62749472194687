<div class="row footer-text text-white bg-dark">
  <div class="row my-5 mx-0 justify-content-center">
    <div class="col-3 d-lg-none d-md-inline d-none"></div>
    <div class="col-lg-3 col-md-6 col-12 justify-content-md-center text-center text-lg-start">
      <div class="row">
        <a routerLink="/home"><img class="footer-logo" src="../../../../../assets/images/logo.svg" alt="Logo" /></a>
      </div>
      <div class="row">
        <p class="mb-2 mt-3 footer-links">
          <a class="footer-link text-nowrap" routerLink="/home">Domů</a>
          |
          <a class="footer-link text-nowrap" routerLink="/references">Reference</a>
          |
          <a class="footer-link text-nowrap" routerLink="/contacts">Kontakty</a>
        </p>
      </div>
      <div class="row text-muted">
        <p>©{{year}} {{contactData.name}}<br>Vytvořil <a class="made-by text-nowrap" href="https://ales-urbanek.cz" target="_blank">Aleš Urbánek ml.</a></p>
      </div>
    </div>
    <div class="col-3 d-lg-none d-md-inline d-none"></div>
    <div class="col-3 d-lg-none d-md-inline d-none"></div>
    <div class="col-lg-3 col-md-4 col-sm-5 col-9 justify-content-md-center">
      <div class="row footer-info justify-content-md-center text-center text-lg-start">
        <div class="col-1 fs-5 position-relative d-lg-inline d-none">
          <fa-icon class="position-absolute top-50 start-50 translate-middle" [icon]="Location"></fa-icon>
        </div>
        <div class="col footer-links mt-2">
          <a class="footer-link" href="https://www.google.cz/maps/place/{{contactData.address1}}" target="_blank">{{contactData.address1}}<br />{{contactData.address2}}</a>
        </div>
      </div>
      <div class="row footer-info justify-content-md-center text-center text-lg-start">
        <div class="col-1 fs-5 position-relative d-lg-inline d-none">
          <fa-icon class="position-absolute top-50 start-50 translate-middle" [icon]="Phone"></fa-icon>
        </div>
        <div class="col footer-links mt-3">
          <a class="footer-link text-nowrap" href="tel:+420{{contactData.phoneNumber}}">+420 {{contactData.phoneNumber}}</a>
        </div>
      </div>
      <div class="row footer-info justify-content-md-center text-center text-lg-start">
        <div class="col-1 fs-5 position-relative d-lg-inline d-none">
          <fa-icon class="position-absolute top-50 start-50 translate-middle" [icon]="Mail"></fa-icon>
        </div>
        <div class="col footer-links mt-lg-3">
          <a class="footer-link text-nowrap" href="mailto:{{contactData.email}}">{{contactData.email}}</a>
        </div>
      </div>
    </div>
    <div class="col-3 d-lg-none d-md-inline d-none"></div>
    <div class="col-3 d-lg-none d-md-inline d-none"></div>
    <div class="col-lg-3 col-md-6 col-10 justify-content-md-center text-center text-lg-start">
      <div class="row">
        <div class="col-12 footer-about mb-2">O společnosti</div>
        <!-- TODO: Načítat z databáze -->
        <div class="col-12 text-muted">
          Našim cílem je vždy spokojenost zákazníka, proto nabízíme kvalitní a profesionální služby.
          Jsme společnost založená v roce 2018 a zabýváme se veškerou stavební činností.
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-2 d-lg-none"></div>
        <div class="col-2 fs-5">
          <a aria-label="Odkaz na facebook" class="footer-link" href="https://www.facebook.com/Geo-stav-Vale%C4%8D-sro-2346242055396113/" target="_blank"><fa-icon [icon]="Facebook"></fa-icon></a>
        </div>
        <div class="col-2 fs-5">
          <a aria-label="Odkaz na instagarm" class="footer-link" href="https://www.instagram.com/geostav_valec/" target="_blank"><fa-icon [icon]="Instagram"></fa-icon></a>
        </div>
        <div class="col-2 fs-5">
          <a aria-label="Emailový kontakt" class="footer-link" href="mailto:{{contactData.email}}"><fa-icon [icon]="Mail"></fa-icon></a>
        </div>
        <div class="col-2 fs-5">
          <a aria-label="Telefonický kontakt" class="footer-link" href="tel:+420{{contactData.phoneNumber}}"><fa-icon [icon]="Phone"></fa-icon></a>
        </div>
      </div>
    </div>
    <div class="col-3 d-lg-none d-md-inline d-none"></div>
  </div>
</div>
