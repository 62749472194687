import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {ReferenceModel} from 'src/app/core/interfaces/reference.model';
import {ReferenceCategoryModel} from 'src/app/core/interfaces/referenceCategory.model';
import {ReferencePlaceModel} from 'src/app/core/interfaces/referencePlace.model';

import {PlaceService} from 'src/app/core/services/place.service';
import {CategoryService} from 'src/app/core/services/category.service';
import {FileService} from 'src/app/core/services/file.service';
import {ReferenceService} from 'src/app/core/services/reference.service';
import {ErrorResolutionService} from 'src/app/core/services/errorResolution.service';

import { Timestamp } from 'firebase/firestore';
import {FileUploadModel} from "../../../../../../../core/interfaces/fileUpload.model";
import {UtilityService} from "../../../../../../../core/services/utility.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-reference-form',
  templateUrl: './reference-form.component.html',
  styleUrls: ['./reference-form.component.scss']
})
export class ReferenceFormComponent implements OnInit {
  categoryList = [new ReferenceCategoryModel()];
  placeList = [new ReferencePlaceModel()];

  @Input() reference = new ReferenceModel();
  @Input() categoryId: string = '';
  referenceBackUp = new ReferenceModel();
  referenceForm!: FormGroup;
  icon = faPenToSquare;

  smallImageDisableSending = false;
  smallImageUploadProgress = 0;
  largeImageDisableSending = false;
  largeImageUploadProgress = 0;

  isNew = true;

  constructor(
    private referenceService: ReferenceService,
    private placeService: PlaceService,
    private categoryService: CategoryService,
    private utilityService: UtilityService,
    private fileService: FileService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private errorRes: ErrorResolutionService,
  ) {
  }

  ngOnInit(): void {
    if (this.reference.id !== '') {
      this.isNew = false;
    } else {
      this.reference.id = this.referenceService.createId();
    }
    this.referenceBackUp = Object.assign({}, this.reference);
    this.getAllCategories();
    this.getAllPlaces();
    this.buildForm();
    if (this.categoryId !== '' && this.reference.category === '') {
      this.referenceForm.get('category')?.setValue(this.categoryId);
      this.referenceBackUp.category = this.categoryId;
    }
  }

  getAllCategories(): void {
    this.categoryService.getAll().subscribe(result => {
      this.categoryList = result;
    });
  }

  getAllPlaces(): void {
    this.placeService.getAll().subscribe(result => {
      this.placeList = result;
    });
  }

  buildForm(): void {
    this.referenceForm = this.formBuilder.group({
      category: new FormControl(this.reference.category || null, [Validators.required]),
      place: new FormControl(this.reference.place || null, [Validators.required]),
      name: new FormControl(this.reference.name || '', [Validators.required]),
      dateFrom: new FormControl(this.utilityService.timestampToString(this.reference.dateFrom, 'YYYY-MM-DD') || '', [Validators.required]),
      dateTo: new FormControl(this.utilityService.timestampToString(this.reference.dateTo, 'YYYY-MM-DD') || '', [Validators.required]),
      basicInfo: new FormControl(this.reference.basicInfo || '', [Validators.required]),
      description: new FormControl(this.reference.description || '', [Validators.required]),
      smallImage: new FormControl(''),
      largeImage: new FormControl(''),
      subCategory: new FormControl(this.reference.subCategory || null, [Validators.required]),
    });
  }

  resetForm(sent: boolean): void {
    if (!sent) {
      this.reference = Object.assign({}, this.referenceBackUp);
    }
    if (this.isNew) {
      this.reference.id = this.referenceService.createId();
    }
    this.smallImageDisableSending = false;
    this.smallImageUploadProgress = 0;
    this.largeImageDisableSending = false;
    this.largeImageUploadProgress = 0;
    this.buildForm();
  }

  chooseSmallImage(event: any): void {
    if (this.reference.previewImage.src !== '') {
      this.fileService.delete(this.reference.previewImage.src);
    }
    if (event.target.files.length > 0) {
      this.smallImageUploadProgress = 0;
      this.smallImageDisableSending = true;
      const smallImage = event.target.files[0];
      this.fileService.upload(smallImage, '/references/' + this.reference.id + '/preview', true).subscribe(result => {
        if (typeof result === 'number') {
          this.smallImageUploadProgress = Math.round(result);
        } else {
          this.reference.previewImage = result;
          this.smallImageDisableSending = false;
        }
      });
    }
  }

  chooseLargeImage(event: any): void {
    if (event.target.files.length > 0) {
      this.largeImageUploadProgress = 0;
      this.largeImageDisableSending = true;
      const largeImage = event.target.files[0];
      this.fileService.upload(largeImage, '/references/' + this.reference.id + '/images', true).subscribe(result => {
        if (typeof result === 'number') {
          this.largeImageUploadProgress = Math.round(result);
        } else {
          if (this.reference.mainImage[0]) {
            this.reference.mainImage.push(result);
          } else {
            this.reference.mainImage = [result];
          }
          this.largeImageDisableSending = false;
        }
      });
    }
  }

  sendData(): void {
    if (this.isCategoryGallery()) {
      this.reference.place = '';
      this.reference.dateFrom = Timestamp.fromDate(new Date(Date.now()));
      this.reference.dateTo = Timestamp.fromDate(new Date(Date.now()));
      this.reference.description = '';
      this.reference.subCategory = this.referenceForm.get('subCategory')?.value;
    } else {
      this.reference.place = this.referenceForm.get('place')?.value;
      this.reference.dateFrom = Timestamp.fromDate(new Date(this.referenceForm.get('dateFrom')?.value));
      this.reference.dateTo = Timestamp.fromDate(new Date(this.referenceForm.get('dateTo')?.value));
      this.reference.description = this.referenceForm.get('description')?.value;
      this.reference.subCategory = '';
    }
    this.reference.category = this.referenceForm.get('category')?.value;
    this.reference.name = this.referenceForm.get('name')?.value;
    this.reference.basicInfo = this.referenceForm.get('basicInfo')?.value;
    this.referenceService.upsert(this.reference);
  }

  openForm(content: any): void {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      scrollable: true,
      centered: true
    }).result.then(
      (result) => {
        this.sendData();
        this.resetForm(!this.isNew);
        console.log('Formulář uzavřen pomocí:' + result);
      }, (reason) => {
        if(this.isNew) {
          this.fileService.deleteFolder('/public/references/' + this.reference.id + '/preview');
          this.fileService.deleteFolder('/public/references/' + this.reference.id + '/images');
        }
        this.resetForm(false);
        console.log(this.errorRes.getModalDismissReason(reason));
      });
  }

  removeImg(image: FileUploadModel): void {
    this.fileService.delete(image.src).then(r => {
      const index = this.reference.mainImage.indexOf(image);
      if (this.reference.mainImage.length > 1) {
        this.reference.mainImage.splice(index, 1);
      } else {
        this.reference.mainImage = [];
      }
    });
  }

  isCategoryGallery(): boolean {
    let categoryId = this.referenceForm.get('category')?.value;
    let category = this.categoryList.find((category) => {
      return category.id == categoryId
    });
    let gallery = category?.galleryMode || false;
    if (gallery) {
      this.referenceForm.get('place')?.setValue('NULL');
      this.referenceForm.get('description')?.setValue('NULL');
    }
    return gallery;
  }

  getCategory(): ReferenceCategoryModel | undefined {
    let categoryId = this.referenceForm.get('category')?.value;
    let category = this.categoryList.find((category) => {
      return category.id == categoryId
    });
    let gallery = category?.galleryMode || false;
    if (gallery) {
      this.referenceForm.get('place')?.setValue(null);
      this.referenceForm.get('description')?.setValue(null);
    }
    return category;
  }
}
