import { Component, OnInit } from '@angular/core';

import {UtilityService} from "../../../../../core/services/utility.service";
import {MachineModel, MachineTypeEnum2LabelMapping} from "../../../../../core/interfaces/machine.model";
import {MachineService} from "../../../../../core/services/machine.service";
import {faEye} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-machine-list',
  templateUrl: './machine-list.component.html',
  styleUrls: ['./machine-list.component.scss']
})
export class MachineListComponent implements OnInit {
  machineList: MachineModel[] = [];
  MachineTypeEnumMapping = MachineTypeEnum2LabelMapping;
  asc = false;
  previewIcon = faEye;

  constructor(
    private machineService: MachineService,
    private util: UtilityService
  ) { }

  ngOnInit(): void {
    this.getAllMachines();
  }
  getAllMachines(): void {
    this.machineService.getAll().subscribe(result => {
      if (result.length > 0) {
        this.machineList = result;
      } else {
        this.machineList = []
        console.log('Žádný záznam stroje neexistuje!!!')
      }
    });
  }
  sort(key: string) {
    const sorter = this.util.sortByKey(this.machineList, key, this.asc);
    this.machineList = sorter.object;
    this.asc = sorter.asc;
  }
}
