import { Timestamp } from 'firebase/firestore';
import {FileUploadModel} from "./fileUpload.model";

export class ReferenceModel {
  id = '';
  category = '';
  subCategory = '';
  name = '';
  previewImage = new FileUploadModel();
  mainImage: FileUploadModel[] = [];
  basicInfo  = '';
  place  = '';
  dateFrom = Timestamp.now();
  dateTo = Timestamp.now();
  description = '';
}
