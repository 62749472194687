import {Component, Input} from '@angular/core';

import {FileUploadModel} from 'src/app/core/interfaces/fileUpload.model';

@Component({
  selector: 'app-image-hover-info',
  templateUrl: './image-hover-info.component.html',
  styleUrls: ['./image-hover-info.component.scss']
})
export class ImageHoverInfoComponent  {
  @Input() image = new FileUploadModel();

  constructor(
  ) { }

  copyUrl(): void {
    navigator.clipboard.writeText(this.image.src).then();
  }
}
