import { Injectable } from '@angular/core';
import {Repository} from './repository.abstract';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {RepositoryOptionsInterface} from '../interfaces/repository-options.interface';
import {ClothesModel} from "../interfaces/clothes.model";
import {ClothingSizeModel} from "../interfaces/clothingSize.model";

@Injectable({
  providedIn: 'root'
})
export class ClothingSizeRepositoryService extends Repository {
  constructor(protected db: AngularFirestore) {
    super();
  }

  collectionName(options?: RepositoryOptionsInterface): string {
    if (options?.parentPath) {
      return options.parentPath;
    }
    return '';
  }

  fromDb(snapshot: any, options: any): ClothingSizeModel {
    const data = snapshot.data(options);
    const result = new ClothingSizeModel();
    Object.assign(result, data);
    result.id = data.id || snapshot.id;

    return result;
  }

  toDb(data: ClothesModel): object {
    return JSON.parse(JSON.stringify(data));
  }
}
