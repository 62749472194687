import { Component, Input } from '@angular/core';
import { ReferenceCategoryModel } from 'src/app/core/interfaces/referenceCategory.model';

@Component({
  selector: 'app-category-item',
  templateUrl: './category-item.component.html',
  styleUrls: ['./category-item.component.scss']
})
export class CategoryItemComponent {
  @Input() category = new ReferenceCategoryModel();

  constructor() { }
}
