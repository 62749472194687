import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {ReferencePlaceModel} from 'src/app/core/interfaces/referencePlace.model';
import {PlaceService} from 'src/app/core/services/place.service';
import {ErrorResolutionService} from 'src/app/core/services/errorResolution.service';
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-place-form',
  templateUrl: './place-form.component.html',
  styleUrls: ['./place-form.component.scss']
})
export class PlaceFormComponent implements OnInit {
  @Input() place = new ReferencePlaceModel();
  placeBackUp = new ReferencePlaceModel();
  placeForm!: FormGroup;
  icon = faPenToSquare;
  isNew = true;

  constructor(
    private placeService: PlaceService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private errorRes: ErrorResolutionService,
  ) { }

  ngOnInit(): void {
    if (this.place.id !== '') {
      this.isNew = false;
    }
    this.placeBackUp = Object.assign({}, this.place);
    this.buildForm();
  }

  buildForm(): void {
    this.placeForm = this.formBuilder.group({
      name: new FormControl(this.place.name || '', [Validators.required]),
      link: new FormControl(this.place.link || '', [Validators.required]),
    });
  }

  resetForm(sent:boolean): void {
    if (!sent) {
      this.place = Object.assign({}, this.placeBackUp);
    }
    this.buildForm();
  }

  sendData(): void {
    this.place.name = this.placeForm.get('name')?.value;
    this.place.link = this.placeForm.get('link')?.value;
    this.placeService.upsert(this.place);
  }

  openForm(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', scrollable: true, centered: true}).result.then(result => {
      this.sendData();
      this.resetForm(!this.isNew);
      console.log('Formulář uzavřen pomocí:' + result);
    }, (reason) => {
      this.resetForm(false);
      console.log(this.errorRes.getModalDismissReason(reason));
    });
  }
}
