import {Component, Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import { ReferenceCategoryModel } from '../../../../../../../core/interfaces/referenceCategory.model';
import {CategoryService} from '../../../../../../../core/services/category.service';
import {FileService} from '../../../../../../../core/services/file.service';
import {ErrorResolutionService} from '../../../../../../../core/services/errorResolution.service';
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-category-delete',
  templateUrl: './category-delete.component.html',
  styleUrls: ['./category-delete.component.scss']
})
export class CategoryDeleteComponent {
  @Input() category = new ReferenceCategoryModel();
  icon = faTrashCan;

  constructor(
    private categoryService: CategoryService,
    private fileService: FileService,
    private modalService: NgbModal,
    private errorRes: ErrorResolutionService,
  ) { }

  showAlert(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', scrollable: true, centered: true}).result.then(result => {
      if (result === 'delete') {
        this.deleteCategory();
        this.deleteFile();
      }
      console.log('Formulář uzavřen pomocí:' + result);
    }, (reason) => {
      console.log(this.errorRes.getModalDismissReason(reason));
    });
  }

  deleteCategory(): void {
    this.categoryService.delete(this.category.id);
  }

  deleteFile(): void {
    console.log('Zahajuji mazání souborů kategorie ' + this.category.name);
    this.fileService.deleteFolder('public/categories/' + this.category.id);
  }
}
