import { Injectable } from '@angular/core';
import {ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class ErrorResolutionService {

  constructor() {}

  getModalDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        return 'Formulář zrušen pomocí ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        return 'Formulář zrušen pomocí kliknutí do pozadí';
      case 'Cross click':
        return 'Formulář zrušen pomocí kliknutí na křížek';
      default:
        return 'Formulář zrušen pomocí: ' + reason;
    }
  }
  getAuthErrorReason(code: string): string {
    switch (code) {
      case 'auth/wrong-password' || 'auth/user-not-found':
        return 'Zadaný e-mail, nebo heslo není správé!';
      case 'auth/too-many-requests':
        return 'Překročen limit pokusů o přihlášení!';
      case 'auth/network-request-failed':
        return 'Nepodařilo se navázat spojení se serverem!'
      case 'auth/missing-email':
        return 'Nezadali jste email!'
      case 'auth/weak-password':
        return 'Heslo je slabé! musí být nejméně 6 znaků dlouhé!'
      default:
        return 'Neznámá chyba!'
    }
  }
}
