<div>
  <h2 class="mb-3 text-center">Informace o firmě</h2>
  <form [formGroup]="companyForm" class="row">
    <div class="col-12 col-sm-6">
      <div class="col form-floating form-group mb-3">
        <input formControlName="name" name="name" id="name" class="form-control" placeholder="nothing" required>
        <label for="name">Název firmy:</label>
      </div>
      <div class="col form-floating form-group mb-3">
        <input formControlName="in" (change)="onInChange()" name="in" id="in" class="form-control" placeholder="nothing" required>
        <label for="in">IČ:</label>
      </div>
      <div class="col form-floating form-group mb-3">
        <input formControlName="taxNumber" name="taxNum" id="taxNum" class="form-control" placeholder="nothing" required>
        <label for="taxNum">DIČ:</label>
      </div>
      <div class="col form-floating form-group mb-3">
        <input formControlName="dataBox" name="dataBox" id="dataBox" class="form-control" placeholder="nothing" required>
        <label for="dataBox">Datová schránka:</label>
      </div>
    </div>
    <div class="col-12 col-sm-6">
      <div class="col form-floating form-group mb-3">
        <input formControlName="address1" name="address1" id="address1" class="form-control" placeholder="nothing" required>
        <label for="address1">Adresa 1 řádek:</label>
      </div>
      <div class="col form-floating form-group mb-3">
        <input formControlName="address2" name="address2" id="address2" class="form-control" placeholder="nothing" required>
        <label for="address2">Adresa 2 řádek:</label>
      </div>
      <div class="col form-floating form-group mb-3">
        <input formControlName="email" name="email" id="email" class="form-control" placeholder="nothing" required>
        <label for="email">Kontaktní e-mail:</label>
      </div>
      <div class="col form-floating form-group mb-3">
        <input formControlName="phoneNumber" (change)="onTelChange()" name="phone" id="phone" class="form-control" placeholder="nothing" required>
        <label for="phone">Tel. číslo:</label>
      </div>
    </div>
  </form>
  <div class="col-12">
    <button (click)="onSubmit()" class="btn btn-lg btn-gradient-custom w-100" [innerHTML]="btnText"></button>
  </div>
</div>
