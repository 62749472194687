<div class="row">
  <header>
    <div class="container-fluid text-center position-relative top-50 start-50 translate-middle">
      <h1>{{headerLine1}}</h1>
      <h2>{{headerLine2}}</h2>
        <button type="submit" class="btn fs-4 mx-4 my-4 btn-primary" (click)="btnAction()">
          {{buttonTitle}}
        </button>
    </div>
  </header>
</div>
