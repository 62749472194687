import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { Observable } from 'rxjs';
import {UtilityService} from "../services/utility.service";

@Injectable({
  providedIn: 'root',
})

export class RedirectService implements CanActivate {
  constructor(
    private utilityService: UtilityService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if(next.routeConfig && next.routeConfig.data && next.routeConfig.data!['redirectUrl']) {
      this.utilityService.openSite(next.routeConfig!.data!['redirectUrl']);
    } else {
      console.error("Pro přesměrování chybí parametr 'redirectUrl'!")
    }
    return false;
  }
}
