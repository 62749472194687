import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {ReferenceModel} from 'src/app/core/interfaces/reference.model';
import {ReferencePlaceModel} from 'src/app/core/interfaces/referencePlace.model';

import {ReferenceService} from 'src/app/core/services/reference.service';
import {PlaceService} from 'src/app/core/services/place.service';
import {FileService} from 'src/app/core/services/file.service';

import {SetupService} from "../../../../../../core/services/setup.service";
import {NgbCarousel} from "@ng-bootstrap/ng-bootstrap";
import {UtilityService} from "../../../../../../core/services/utility.service";
import {DataService} from "../../../../../../core/services/data.service";
import {CompanyInfoModel} from "../../../../../../core/interfaces/companyInfo.model";

@Component({
  selector: 'app-reference-detail',
  templateUrl: './reference-detail.component.html',
  styleUrls: ['../references.component.scss']
})
export class ReferenceDetailComponent implements OnInit {
  reference = new ReferenceModel();
  referencePlace = new ReferencePlaceModel();

  referenceDateFrom = '';
  referenceDateTo = '';
  contactData = new CompanyInfoModel();

  headerL1 = '';
  headerL2 = '';
  btnType = '';

  constructor(
    private fileService: FileService,
    private referenceService: ReferenceService,
    private utilityService: UtilityService,
    private placeService: PlaceService,
    private route: ActivatedRoute,
    private setup: SetupService,
    private dataService: DataService,
  ) { }

  ngOnInit(): void {
    this.referenceSetup();
  }

  referenceSetup(): void {
    this.dataService.getCompanyInfo().subscribe(result => {
      this.contactData = result.data();
    });
    // Vytáhne požadovanou ID z URL
    const id = String(this.route.snapshot.paramMap.get('id'));
    // Vyhledá v databázi data pro požadovanou referenci
    this.referenceService.getById(id).subscribe(result => {
      // Data reference nalezena
      if (result.data()) {
        // Vysypaní získaných dat do proměnné reference
        this.reference = result.data();
        // Nastaví title
        this.setup.setTitle(this.reference.name);
        // převádí Timestamp na string v požadovaném formátu
        this.referenceDateFrom = this.utilityService.timestampToString(this.reference.dateFrom);
        this.referenceDateTo = this.utilityService.timestampToString(this.reference.dateTo);
        // Získání dat o místu
        this.placeSetup(this.reference.place);
        // Nastavení proměnných headeru na požadovanou hodnotu
        this.headerL1 = this.reference.name;
        this.headerL2 = this.reference.basicInfo;
        this.btnType = 'contects';
      // Data reference nenalezena
      } else {
        // Nastavení proměnných headeru na požadovanou hodnotu
        this.headerL1 = 'Jeejda!';
        this.headerL2 = 'Tudy cesta nevede...';
        this.btnType = 'back';
      }
    });
  }
  placeSetup(id: string): void {
    this.placeService.getById(id).subscribe(result => {
      if (result.data()) {
        this.referencePlace = result.data();
      }
    });
  }

  @ViewChild('carousel', {static : true}) carousel!: NgbCarousel;
}
