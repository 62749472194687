<nav class="row navbar navbar-expand-lg navbar-light bg-white shadow-lg">
  <div class="container-fluid">
    <div class="col-12 col-lg-3">
      <div class="row">
        <div class="col-6">
          <a class="navbar-brand" routerLink="/home"><img class="nav-logo" src="../../../../assets/images/logo.svg" alt="Logo" /></a>
        </div>
        <div class="col-6 text-end">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </div>
    </div>
    <div class="col-6 collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto text-lg-end text-center">
        <li class="nav-item">
          <a class="nav-link mx-2 my-3 fs-5 text-nowrap" routerLink="/home" routerLinkActive="active">Domů</a>
        </li>
        <li class="nav-item">
          <a class="nav-link mx-2 my-3 fs-5 text-nowrap" routerLink="/references" routerLinkActive="active">Služby a reference</a>
        </li>
        <li class="nav-item">
          <a class="nav-link mx-2 my-3 fs-5 text-nowrap" routerLink="/contacts" routerLinkActive="active">Kontakty</a>
        </li>
        <li class="nav-item d-lg-none">
          <div class="social">
            <div class="col my-3">
              <a aria-label="Odkaz na facebook" href=" https://www.facebook.com/Geo-stav-Vale%C4%8D-sro-2346242055396113/" target="_blank"><fa-icon class="fs-5 mx-4" [icon]="Facebook"></fa-icon></a>
              <a aria-label="Odkaz na instagarm" href="https://www.instagram.com/geostav_valec/" target="_blank"><fa-icon class="fs-5 mx-4" [icon]="Instagram"></fa-icon></a>
              <a aria-label="Emailový kontakt" href="mailto:{{contactData.email}}"><fa-icon class="fs-5 mx-4" [icon]="Mail"></fa-icon></a>
              <a aria-label="Telefonický kontakt" href="tel:+420{{contactData.phoneNumber}}"><fa-icon class="fs-5 mx-4" [icon]="Phone"></fa-icon></a>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-3 social collapse navbar-collapse">
      <div class="col text-lg-end text-center my-3">
        <a aria-label="Odkaz na facebook" href=" https://www.facebook.com/Geo-stav-Vale%C4%8D-sro-2346242055396113/" target="_blank"><fa-icon class="fs-5 mx-2" [icon]="Facebook"></fa-icon></a>
        <a aria-label="Odkaz na instagarm" href="https://www.instagram.com/geostav_valec/" target="_blank"><fa-icon class="fs-5 mx-2" [icon]="Instagram"></fa-icon></a>
        <a aria-label="Emailový kontakt" href="mailto:{{contactData.email}}"><fa-icon class="fs-5 mx-2" [icon]="Mail"></fa-icon></a>
        <a aria-label="Telefonický kontakt" href="tel:+420{{contactData.phoneNumber}}"><fa-icon class="fs-5 mx-2" [icon]="Phone"></fa-icon></a>
      </div>
    </div>
  </div>
</nav>
