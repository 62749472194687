<mat-sidenav-container>
  <mat-sidenav #sidenav="matSidenav">
    <div class="mav-logo">
      <a routerLink="/admin"><img class="logo" src="assets/images/logo-white.svg" alt="logo"/></a>
      <mat-divider></mat-divider>
    </div>
    <div class="menu-items">
      <span *ngFor="let menuItem of menuItems">
        <button *ngIf="!menuItem.withChildItem" (click)="highlightParent()" routerLinkActive="active-button" [routerLink]=menuItem.routerLink mat-button class="menu-button">
          <mat-icon>{{menuItem.icon}}</mat-icon>
          <span>{{menuItem.title}}</span>
        </button>
        <button *ngIf="menuItem.withChildItem" (click)="slideOpen(menuItem.identifier)" mat-button [attr.id]="menuItem.identifier" class="menu-button">
          <mat-icon>{{menuItem.icon}}</mat-icon>
          <div class="dropdown-menu-button-text">
            <span>{{menuItem.title}}</span>
            <div>
              <mat-icon [attr.id]="menuItem.identifier + '-arrow-down'" class="d-inline-block">arrow_right</mat-icon>
              <mat-icon [attr.id]="menuItem.identifier + '-arrow-up'" class="d-none">arrow_drop_down</mat-icon>
            </div>
          </div>
        </button>
        <span *ngIf="menuItem.withChildItem" [attr.id]="'sub-' + menuItem.identifier">
          <button *ngFor="let child of menuItem.children" (click)="highlightParent(menuItem.identifier)" routerLinkActive="active-button" [routerLink]=child.routerLink mat-button [attr.id]="menuItem.identifier + '-child'" class="menu-button">
            <mat-icon>{{child.icon}}</mat-icon>
            <span>{{child.title}}</span>
          </button>
        </span>
      </span>
    </div>
    <div>
      <mat-divider></mat-divider>
      <p class="copyright">©{{date | async | date: 'yyyy'}} Geo-stav Valeč s.r.o.</p>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="toolbar mat-elevation-z8">
      <button mat-icon-button *ngIf="sidenav.mode === 'over'" (click)="sidenav.toggle()">
        <mat-icon *ngIf="!sidenav.opened">menu</mat-icon>
        <mat-icon *ngIf="sidenav.opened">close</mat-icon>
      </button>
      <ng-container *ngIf="sidenav.mode !== 'over'">
        <h5 class="mb-0 fw-normal">{{getDayName()}} {{date | async | date: 'dd.MM.yyyy HH:mm:ss'}}</h5>
      </ng-container>
      <span fxFlex></span>
      <ng-container>
        <h6 [matMenuTriggerFor]="menu" class="toolbar-text text-white"
            *ngIf="authService.appUser as user">{{(user.displayName)}}</h6>
        <button [matMenuTriggerFor]="menu" mat-icon-button class="login-avatar">
          <mat-icon>person</mat-icon>
        </button>
      </ng-container>
      <mat-menu #menu="matMenu">
<!--        <button mat-menu-item>Nastavení</button>-->
        <button mat-menu-item (click)="logout()">Odhlásit</button>
      </mat-menu>
    </div>
    <div class="content mat-elevation-z8">
      <div class="router-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
