import {Component, Input} from '@angular/core';
import {ReferenceModel} from 'src/app/core/interfaces/reference.model';

@Component({
  selector: 'app-reference-item',
  templateUrl: './reference-item.component.html',
  styleUrls: ['./reference-item.component.scss']
})
export class ReferenceItemComponent {
  @Input() reference = new ReferenceModel();

  constructor() { }
}
