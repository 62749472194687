import { Component } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent {

  constructor(
    public location: Location,
  ) { }

}
