<ng-container *ngIf="mode === 0">
  <td (click)="openModal(previewModal)" class="text-nowrap pointer"><p class="mt-1 mb-0">{{attendance.id}}</p></td>
  <td (click)="openModal(previewModal)" class="text-nowrap d-none d-sm-table-cell d-lg-none d-xl-table-cell pointer text-center"><p class="mt-1 mb-0" [ngbTooltip]="vacationTip">{{attendance.vacationHoursAtStart}} h -> {{attendance.vacationHoursAtStart - attendance.vacationHoursDuring}} h</p></td>
  <td (click)="openModal(previewModal)" class="text-nowrap text-center d-none d-sm-table-cell pointer"><p class="mt-1 mb-0">{{attendance.workHours}} h</p></td>
  <td (click)="openModal(previewModal)" class="text-nowrap text-end pointer d-none d-sm-table-cell"><p class="mt-1 mb-0">{{attendance.salary + attendance.salaryBonus}} Kč</p></td>
  <td class="d-flex justify-content-end"><button class="btn btn-sm btn-success export-button" (click)="export(attendance)"><mat-icon>table_view</mat-icon><span>Export</span></button></td>
</ng-container>
<ng-container *ngIf="mode === 1">
  <th scope="row" class="sticky text-nowrap"><a routerLink="/admin/employees/list/profile/{{employee.id}}" [queryParams]="{tab: 2}">{{employee.lastName}} {{employee.firstName}}</a></th>
  <td (click)="openModal(previewModal)" class="pointer text-nowrap">{{attendance.salary + attendance.salaryBonus}} Kč</td>
  <td (click)="openModal(previewModal)" class="pointer">{{salaryService.getAmountOfBills(attendance.salary + attendance.salaryBonus, 5000, haveMoney)}} ks</td>
  <td (click)="openModal(previewModal)" class="pointer">{{salaryService.getAmountOfBills(attendance.salary + attendance.salaryBonus, 2000, haveMoney)}} ks</td>
  <td (click)="openModal(previewModal)" class="pointer">{{salaryService.getAmountOfBills(attendance.salary + attendance.salaryBonus, 1000, haveMoney)}} ks</td>
  <td (click)="openModal(previewModal)" class="pointer">{{salaryService.getAmountOfBills(attendance.salary + attendance.salaryBonus, 500, haveMoney)}} ks</td>
  <td (click)="openModal(previewModal)" class="pointer">{{salaryService.getAmountOfBills(attendance.salary + attendance.salaryBonus, 200, haveMoney)}} ks</td>
  <td (click)="openModal(previewModal)" class="pointer">{{salaryService.getAmountOfBills(attendance.salary + attendance.salaryBonus, 100, haveMoney)}} ks</td>
  <td (click)="openModal(previewModal)" class="pointer">{{salaryService.getAmountOfBills(attendance.salary + attendance.salaryBonus, 50, haveMoney)}} ks</td>
  <td (click)="openModal(previewModal)" class="pointer">{{salaryService.getAmountOfBills(attendance.salary + attendance.salaryBonus, 20, haveMoney)}} ks</td>
  <td (click)="openModal(previewModal)" class="pointer">{{salaryService.getAmountOfBills(attendance.salary + attendance.salaryBonus, 10, haveMoney)}} ks</td>
  <td (click)="openModal(previewModal)" class="pointer">{{salaryService.getAmountOfBills(attendance.salary + attendance.salaryBonus, 5, haveMoney)}} ks</td>
</ng-container>
<ng-template #vacationTip>
  {{attendance.vacationHoursDuring}} h
</ng-template>
<ng-template #previewModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{attendanceService.getMonth(attendance.id)}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <table class="table">
      <thead>
        <tr>
          <th>Datum</th>
          <th class="d-none d-sm-table-cell text-center">Den</th>
          <th class="d-none d-sm-table-cell text-end">Hodiny</th>
          <th class="d-table-cell d-sm-none text-end">Hod.</th>
          <th class="text-center">Místo</th>
          <th class="d-none d-lg-table-cell">Popis</th>
          <th class="text-end">Kč</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let day of attendance.days"
            class="{{day.place === 'Víkend'? 'text-muted':''}}
                   {{day.place === 'Dovolená'? 'text-primary':''}}
                   {{day.place === 'Nemoc'? 'text-danger':''}}
                   {{day.place === 'Svátek'? 'text-warning':''}}">
          <td>{{day.date}}</td>
          <td class="d-none d-sm-table-cell text-center">{{attendanceService.getDay(day.date)}}</td>
          <td class="text-end">{{day.hours}} h</td>
          <td class="text-center">{{day.place}}</td>
          <td class="d-none d-lg-table-cell"><i>{{day.activity}}</i></td>
          <td class="text-end">
            <span class="text-nowrap" placement="start" [ngbTooltip]="tipContent">{{salaryService.calculateSalaryForDay(day.place, day.hours, day.multiplier, attendance.currentSalary)}} Kč</span>
            <ng-template #tipContent>
                Stavba: {{attendance.currentSalary.construction}} Kč/h<br>
                Dílna: {{attendance.currentSalary.workshop}} Kč/h<br>
                Jiné: {{attendance.currentSalary.other}} Kč/h<br>
            </ng-template>
          </td>
        </tr>
        <tr class="bg-primary bg-opacity-75">
          <td class="d-none d-sm-table-cell "></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="d-none d-lg-table-cell"></td>
          <td></td>
        </tr>
        <tr>
          <td>Hodiny</td>
          <td class="text-center d-none d-lg-table-cell"></td>
          <td class="text-center d-table-cell d-sm-none" colspan="2">{{attendance.workHours}} h</td>
          <td class="d-none d-sm-table-cell text-end">(P) {{attendance.workHours}} h</td>
          <td class="d-none d-sm-table-cell text-center text-primary">(D) {{attendance.vacationHoursDuring}} h</td>
          <td class="d-none d-sm-table-cell text-danger">(N) {{attendance.sickHours}} h</td>
          <td class="text-end text-nowrap">{{attendance.salary}} Kč</td>
        </tr>
        <tr>
          <td>Odměny</td>
          <td class="d-none d-lg-table-cell text-center"></td>
          <td class="d-none d-sm-table-cell text-end"></td>
          <td class="text-center"></td>
          <td></td>
          <td class="text-end text-nowrap">{{attendance.salaryBonus}} Kč</td>
        </tr>
        <tr>
          <th scope="row">Celkem</th>
          <th scope="row" class="d-none d-lg-table-cell text-center"></th>
          <th scope="row" class="d-none d-sm-table-cell text-end"></th>
          <th scope="row" class="text-center"></th>
          <th scope="row"></th>
          <th scope="row" class="text-end text-nowrap">{{attendance.salary + attendance.salaryBonus}} Kč</th>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button (click)="modal.close('Save click')"  type="button" class="btn btn-primary">Zavřít</button>
  </div>
</ng-template>
