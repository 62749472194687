<h5>Docházka</h5>
<div *ngIf="attendanceList[0]" class="table-div">
  <div class="tableFixHead">
  <table class="mb-0 table table-striped">
    <thead>
    <tr>
      <th>Měsíc</th>
      <th class="d-none d-sm-table-cell d-lg-none d-xl-table-cell text-center">Dovolená</th>
      <th class="text-center d-none d-sm-table-cell">Práce</th>
      <th class="text-end d-none d-sm-table-cell">Výplata</th>
      <th class="text-end">Operace</th>
    </tr>
    </thead>
    <tbody>
    <tr attendance-modal *ngFor="let doc of attendanceList" [mode]="0" [attendance]="doc" [employeeID]="employeeID"></tr>
    </tbody>
  </table>
  </div>
</div>
<ng-container *ngIf="!attendanceList[0]">
  <h3 class="text-center text-danger my-5">Nebyl nalezen žádný záznam docházky!</h3>
</ng-container>
