import {Component, Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {ErrorResolutionService} from 'src/app/core/services/errorResolution.service';
import {ClothesModel} from "../../../../../../../../core/interfaces/clothes.model";
import {faEye} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-clothing-preview',
  templateUrl: './clothing-preview.component.html',
  styleUrls: ['./clothing-preview.component.scss']
})
export class ClothingPreviewComponent {
  @Input() clothing = new ClothesModel();
  icon = faEye;

  constructor(
    private modalService: NgbModal,
    private errorRes: ErrorResolutionService,
  ) { }

  showPlace(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', scrollable: true, centered: true}).result.then(result => {
      console.log('Formulář uzavřen pomocí:' + result);
    }, (reason) => {
      console.log(this.errorRes.getModalDismissReason(reason));
    });
  }
}
