<h2 class="card-title text-center">Kategorie</h2>
<hr class="d-block d-sm-none">
<div class="tableFixHead">
<table class="table table-hover text-center text-sm-start">
  <thead>
    <tr>
      <th scope="col" class="d-none d-lg-table-cell">#</th>
      <th scope="col" class="d-none d-sm-table-cell d-lg-none">Kategorie</th>
      <th scope="col" class="d-none d-lg-table-cell sorter" (click)="sort('name')">Název<mat-icon>sort</mat-icon></th>
      <th scope="col" class="d-none d-xl-table-cell sorter" (click)="sort('link')">Link<mat-icon>sort</mat-icon></th>
      <th scope="col" class="d-none d-lg-table-cell">Obrázek</th>
      <th scope="col" class="d-none d-sm-table-cell">Operace</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="categoryList[0]">
      <tr *ngFor="let category of categoryList">
        <th scope="row" class="d-none d-lg-table-cell align-middle">{{ category.priority }}</th>
        <td class="d-none d-sm-table-cell align-middle">
          <fa-icon class="me-2" [icon]="category.galleryMode? galleryIcon:classicIcon"></fa-icon>
          <a routerLink="{{category.link}}"><app-name-hover-id [object]="category"></app-name-hover-id></a> <br class="d-table-cell d-lg-none">
          <app-image-hover-info [image]="category.file" class="d-table-cell d-lg-none"></app-image-hover-info>
        </td>
        <td class="d-none d-xl-table-cell align-middle">
          <a routerLink="/references/category/{{category.link}}" target="_blank">{{category.link}}</a>
        </td>
        <td class="d-none d-lg-table-cell align-middle"><app-image-hover-info [image]="category.file"></app-image-hover-info></td>
        <td class="text-nowrap align-middle">
          <p class="d-sm-none justify-content-around">
            <fa-icon [icon]="category.galleryMode? galleryIcon:classicIcon"></fa-icon> {{ category.name }}<br>
            <app-image-hover-info [image]="category.file"></app-image-hover-info>
          </p>
          <div class="mt-2 mt-lg-auto">
            <a class="btn btn-sm btn-primary mx-1" routerLink="/references/category/{{category.link}}" [ngbTooltip]="viewTip" target="_blank"><fa-icon [icon]="previewIcon"></fa-icon></a>
            <app-category-form [category]="category" [ngbTooltip]="editTip"></app-category-form>
            <a class="btn btn-sm btn-warning mx-1" routerLink="{{category.link}}" [ngbTooltip]="addTip"><fa-icon [icon]="addReferenceIcon"></fa-icon></a>
            <app-category-delete [category]="category" [ngbTooltip]="deleteTip"></app-category-delete>
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
</div>
<app-category-form class="d-grid"></app-category-form>
<ng-template #viewTip>
  <div class="fs-7">
    Zobrazit
  </div>
</ng-template>
<ng-template #addTip>
  <div class="fs-7">
    Přidat referenci
  </div>
</ng-template>
<ng-template #deleteTip>
  <div class="fs-7">
    Odstranit
  </div>
</ng-template>

<ng-template #editTip>
  <div class="fs-7">
    Upravit
  </div>
</ng-template>
