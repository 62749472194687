import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from '@angular/fire/storage';
import {FileUploadModel} from '../interfaces/fileUpload.model';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import { Timestamp } from 'firebase/firestore';
@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(
    private storage: AngularFireStorage
  ) {
  }

  upload(file: any, fileDir: string = '', publicFile = false): Observable<FileUploadModel | number> {
    const storage = getStorage();
    let fullPath: string;
    if(publicFile){
      fullPath = 'public' + fileDir + '/' + file.name;
    } else {
      fullPath = 'private' + fileDir + '/' + file.name;
    }
    const storageRef = ref(storage, fullPath);
    const uploadTask = uploadBytesResumable(storageRef, file);
    const fileDataObject: FileUploadModel = {
      name: file.name,
      size: file.size,
      src: '',
      uploadTime: Timestamp.now()
    };
    return new Observable<FileUploadModel | number>(subscriber => {
      console.log('Začínám nahrávat nový soubor...');
      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          subscriber.next(progress);
          console.log('Nahrávání ' + file.name + ': ' + progress + '%');
        },
        (error) => {
          switch (error.code) {
            case 'storage/unauthorized':
              break;
            case 'storage/canceled':
              break;
            case 'storage/unknown':
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            fileDataObject.src = downloadURL;
            subscriber.next(fileDataObject);
            console.log('Soubor byl úspěšně nahrán, přeposílám jeho data...');
          });
        }
      );
    });
  }
  delete(downloadUrl:string) {
    return this.storage.storage.refFromURL(downloadUrl).delete();
  }
  deleteFolder(folderPath: string): void {
    console.log('Mažu složku ' + folderPath);
    // Vypíše všechny soubory složky
    this.storage.storage.ref(folderPath).listAll().then(data => {
      // Projde jednotlivé soubory
      data.items.forEach(item => {
        // Soubor smaže
        console.log('Mažu soubor ' + item.name);
        item.delete().then();
      });
    });
  }
  getFileType(name: string): string {
    const re = /(?:\.([^.]+))?$/;
    let ext = re.exec(name)![1];
    switch(ext.toLowerCase()) {
      case 'pdf':
        return 'pdf';
      case 'doc':
      case 'docx':
        return 'word';
      case 'xls':
      case 'xlsx':
        return 'excel';
      case 'ppt':
      case 'pptx':
        return 'powerpoint';
      case 'png':
      case 'jpg':
      case 'jpeg':
        return 'image';
      case 'mov':
      case 'mp4':
      case 'avi':
        return 'video';
      case 'mp3':
        return 'audio';
      case 'zip':
      case 'rar':
        return 'archive';
      default:
        return 'other';
    }
  }
  formatBytes(a: number, b = 2): string {
    if (!+a) return "0 Bytes";
    const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]}`
  }
}
