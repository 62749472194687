<h2 class="card-title text-center">{{category.name || 'Reference'}}</h2>
<hr class="d-block d-sm-none">
<div class="tableFixHead">
<table class="table table-hover text-center text-sm-start">
  <thead>
  <tr>
    <th scope="col" class="d-none d-xl-table-cell">#</th>
    <th scope="col" class="d-none d-sm-table-cell d-lg-none">Reference</th>
    <th scope="col" class="d-none d-lg-table-cell sorter" (click)="sort('name')">Název<mat-icon>sort</mat-icon></th>
    <th *ngIf="!category.galleryMode" scope="col" class="d-none d-lg-table-cell sorter" (click)="sort('place')">Místo<mat-icon>sort</mat-icon></th>
    <th *ngIf="!category.id" scope="col" class="d-none d-lg-table-cell sorter" (click)="sort('category')">Kategorie<mat-icon>sort</mat-icon></th>
    <th *ngIf="category.id" scope="col" class="d-none d-lg-table-cell sorter">Zák. informace</th>
    <th scope="col" class="d-none d-sm-table-cell">Operace</th>
  </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="referenceList[0]">
      <tr *ngFor="let reference of referenceList; index as i">
        <th scope="row" class="d-none d-xl-table-cell align-middle">{{ i + 1 }}</th>
        <td class="d-none d-sm-table-cell align-middle">
          <app-name-hover-id [object]="reference"></app-name-hover-id><br class="d-table-cell d-lg-none">
          <app-place-name *ngIf="!category.galleryMode" [placeID]="reference.place" class="d-table-cell d-lg-none"></app-place-name>
        </td>
        <td *ngIf="!category.galleryMode" class="d-none d-lg-table-cell align-middle"><app-place-name [placeID]="reference.place"></app-place-name></td>
        <td *ngIf="!category.id"  class="d-none d-lg-table-cell align-middle"><app-category-name [categoryID]="reference.category"></app-category-name></td>
        <td *ngIf="category.id" class="d-none d-lg-table-cell align-middle">{{reference.basicInfo}}</td>
        <td class="text-nowrap align-middle">
          <p class="d-sm-none justify-content-around">
            {{ reference.name }}<br>
            <app-place-name *ngIf="!category.galleryMode" [placeID]="reference.place"></app-place-name>
          </p>
          <div class="mt-2 mt-lg-auto">
            <a
              [style.pointer-events]="reference.place==='' ? 'none' : 'auto'"
              [ngClass]="{'btn-primary': reference.place!=='', 'btn-secondary': reference.place===''}"
              class="btn btn-sm mx-1"
              routerLink="/references/id/{{reference.id}}"
              [ngbTooltip]="viewTip"
              target="_blank"><fa-icon [icon]="previewIcon"></fa-icon>
            </a>
            <app-reference-form [reference]="reference" [ngbTooltip]="editTip"></app-reference-form>
            <app-reference-delete [reference]="reference" [ngbTooltip]="deleteTip"></app-reference-delete>
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
</div>
<app-reference-form *ngIf="category.id" [categoryId]="category.id" class="d-grid"></app-reference-form>
<app-reference-form *ngIf="!category.id" class="d-grid"></app-reference-form>
<ng-template #viewTip>
  <div class="fs-7">
    Zobrazit
  </div>
</ng-template>
<ng-template #deleteTip>
  <div class="fs-7">
    Odstranit
  </div>
</ng-template>
<ng-template #editTip>
  <div class="fs-7">
    Upravit
  </div>
</ng-template>
