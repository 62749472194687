import {MenuItem} from "../interfaces/menuItem.model"

export const adminMenuItems: MenuItem[] = [
  {
    title: 'Přehled',
    icon: 'home',
    routerLink: 'dashboard',
    withChildItem: false,
    identifier: '',
    permissionRequired: '',
    children: [],
  },
  {
    title: 'Obsah',
    icon: 'content_paste',
    routerLink: '',
    withChildItem: true,
    identifier: 'contacts',
    permissionRequired: '',
    children: [
      {
        title: 'Firemní údaje',
        icon: 'apartment',
        routerLink: 'content/companyInfo',
        withChildItem: false,
        identifier: '',
        permissionRequired: 'siteAdmin',
        children: [],
      },
      {
        title: 'Kontaktní osoby',
        icon: 'groups',
        routerLink: 'content/contactPeople',
        withChildItem: false,
        identifier: '',
        permissionRequired: 'siteAdmin',
        children: [],
      },
      {
        title: 'Certifikáty',
        icon: 'card_membership',
        routerLink: 'content/companyCertificates',
        withChildItem: false,
        identifier: '',
        permissionRequired: 'siteAdmin',
        children: [],
      },
    ],
  },
  {
    title: 'Reference',
    icon: 'library_books',
    routerLink: '',
    withChildItem: true,
    identifier: 'reference',
    permissionRequired: '',
    children: [
      // {
      //   title: 'Reference',
      //   icon: 'newspaper',
      //   routerLink: 'references/reference',
      //   withChildItem: false,
      //   identifier: '',
      //   permissionRequired: 'siteAdmin',
      //   children: [],
      // },
      {
        title: 'Kategorie',
        icon: 'category',
        routerLink: 'references/category',
        withChildItem: false,
        identifier: '',
        permissionRequired: 'siteAdmin',
        children: [],
      },
      {
        title: 'Místa',
        icon: 'public',
        routerLink: 'references/place',
        withChildItem: false,
        identifier: '',
        permissionRequired: 'siteAdmin',
        children: [],
      },
    ],
  },
  {
    title: 'Zaměstnanci',
    icon: 'badge',
    routerLink: '',
    withChildItem: true,
    identifier: 'employees',
    permissionRequired: '',
    children: [
      {
        title: 'Seznam',
        icon: 'engineering',
        routerLink: 'employees/list',
        withChildItem: false,
        identifier: '',
        permissionRequired: 'employeeAdmin',
        children: [],
      },
      {
        title: 'Docházka',
        icon: 'pin',
        routerLink: 'employees/attendance',
        withChildItem: false,
        identifier: '',
        permissionRequired: 'attendanceAdmin',
        children: [],
      },
      {
        title: 'Oblečení',
        icon: 'checkroom',
        routerLink: 'employees/clothes',
        withChildItem: false,
        identifier: '',
        permissionRequired: 'employeeAdmin',
        children: [],
      },
    ],
  },
  {
    title: 'Technika',
    icon: 'front_loader',
    routerLink: '',
    withChildItem: true,
    identifier: 'machines',
    permissionRequired: '',
    children: [
      {
        title: 'Seznam',
        icon: 'list',
        routerLink: 'machines/list',
        withChildItem: false,
        identifier: '',
        permissionRequired: 'machinesAdmin',
        children: [],
      },
      {
        title: 'GPS',
        icon: 'share_location',
        routerLink: 'employees/gps',
        withChildItem: false,
        identifier: '',
        permissionRequired: 'machinesAdmin',
        children: [],
      },
    ],
  },
  {
    title: 'Uživatelé',
    icon: 'admin_panel_settings',
    routerLink: 'users',
    withChildItem: false,
    identifier: '',
    permissionRequired: 'userAdmin',
    children: [],
  },
];
