import {Component, OnInit} from '@angular/core';
import {MonthlyAttendance} from "../../../../../../../../../core/interfaces/employeeAttendance.model";
import {AttendanceService} from "../../../../../../../../../core/services/attendance.service";
import {ActivatedRoute} from "@angular/router";
import {ExcelService} from "../../../../../../../../../core/services/excel.service";
import {EmployeeService} from "../../../../../../../../../core/services/employee.service";

@Component({
  selector: 'app-employee-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss']
})
export class AttendanceComponent implements OnInit {
  attendanceList: MonthlyAttendance[] = [];
  employeeID = String(this.route.snapshot.paramMap.get('id'));

  constructor(
    private excelService: ExcelService,
    private employeeService: EmployeeService,
    private service: AttendanceService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getAll();
  }

  getAll(): void {
    this.service.getAllForEmployee(this.employeeID).subscribe(result => {
      if (result.length > 0) {
        this.attendanceList = result.reverse();
      } else {
        console.log('Žádný záznam docházky neexistuje!!!')
      }
    });
  }
}
