import { Timestamp } from 'firebase/firestore';
import {FileUploadModel} from "./fileUpload.model";

export class SalaryModel {
  construction = 145;
  workshop = 145;
  other = 0;
}

export class EmployeeModel {
  id = '';
  firstName = '';
  lastName = '';
  rc = '';
  tel = '';
  email = '';
  address = '';
  entryDate = Timestamp.now();
  fireDate = Timestamp.fromDate(new Date());
  vacationHours = 225;
  defaultBonus = 5000;
  defaultHours = 8;
  salary = new SalaryModel();
  active = true;
  note = '';
  files: FileUploadModel[] = [];
}
