<button *ngIf="!isNew" class="btn btn-sm btn-success mx-1" (click)="openForm(addForm)"><fa-icon [icon]="icon"></fa-icon></button>
<button *ngIf="isNew" class="btn btn-lg btn-gradient-custom" (click)="openForm(addForm)">Přidat stroj</button>
<ng-template #addForm let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{isNew ? 'Vytvořit' : 'Upravit'}} stroj</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="machineForm">
      <div class="col form-floating form-group mb-3">
        <input formControlName="manufacturer" id="manufacturer" class="form-control" placeholder="nothing" required>
        <label for="manufacturer">Výrobce:</label>
      </div>
      <div class="col form-floating form-group mb-3">
        <input formControlName="model" id="model" class="form-control" placeholder="nothing" required>
        <label for="model">Model:</label>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 form-group mb-3">
          <select
            formControlName="type"
            class="form-control form-control-lg"
            required>
            <option [value]="null" disabled>Typ:</option>
            <ng-container *ngFor="let type of types">
              <option [value]="type">
                {{MachineTypeEnumMapping[type]}}
              </option>
            </ng-container>
          </select>
        </div>
        <div class="col-12 col-sm-6 form-group mb-3">
          <select
            formControlName="operator"
            class="form-control form-control-lg"
            >
            <option [value]=null>Operátor:</option>
            <ng-container *ngFor="let employee of employeeList">
              <option [value]="employee.id">
                {{employee.lastName}} {{employee.firstName}}
              </option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 form-floating mb-3">
          <input formControlName="purchaseDate" id="purchaseDate" type="date" class="form-control" placeholder="XX.XX.XXXX" required>
          <label for="purchaseDate" class="mx-4">Datum pořízení:</label>
        </div>
        <div class="col-12 col-sm-6 form-group mb-3">
          <div class="form-floating form-group">
            <input formControlName="gpsId" id="gpsId" class="form-control" placeholder="nothing">
            <label for="gpsId">GPS ID:</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 form-floating form-group mb-3">
          <div class="form-floating form-group">
            <input formControlName="vin" id="vin" class="form-control" placeholder="nothing">
            <label for="vin">VIN:</label>
          </div>
        </div>
        <div class="col-12 col-sm-6 form-floating form-group mb-3">
          <div class="form-floating form-group">
            <input formControlName="spz" id="spz" class="form-control" placeholder="nothing">
            <label for="spz">SPZ:</label>
          </div>
        </div>
      </div>
      <div class="col form-group mb-3">
        <label for="previewImage" class="mb-1">Náhled:</label>
        <div *ngIf="(!isNew && !smallImageDisableSending) || (isNew && !smallImageDisableSending && smallImageUploadProgress === 100)" class="text-center mb-3">
          <img src="{{machine.previewImage.src}}" alt="{{machine.previewImage.name}}">
        </div>
        <p *ngIf="smallImageDisableSending">
          <ngb-progressbar
            [showValue]="true"
            [value]="smallImageUploadProgress"
            [striped]="true"
            [animated]="true"
          ></ngb-progressbar>
        </p>
        <input
          (change)="chooseSmallImage($event)"
          [required]="isNew"
          [attr.disabled]="smallImageDisableSending ? '' : null"
          accept="image/jpeg, image/png"
          formControlName="previewImage"
          id="previewImage"
          type="file"
          class="form-control">
      </div>
      <div class="col form-group mb-3">
        <label for="gallery" class="mb-1">Fotky:</label>
        <div *ngIf="machine.gallery[0].name !== ''" class="mb-3">
          <table class="table table-hover">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Název</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
              <tr *ngFor="let image of machine.gallery; index as i">
                <th scope="row">{{ i + 1 }}</th>
                <td><app-image-hover-info [image]="image"></app-image-hover-info></td>
                <td><button type="button" class="btn-close btn-close-danger" (click)="removeImg(image)"></button></td>
              </tr>
            </tbody>
          </table>
        </div>
        <p *ngIf="largeImageDisableSending">
          <ngb-progressbar
            [showValue]="true"
            [value]="largeImageUploadProgress"
            [striped]="true"
            [animated]="true"
          ></ngb-progressbar>
        </p>
        <input
          (change)="chooseLargeImage($event)"
          [attr.disabled]="largeImageDisableSending? '' : null"
          accept="image/jpeg, image/png"
          formControlName="gallery"
          id="gallery"
          type="file"
          class="form-control">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button *ngIf="isNew" (click)="sendData()" [disabled]="smallImageDisableSending || largeImageDisableSending || !machineForm.valid" type="button" class="btn btn-success">Další</button>
    <button (click)="modal.close('Save click')" [disabled]="smallImageDisableSending || largeImageDisableSending || !machineForm.valid" type="button" class="btn btn-primary">Uložit</button>
  </div>
</ng-template>

