import {Component, Input} from '@angular/core';
import {DataService} from '../../../../../../../core/services/data.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ErrorResolutionService} from '../../../../../../../core/services/errorResolution.service';
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-contact-person-delete',
  templateUrl: './contact-person-delete.component.html',
  styleUrls: ['./contact-person-delete.component.scss']
})
export class ContactPersonDeleteComponent {
  @Input() person!: any;
  icon = faTrashCan;

  constructor(
    private data: DataService,
    private modalService: NgbModal,
    private errorRes: ErrorResolutionService,
  ) { }

  showAlert(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', scrollable: true, centered: true}).result.then(result => {
      if (result === 'delete') {
        this.delete();
      }
      console.log('Formulář uzavřen pomocí:' + result);
    }, (reason) => {
      console.log(this.errorRes.getModalDismissReason(reason));
    });
  }
  delete(): void {
    console.log('Mažu záznam ' + this.person.id + ' osoby ' + this.person.name);
    this.data.deleteContactPerson(this.person.id);
  }
}
