import {Component, Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {ReferencePlaceModel} from 'src/app/core/interfaces/referencePlace.model';
import {PlaceService} from 'src/app/core/services/place.service';
import {ErrorResolutionService} from 'src/app/core/services/errorResolution.service';
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-place-delete',
  templateUrl: './place-delete.component.html',
  styleUrls: ['./place-delete.component.scss']
})
export class PlaceDeleteComponent {
  @Input() place = new ReferencePlaceModel();
  icon = faTrashCan;

  constructor(
    private placeService: PlaceService,
    private modalService: NgbModal,
    private errorRes: ErrorResolutionService,
  ) { }

  showAlert(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', scrollable: true, centered: true}).result.then(result => {
      if (result === 'delete') {
        this.delete();
      }
      console.log('Formulář uzavřen pomocí: ' + result);
    }, (reason) => {
      console.log(this.errorRes.getModalDismissReason(reason));
    });
  }

  delete(): void {
    console.log('Mažu záznam ' + this.place.id + ' místa ' + this.place.name);
    this.placeService.delete(this.place.id);
  }
}
