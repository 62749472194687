import { Component, OnInit } from '@angular/core';

import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import {CompanyInfoModel} from 'src/app/core/interfaces/companyInfo.model';
import {DataService} from 'src/app/core/services/data.service';

@Component({
  selector: 'app-web-navbar',
  templateUrl: './web-navbar.component.html',
  styleUrls: ['./web-navbar.component.scss']
})
export class WebNavbarComponent implements OnInit {
  contactData = new CompanyInfoModel();
  // fontAwsome icons
  Facebook = faFacebookF;
  Instagram = faInstagram;
  Mail = faEnvelope;
  Phone = faPhone;

  constructor(
    private dataService: DataService,
  ) { }

  ngOnInit(): void {
    this.dataService.getCompanyInfo().subscribe(result => {
      this.contactData = result.data();
    });
  }

}
